import React, {FC, useState , useEffect} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select, {MultiValue, SingleValue} from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {roleQUERIES} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../members-management/users-list/core/_models'
import {useAuth} from '../../../modules/auth'
import {Form, Formik} from 'formik'
import { RootState } from '../../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import { upcomingAppointmentsCurrentPage, upcomingAppointmentsFilterData, upcomingAppointmentsRowsPerPage } from '../../../redux/tableSlice/tableSlice'
import toaster from '../../../../Utils/toaster'
import { updatePassword } from '../../../modules/accounts/components/settings/SettingsModel';

const API_URL = process.env.REACT_APP_API_URL
// const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`
//const GET_ALL_SERVICETYPES = `${API_URL}/master/getall/SERVICE_TYPE`
//const GET_ALL_COMMUNICATIONTYPES = `${API_URL}/master/getall/COMMUNICATION_TYPE`
// const GET_ALL_Languages = `${API_URL}/master/languages/active-shortlist`

type Props = {
  setAllAppointments: any
  setCurrentPage: any
  filterData: any
  searchQuery: any
  fetchAllAppointments: any
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
}
export function UpcomingAppointmentFilterDropdown({
  setAllAppointments,
  setCurrentPage,
  fetchAllAppointments,
  searchQuery,
  filterData,
  setTotalPages,
}: Props) {
  const {currentUser} = useAuth()

  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const dispatch = useDispatch()
  const [data, setdata] = useState(filterData)
  const {items: responseCustomer, loading: isFetchingCustomer} = useSelector(
    (state: RootState) => state.customers
  )

  const {items: responseLanguage, loading: isFetchingLanguage} = useSelector(
    (state: RootState) => state.languages
  )
  const {items: responseServicetypes, loading: isFetchingServicetypes} = useSelector(
    (state: RootState) => state.servicetypes
  )
  const {items: responseCommunicationtypes, loading: isFetchingCommunicationtypes} = useSelector(
    (state: RootState) => state.communicationtypes
  )
  const { rowsPerPage } = useSelector((state: RootState) => {
    return{
      rowsPerPage: state.table.upcomingAppoinment[0].rowsPerPage
    }
  })

  useEffect( ()=> {
    if(filterData?.accounts?.length > 0 || filterData?.communicationTypes?.length > 0 || filterData?.langs?.length > 0 || filterData?.serviceTypes?.length > 0 || filterData?.UpcomingAppointmentFilter){
      setIsFilterApplied(true)
    }
  },[filterData])
  // const getCustomers = (): Promise<DropdownResponse> => {
  //   return axios
  //     .get(`${GET_ALL_CUSTOMER_NAME}`)
  //     .then((d: AxiosResponse<DropdownResponse>) => d.data)
  // }

  // const getservicetypes = (): Promise<DropdownResponse> => {
  //   return axios.get(`${GET_ALL_SERVICETYPES}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  // }

  // const getcommunicationtypes = (): Promise<DropdownResponse> => {
  //   return axios
  //     .get(`${GET_ALL_COMMUNICATIONTYPES}`)
  //     .then((d: AxiosResponse<DropdownResponse>) => d.data)
  // }

  // const getNativeLanguage = (): Promise<DropdownResponse> => {
  //   return axios.get(`${GET_ALL_Languages}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  // }

  // const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
  //   `${roleQUERIES.Customer_LIST}`,
  //   getCustomers,
  //   {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  // )

  // const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
  //   `Query.nativeLanguageList`,
  //   getNativeLanguage,
  //   {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  // )

  // const {isFetching: isFetchingServiceType, data: servicetypeList} = useQuery(
  //   `Query.serviceTypeList`,
  //   getservicetypes,
  //   {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  // )

  // const {isFetching: isFetchingcommunication, data: communicationTypeList} = useQuery(
  //   `Query.communicationTypeList`,
  //   getcommunicationtypes,
  //   {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  // )

  const languageOptions =
  responseLanguage?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const serviceTypeOptions =
  responseServicetypes?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const communicationTypeOptions =
  responseCommunicationtypes
  ?.filter((item: any) => item.identification !== 'ON_DEMAND') // Filter out items with identification 'ON_DEMAND'
  .map((d: any) => ({
    value: d.key ?? '',
    label: d.value ?? '',
  })) ?? []

  const customerOptions =
    responseCustomer?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const handleSubmit = (values: any) => {
    try{
      dispatch(upcomingAppointmentsCurrentPage(1))
      fetchAllAppointments(values, searchQuery, 1, rowsPerPage)
      dispatch(upcomingAppointmentsFilterData({...values}))
      setShowDropdownMenu(false)
      setIsFilterApplied(true)
    } catch (error){
      console.log(error)
      toaster('error', 'Server Error')
    }
  }

  const handleReset = async () => {
    dispatch(upcomingAppointmentsCurrentPage(1))
    dispatch(upcomingAppointmentsRowsPerPage(10))
    setdata({
      accounts: [],
      langs: [],
      serviceTypes: [],
      communicationTypes: [],
      UpcomingAppointmentFilter: null,
    })
    dispatch(upcomingAppointmentsFilterData({
      accounts: [],
      langs: [],
      serviceTypes: [],
      communicationTypes: [],
      UpcomingAppointmentFilter: null,
    }))
    fetchAllAppointments({
      accounts: [],
      langs: [],
      serviceTypes: [],
      communicationTypes: [],
      UpcomingAppointmentFilter: null,
    } , searchQuery, 1, 10)

    setShowDropdownMenu(false)
    setIsFilterApplied(false)
  }
  interface OptionType {
    value: number
    label: string
  }
  const upcomingfilter = [
    {value: 1, label: 'Today'},
    {value: 2, label: 'Tommorow'},
    {value: 3, label: 'Current Week'},
    {value: 4, label: 'Current Month'},
    {value: 5, label: 'Upcoming Month'},
    {value: 6, label: 'Current Year'},
  ]

  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-350px'>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>
            <Formik
              enableReinitialize
              initialValues={data}
              onSubmit={async (values, {setSubmitting, resetForm}) => {
                setSubmitting(true)
                setdata(values)
                handleSubmit(values)
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                resetForm,
                values,
                errors,
                touched,
                isValid,
                dirty,
                ...formik
              }) => (
                <Form>
                  <div className='separator border-gray-200'></div>
                  <div className='card px-4 mt-4'>
                    <div className='row flex-column'>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                                  Customer Name(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={values?.accounts?.length === customerOptions?.length}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'accounts',
                                        customerOptions.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('accounts', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('accounts')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingCustomer ? customerOptions : []}
                                  placeholder='Select Customer(s)'
                                  isMulti
                                  value={
                                    customerOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.accounts) &&
                                        (values.accounts as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions: any[]) => {
                                    setFieldValue(
                                      'accounts',
                                      selectedOptions
                                        ? selectedOptions.map((option: { value: any }) => option.value)
                                        : []
                                    )
                                  }}
                                  onBlur={(e: any) => setFieldTouched('customers', true)}
                                  styles={{
                                    control: (provided: any) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='languages' className='form-label fs-7 mb-1'>
                                  Language(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={values?.langs?.length === languageOptions?.length}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'langs',
                                        languageOptions.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('langs', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingLanguage ? languageOptions : []}
                                  placeholder='Select Language(s)'
                                  isMulti
                                  value={
                                    languageOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.langs) &&
                                        (values.langs as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions: any[]) => {
                                    setFieldValue(
                                      'langs',
                                      selectedOptions
                                        ? selectedOptions.map((option: { value: any }) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided: any) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='serviceType' className='form-label fs-7 mb-1'>
                                  Service Type(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={
                                    values?.serviceTypes?.length === serviceTypeOptions?.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'serviceTypes',
                                        serviceTypeOptions.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('serviceTypes', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingServicetypes ? serviceTypeOptions : []}
                                  placeholder='Select Service Type(s)'
                                  isMulti
                                  value={
                                    serviceTypeOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.serviceTypes) &&
                                        (values.serviceTypes as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions: any[]) => {
                                    setFieldValue(
                                      'serviceTypes',
                                      selectedOptions
                                        ? selectedOptions.map((option: { value: any }) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided: any) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='communicationType' className='form-label fs-7 mb-1'>
                                  Communication Type(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={
                                    values?.communicationTypes?.length ===
                                    communicationTypeOptions?.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'communicationTypes',
                                        communicationTypeOptions.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('communicationTypes', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingCommunicationtypes ? communicationTypeOptions : []}
                                  placeholder='Select Communication Type(s)'
                                  isMulti
                                  value={
                                    communicationTypeOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.communicationTypes) &&
                                        (values.communicationTypes as string[]).includes(
                                          option.value
                                        )
                                    ) || []
                                  }
                                  onChange={(selectedOptions: any[]) => {
                                    setFieldValue(
                                      'communicationTypes',
                                      selectedOptions
                                        ? selectedOptions.map((option: { value: any }) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided: any) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='communicationType' className='form-label fs-7 mb-1'>
                            Date Ranges
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('UpcomingAppointmentFilter')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={upcomingfilter}
                                  value={
                                    upcomingfilter?.find(
                                      (option: any) =>
                                        option.value === values.UpcomingAppointmentFilter
                                    ) || null
                                  }
                                  onChange={(selectedoption: { value: any }) =>
                                    setFieldValue(
                                      'UpcomingAppointmentFilter',
                                      selectedoption?.value || ''
                                    )
                                  }
                                  placeholder='Select'
                                  styles={{
                                    control: (provided: any) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className='fv-row'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <label htmlFor='startDate' className='form-label fs-7 mb-1'>
                        Start Date
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder='Enter Subject'
                      />
                    </div>
                    <div className='col-sm-6'>
                      <label htmlFor='endDate' className='form-label fs-7 mb-1'>
                        End Date
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        placeholder='Start Time'
                      />
                    </div>
                  </div>
                </div> */}
                    </div>
                  </div>
                  <div className='text-end pt-5 px-4'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      data-kt-menu-dismiss='true'
                      onClick={handleReset}
                    >
                      Reset
                    </button>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      onClick={(e) => handleSubmit}
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Apply
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}
