import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {register, getUserByToken, resetPassword2} from '../core/_requests'
import {Link} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import toaster from '../../../../Utils/toaster'

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  oldPassword: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*()_£+\-={};':"|,.<>\/?!\\])(?=\S*$).{8,}$/, 'Invalid password')
    .required('Password is required'),
  newPassword: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('oldPassword')], 'The passwords entered do not match'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function ResetPassword() {
  const urlParams = new URLSearchParams(window.location.search)
  const Passwordaccesstoken = String(urlParams.get('token'))
  const email = String(urlParams.get('email'))
  const [initialValues, setInitialValues] = useState({
    email: email,
    oldPassword: '',
    newPassword: '',
    acceptTerms: false,
    token: Passwordaccesstoken,
  })
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const [showconfirmPassword, setShowconfirmPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState)
  }
  const toggleconfirmPasswordVisibility = () => {
    setShowconfirmPassword((prevState) => !prevState)
  }
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        //values.token = Passwordaccesstoken
        const {data: auth} = await resetPassword2(
          values.token,
          values.email,
          values.oldPassword,
          values.newPassword
        )

        if (auth.isAuthenticated === false) {
          setLoading(false)
          toaster('error', auth?.message)
        } else {
          saveAuth(auth)
          const {data: user} = await getUserByToken()
          setCurrentUser({...user, notNowTimeZoneChanging: false})
          toaster('success', auth?.text)
        }
        setLoading(false)
        setSubmitting(false)

        values.acceptTerms = false
        values.newPassword = ''
        values.email = email
        values.oldPassword = ''
        setInitialValues({
          email: email,
          oldPassword: '',
          newPassword: '',
          acceptTerms: false,
          token: '',
        })
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
        {/* end::Title */}
      </div>
      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          disabled
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email.toString()}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('oldPassword')}
              className={clsx(
                'form-control bg-transparent pr-10',
                {
                  'is-invalid': formik.touched.oldPassword && formik.errors.oldPassword,
                },
                {
                  'is-valid': formik.touched.oldPassword && !formik.errors.oldPassword,
                }
              )}
            />
            <i
              className={clsx('bi', showPassword ? 'bi-eye-slash' : 'bi-eye', 'position-absolute', {
                'd-none': formik.values.oldPassword === '',
              })}
              onClick={togglePasswordVisibility}
              style={{
                right: '35px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                fontSize: '1.25rem',
                color: '#555',
              }}
            ></i>
            {formik.touched.oldPassword && formik.errors.oldPassword && (
              <div className='fv-plugins-message-container' style={{
                position: 'absolute',
                bottom: '-25px',
                left: 0,
                width: '100%',
              }}>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.oldPassword}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3 mt-10'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-gray-800 fs-8 '>
          (Password must be at least 8 characters , include at least one lowercase letter, one
          uppercase letter, one digit, and one special character, and must not contain spaces.)
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <div className='position-relative mb-3'>
        <input
          type={showconfirmPassword ? 'text' : 'password'}
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('newPassword')}
          className={clsx(
            'form-control bg-transparent pr-10',
            {
              'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
            },
            {
              'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
            }
          )}
        />
        <i
          className={clsx(
            'bi',
            showconfirmPassword ? 'bi-eye-slash' : 'bi-eye',
            'position-absolute',
            {
              'd-none': formik.values.newPassword === '',
            }
          )}
          onClick={toggleconfirmPasswordVisibility}
          style={{
            right: '35px',
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            fontSize: '1.25rem',
            color: '#555',
          }}
        ></i>
        
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className='fv-plugins-message-container'  style={{
            position: 'absolute',
            bottom: '-25px',
            left: 0,
            width: '100%',
          }} >
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.newPassword}</span>
            </div>
          </div>
        )}
        </div>
        </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8 mt-10'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
            checked={formik.values.acceptTerms}
          />
          <span>
            I accept the
            <a
              className='ms-1 link-primary'
              href='https://ad-astrainc.com/connect-terms-and-conditions'
              target='_blank'
            >
              Terms and Conditions
            </a>
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
