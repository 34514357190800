import React, {useState} from 'react'
import {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

import {UsersViewTable} from './UsersViewTable'

export function CustomersView() {
  const [activeTab, setActiveTab] = useState(1)
  const [isChecked, setIsChecked] = useState(true)

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber)
  }

  return (
    <>
      <div className='mb-5'>
        <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
          Customers
        </h1>

        <p className='text-gray-500 fs-5'>
          {' '}
          Send a personalized invite to your account's admin to offer <br></br> specialized
          interpreter resources to only them
        </p>
      </div>
      <div className='mb-5'>
        <div className=''>
          <div className=''>
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                <UsersViewTable userType={'CONSUMER'} className={''} />
              </div>
            </div>
          </div>
          <img
            className='w-100 card-rounded-bottom'
            alt=''
            src='assetsmedia/svg/illustrations/bg-4.svg'
          />
        </div>
      </div>
    </>
  )
}
