import {useState, useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import toaster from '../../../../Utils/toaster'
import axios from 'axios'
import moment from 'moment'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import {Dropdown, Form, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Formik} from 'formik'
import * as Yup from 'yup'

const API_URL = process.env.REACT_APP_API_URL
const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10
const ServiceTypeValidation = Yup.object().shape({
  startDate: Yup.date().typeError('Invalid date'),
  endDate: Yup.date()
    .typeError('Invalid date')
    .when('startDate', (startDate, schema) => {
      return startDate
        ? schema.min(startDate, 'To date must be greater than or equal to From date')
        : schema
    }),
})

export function ServiceTypeChange() {
  const [isLoading, setIsLoading] = useState(false)
  const [ServiceTypeData, setServiceTypeData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(itemsPerPage)
  const [totalItems, setTotalItems] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [filterData, setFilterData] = useState<any>({
    startDate: '',
    endDate: '',
  })

  const fetchServiceTypeData = async (
    page: number,
    rowsPerPage: number,
    filterData: any,
    search: string
  ) => {
    setIsLoading(true)
    try {
      const response = await axios.post(
        `${API_URL}/master/master-data-logs`,
        {
          ...filterData,
          startDate: filterData.startDate || undefined,
          endDate: filterData.endDate || undefined,
        },
        {
          params: {
            page,
            items_per_page: rowsPerPage,
            search,
          },
        }
      )
      const {payload} = response.data
      setServiceTypeData(response.data?.data || [])
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      toaster('error', 'Loading failed!')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchServiceTypeData(currentPage, rowsPerPage, filterData, searchTerm)
  }, [])

  useEffect(() => {
    if (searchTerm.trim() === '') {
      fetchServiceTypeData(1, rowsPerPage, filterData, '')
    }
  }, [searchTerm])

  const handleSearch = () => {
    setCurrentPage(1)
    fetchServiceTypeData(1, rowsPerPage, filterData, searchTerm)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const ServiceTypeFilter = () => {
    return (
      <Dropdown
        className='dropdown'
        show={showDropdownMenu}
        onToggle={(nextShow) => setShowDropdownMenu(nextShow)}
      >
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        <Dropdown.Menu className='p-4 w-400px' style={{minHeight: '100px'}}>
          <Formik
            enableReinitialize
            initialValues={{
              startDate: filterData.startDate || '',
              endDate: filterData.endDate || '',
            }}
            validationSchema={ServiceTypeValidation}
            onSubmit={async (values, {setSubmitting}) => {
              setIsFilterApplied(true)
              setFilterData({
                startDate: values.startDate,
                endDate: values.endDate,
              })
              await fetchServiceTypeData(
                1,
                rowsPerPage,
                {
                  startDate: values.startDate,
                  endDate: values.endDate,
                },
                searchTerm
              )
              setSubmitting(false)
              setShowDropdownMenu(false)
            }}
          >
            {({
              values,
              errors,
              touched,
              resetForm,
              setFieldTouched,
              isSubmitting,
              isValid,
              dirty,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form>
                <div className='px-4 pb-4'>
                  <div className='fs-4 text-dark fw-semibolder'>Filter</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className='px-4 mt-4'>
                  <div className='row flex-column'>
                    <div className='fv-row mb-3'>
                      <div className='row '>
                        <div className='mb-3'>
                          <div className='d-flex w-100 flex-1 align-items-center'>
                            <div className='col-sm-5 col-md-5 col-lg-5'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                From
                              </label>
                              <input
                                type='date'
                                id='startDate'
                                name='startDate'
                                className='form-control form-control-white form-select-sm custom-input-height'
                                placeholder='Enter Subject'
                                value={values.startDate}
                                onChange={(e) => setFieldValue('startDate', e.target.value)}
                                onBlur={() => {
                                  setFieldTouched('startDate', true)
                                  setFieldTouched('startDate', true)
                                }}
                                max={new Date().toISOString().split('T')[0]}
                              />
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2 h-100'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              ></label>
                              <span className='fs-7 text-gray-600  d-flex align-items-center justify-content-center'>
                                -
                              </span>
                            </div>
                            <div className='col-sm-5 col-md-5 col-lg-5'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                To
                              </label>
                              <input
                                type='date'
                                id='endDate'
                                name='endDate'
                                className='form-control form-control-white form-select-sm custom-input-height'
                                placeholder='Start Time'
                                value={values.endDate}
                                onChange={(e) => setFieldValue('endDate', e.target.value)}
                                onBlur={() => {
                                  setFieldTouched('startDate', true)
                                  setFieldTouched('endDate', true)
                                }}
                                max={new Date().toISOString().split('T')[0]}
                              />
                            </div>
                          </div>
                          {((errors.startDate && touched?.startDate) ||
                            (errors.endDate && touched?.endDate)) && (
                            <div className='fv-plugins-message-container mx-1'>
                              {errors.startDate && touched?.startDate && (
                                <div className='fv-help-block'>{String(errors?.startDate)}</div>
                              )}
                              {errors?.endDate && touched?.endDate && (
                                <div className='fv-help-block'>{String(errors?.endDate)}</div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-end pt-3 px-4'>
                  <button
                    type='reset'
                    className='btn btn-sm btn-light me-2'
                    onClick={() => {
                      resetForm()
                      setFilterData({startDate: '', endDate: ''})
                      fetchServiceTypeData(1, rowsPerPage, {}, searchTerm)
                      setIsFilterApplied(false)
                      setShowDropdownMenu(false)
                    }}
                  >
                    Reset
                  </button>
                  <button
                    onClick={() => handleSubmit()}
                    className='btn btn-sm btn-primary'
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    Apply
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <>
      <div className='mb-5'>
        <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
          Service Type Details
        </h1>
        <p className='text-gray-500 fs-5'>Service Type Chnage Details</p>
      </div>

      <div className='card mb-5'>
        <div className='card-header'>
          <div className='card-title d-flex align-items-center position-relative me-4 '>
            {/*Data */}
          </div>

          <div className='card-toolbar'>
            <div className='d-flex flex-wrap'>
              <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                  placeholder='Search'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button
                  type='button'
                  className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                  onClick={handleSearch}
                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                </button>
              </div>
              <ServiceTypeFilter />
            </div>
          </div>
        </div>
        <div className='mt-1 mb-4 py-2 px-8'>
          <div className='tab-content' id='myTabContent'>
            {/* begin::Body */}
            <div className='py-0'>
              {/* begin::Table container */}
              <div className='table-responsive' style={{overflowY: 'scroll'}}>
                {/* begin::Table */}
                <table className='table table-row-dashed overflow-auto table-row-gray-300 align-middle gs-2 gy-2 table-hover'>
                  {/* begin::Table head */}
                  <thead
                    style={{
                      position: 'sticky',
                      top: '0',
                      backgroundColor: '#ffffff',
                      zIndex: '10',
                      boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;',
                      border: '1px solid #dee2e6',
                    }}
                  >
                    <tr className='fw-semibold text-muted text-uppercase text-center'>
                      <th className='min-w-400px ' rowSpan={1} colSpan={2}>
                        Current Status
                      </th>
                      <th className='min-w-400px ' rowSpan={1} colSpan={2}>
                        Previous Status
                      </th>
                      <th className='min-w-250px ' rowSpan={2} colSpan={1}>
                        Modified By
                      </th>
                      <th className='min-w-250px' rowSpan={2} colSpan={1}>
                        Timestamp
                      </th>
                    </tr>
                    <tr className='fw-semibold text-muted text-uppercase text-center'>
                      <th className='min-w-100px ' rowSpan={1} colSpan={1}>
                        Name
                      </th>
                      <th className='min-w-100px ' rowSpan={1} colSpan={1}>
                        Status
                      </th>
                      <th className='min-w-100px ' rowSpan={1} colSpan={1}>
                        Name
                      </th>
                      <th className='min-w-100px ' rowSpan={1} colSpan={1}>
                        Status
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {ServiceTypeData?.length > 0 ? (
                      ServiceTypeData.map((data: any, index: number) => (
                        <tr key={index}>
                          <td>{data?.currentName}</td>
                          <td className='text-center'>
                            {data?.currentStatus === true && (
                              <span
                                className='badge badge-light-danger px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
                                style={{width: '80px'}}
                              >
                                Inactive
                              </span>
                            )}
                            {data?.currentStatus === false && (
                              <span
                                className='badge badge-light-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
                                style={{width: '80px'}}
                              >
                                Active
                              </span>
                            )}
                          </td>
                          <td>{data?.previousName || '-'}</td>
                          <td className=''>
                            {data?.previousStatus === true ? (
                              <span
                                className='badge badge-light-danger px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
                                style={{width: '80px'}}
                              >
                                Inactive
                              </span>
                            ) : data?.previousStatus === false ? (
                              <span
                                className='badge badge-light-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
                                style={{width: '80px'}}
                              >
                                Active
                              </span>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td className='text-center'>{data?.modifiedBy}</td>
                          <td className='text-center'>
                            {moment(data.lastModifiedDateTime).format('YYYY-MM-DD hh:mm A') ||
                              '-'}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan={6} className='text-center'>
                        <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                          <div className='text-center'>
                            <div className='symbol symbol-200px '>
                              <img src='/media/other/nodata.png' alt='' />
                            </div>
                          </div>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                            No matching records found
                          </div>
                        </div>
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='pb-5 px-8'>
          <CommonPaginationModel
            currentPage={currentPage}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            total={totalItems}
            onPageChange={(page: number) => {
              setCurrentPage(page)
              fetchServiceTypeData(page, rowsPerPage, filterData, searchTerm)
            }}
            onRowsPerPageChange={(newRowsPerPage: number) => {
              setRowsPerPage(newRowsPerPage)
              setCurrentPage(1)
              fetchServiceTypeData(1, newRowsPerPage, filterData, searchTerm)
            }}
          />
        </div>
      </div>
      {isLoading && <CommonLoading />}
    </>
  )
}
