import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {UsersListFilter} from './UsersListFilter'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
 

const UsersListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip id='tooltip-filter'> Add Location</Tooltip>}
      >
        <div>
          <button
            type='button'
            className='btn btn-sm btn-primary btn-icon'
            onClick={openAddUserModal}
          >
            <i className='bi bi-plus fs-2'></i>
          </button>
        </div>
      </OverlayTrigger>
    </div>
  )
}

export {UsersListToolbar}
