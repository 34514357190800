import moment from 'moment'
import {TableState} from './interfaces'

const initialState: TableState = {

  InterperterInvoice: [
    {
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      filterData: {
        fromDate: '',
        toDate: moment().format('YYYY-MM-DD'),
        company: [],
        status: [],
      },
    },
  ],
  
  RequesterManagement: [
    {
      currentPage: 1,
      rowsPerPage: 10,
      sort: null,
      order: null,
      searchQuery: '',
      filterData: {
        joinDate: null,
        loginDate: null,
        customers: [],
        status: [],
        roles: [],
      },
    },
  ],

  InterperterManagement : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      sort: null,
      order: null,
      searchQuery: '',
      filterData: {
        joinDate: null,
        loginDate: null,
        status: [],
      },
    },
  ],

  AdministratorManagement : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      sort: null,
      order: null,
      searchQuery: '',
      filterData: {
        joinDate: null,
        loginDate: null,
        status: [],
        role: [],
      },
    },
  ],

  CompanyUserManagement : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      customerCode : 0,
      sort: null,
      order: null,
      searchQuery: '',
      filterData: {
        joinDate: null,
        loginDate: null,
        status: [],
        roles: [],
      },
    },
  ],

  CustomerInvoice : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      filterData: {
        fromDate: '',
        toDate: moment().format('YYYY-MM-DD'),
        company: [],
        status: [],
      },
    },
  ],

  dashboardCallLogsOnDEmand : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      filterData: {
        awsUserId_REQList: [],
        languageList: [],
        callStateList: [],
        startDate: null,
        endDate: null,
        callType: 1,
      },
    },
  ],

  dashboardCallLogsScheduled : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      filterData: {
        awsUserId_REQList: [],
        languageList: [],
        callStateList: [],
        startDate: null,
        endDate: null,
        callType: 0,
      },
    },
  ],

  dashboardCallLogsInPerson : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      filterData: {
        awsUserId_REQList: [],
        languageList: [],
        callStateList: [],
        startDate: null,
        endDate: null,
        callType: 2,
      },
    },
  ],

  dashboardCallLogsOperator : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      filterData: {
        awsUserId_REQList: [],
        languageList: [],
        callStateList: [],
        startDate: null,
        endDate: null,
        callType: 3,
      },
    },
  ],

  dashboardCallLogsEffectiff : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      filterData: {
        awsUserId_REQList: [],
        languageList: [],
        callStateList: [],
        startDate: null,
        endDate: null,
        callType: 5,
      },
    },
  ],

  CustomerUnInvoicedCalls : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      filterData: {
        languageList: [],
        coustomerList: [],
        startDate: null,
        endDate: null,
        callType: 50,
      },
    },
  ],

  dashboardOverview : [
    {
      filter : 5,
      communicationTypes : []
    }
  ],

  InterpreterUnInvoicedCalls : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      filterData: {
        languageList: [],
        awsUserId_INTList: [],
        startDate: null,
        endDate: null,
        callType: 50,
      },
    },
  ],

  appointmentsCallender : [
    { 
      date : moment().format('YYYY-MM-DD'),
      filterData: {
        accounts : [],
        communicationTypes : [],
        langs : [],
        serviceTypes : [],
      }
    }
  ], 

  allAppointments : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      filterData: {
        accounts : [],
        communicationTypes : [],
        langs : [],
        serviceTypes : [],
        startDate : null ,
        endDate : null ,
      }
    }
  ],

  upcomingAppoinment : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      filterData: {
        accounts : [],
        communicationTypes : [],
        langs : [],
        serviceTypes : [],
        UpcomingAppointmentFilter : null,
      }
    }
  ],

  customerManagement : [
    {
      currentPage: 1,
      rowsPerPage: 10,
      sort: null,
      order: null,
      searchQuery: '',
      filterData: {
        countries: [],
        status: [],
      }
    }
  ],
}

export default initialState
