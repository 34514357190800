import React, {useEffect, useState} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { appointmentsCallenderDate } from '../../../redux/tableSlice/tableSlice'
import { useDispatch , useSelector } from 'react-redux'
// import '@fullcalendar/daygrid/main.css'
// Add some CSS to style the event content
const styles = `
.fc-daygrid-day-top {
    display: flex;
    justify-content: center;
}
.fc-col-header-cell-cushion{
font-size: 8px !important;

}
.fc-daygrid-day-number {
  font-size: 8px !important;
}
.fc .fc-bg-event{
opacity: 1 !important;
}
`

const SmallCalendarComponent = (props: any) => {
  const {availableDates} = props
  const dispath = useDispatch()
  const { date} = useSelector((state : any) =>{
    return {
      date : state.table.appointmentsCallender[0].date
    }
  })

  const handleDateSelect = (selectInfo: any) => {
    dispath(appointmentsCallenderDate(selectInfo.startStr))
  }

  const availableEvents = availableDates.map((date : any) => ({
    start: date,
  }))
  const specificEvent = {
    start: date,
    display: 'background',
    color: '#ebea9b',
  }
  // Combine the events
  const events = [...availableEvents, specificEvent]

  return (
    <div
      id='small'
      className='mt-2 pe-1'
      style={{width: '100%', minHeight: '260px', height: '100%', fontSize: '8px'}}
    >
      <FullCalendar
        schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        initialDate={date}
        height={'auto'}
        headerToolbar={{
          left: 'prev',
          center: 'title',
          right: 'next',
        }}
        editable={true}
        selectable={true}
        select={handleDateSelect}
        events={events}
      />
    </div>
  )
}
// Insert the styles into the document head
const styleSheet = document.createElement('style')
styleSheet.type = 'text/css'
styleSheet.innerText = styles

export default SmallCalendarComponent
