/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import toaster from '../../../../../Utils/toaster'
import {KTSVG} from '../../../../../_metronic/helpers'
import {CustomerFilterDropdown} from './CustomerFilterDropdown'
import {DownloadModal} from '../../../../../Utils/DownloadModal'
import {CommonPaginationModel} from '../../../../../Utils/commonPagination'
import {useDownloadExcel} from 'react-export-table-to-excel'
import { useAuth } from '../../../../modules/auth'
import { useDispatch , useSelector } from 'react-redux'
import { LogsViewTable } from '../../../../pages/dashboard/Logs/LogsViewTable'
import { CustomerUnInvoicedCallsCurrentPage, CustomerUnInvoicedCallsRowsPerPage, CustomerUnInvoicedCallsSearch } from '../../../../redux/tableSlice/tableSlice'

const API_URL = process.env.REACT_APP_API_URL

type Props = {
  callType: number
  pageTitle: string
  downloadPage: string
}

const CustomerUninvoicedCalls: React.FC<Props> = ({callType, pageTitle, downloadPage}) => {
  const {id} = useParams()
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const inputRef = useRef<HTMLInputElement>(null)
  const tableRef = useRef<any>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [call_log, setcall_log] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const { currentPage, rowsPerPage , searchQuery, filterData} = useSelector((state: any) => {
    return {
      currentPage: state.table.CustomerUnInvoicedCalls[0].currentPage,
      rowsPerPage: state.table.CustomerUnInvoicedCalls[0].rowsPerPage,
      searchQuery: state.table.CustomerUnInvoicedCalls[0].searchQuery,
      filterData: state.table.CustomerUnInvoicedCalls[0].filterData,
    }
  })

  useEffect(() => {
    fetchcall_log(filterData, searchQuery , currentPage , rowsPerPage)
    if(inputRef.current){
      inputRef.current.value = searchQuery || ''
    }
  }, [])

  const fetchcall_log = async (passingData: any, searchQuery: any , currentPage: number , rowsPerPage: number) => {
    setIsLoading(true)
    try {
      let response = await axios.post(`${API_URL}/invoice/call-log-no-invoice`, passingData, {
        params: {
          page: currentPage,
          items_per_page: rowsPerPage,
          ...(
            searchQuery.length > 0
              ? { search: searchQuery} 
              : searchQuery 
          ),
        },
      })
      const {data, payload} = response.data
      setcall_log(data)
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      toaster('error', 'Loading failed!')
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
     const searchValue = inputRef.current?.value || ''
     dispatch(CustomerUnInvoicedCallsCurrentPage(1))
     dispatch(CustomerUnInvoicedCallsSearch(searchValue))
     fetchcall_log(filterData, { search: searchValue }, 1, rowsPerPage)
    }
  }

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: currentUser?.result?.userType === 'CONSUMER' ? 'My Uninvoiced Calls' :'Customer Uninvoiced Calls',
    sheet:
      filterData?.startDate !== null && filterData?.endDate !== null
        ? `${filterData?.startDate} - ${filterData?.endDate}`
        : filterData?.endDate !== null
        ? `Until ${filterData?.endDate}`
        : currentUser?.result?.userType === 'CONSUMER' ? 'My Uninvoiced Calls' :'Customer Uninvoiced Calls',
  })

  return (
    <>
      <div className='card-body p-0'>
        <div className='rounded'>
          <div className='mt-4 d-flex justify-content-between flex-wrap align-items-center'>
            <div className='d-flex'>
              <h4 className='text-center mb-0'>{pageTitle}</h4>
            </div>
            <div className='d-flex flex-wrap'>
              <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                <input
                  ref={inputRef}
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                  placeholder='Search'
                  onKeyDown={handleKeyDown}
                  onChange={() => {
                    const value = inputRef.current?.value || '';
                    if( value === '') {
                      dispatch(CustomerUnInvoicedCallsCurrentPage(1))
                      dispatch(CustomerUnInvoicedCallsSearch(''))
                      fetchcall_log(filterData, '' , 1, rowsPerPage)
                    }
                  }}
                />
                <button
                  type='button'
                  className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                  onClick={() => {
                    const searchValue = inputRef.current?.value || '';
                    dispatch(CustomerUnInvoicedCallsCurrentPage(1))
                    dispatch(CustomerUnInvoicedCallsSearch(searchValue))
                    fetchcall_log(filterData, { search: searchValue }, 1, rowsPerPage)
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                </button>
              </div>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'>Download CSV</Tooltip>}
              >
                <button
                  type='button'
                  className='btn btn-primary btn-sm me-3 btn-icon'
                  onClick={onDownload}
                  data-bs-toggle='modal'
                  data-bs-target='#kt_download_list_modal'
                >
                  <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-muted' />
                </button>
              </OverlayTrigger>
              <div className=''>
                <CustomerFilterDropdown
                  setcall_log={setcall_log}
                  setTotalPages={setTotalPages}
                  setCurrentPage={currentPage}
                  fetchcall_log={fetchcall_log}
                  searchQuery={searchQuery}
                  filterData={filterData}
                />
              </div>
              {/* <DownloadModal
                title='Select Download Options'
                pageName='Scheduled_InPerson'
                filterData={filterData}
                searchKey={searchQuery}
                callType={callType}
              /> */}
            </div>
          </div>
          <div className='table-responsive mb-5 mt-3'>
            <LogsViewTable
              title={pageTitle}
              className={''}
              isLoading={isLoading}
              call_log={call_log}
              tableRef={tableRef}
            />
          </div>
          <CommonPaginationModel
            currentPage={currentPage}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            total={totalItems}
            onPageChange={(newPage: number) => {
              dispatch(CustomerUnInvoicedCallsCurrentPage(newPage))
              fetchcall_log(filterData, searchQuery, newPage, rowsPerPage)
            }}
            onRowsPerPageChange={(newRowsPerPage: number) => {
              dispatch(CustomerUnInvoicedCallsRowsPerPage(newRowsPerPage))
              dispatch(CustomerUnInvoicedCallsCurrentPage(1))
              fetchcall_log(filterData, searchQuery, 1, newRowsPerPage)
            }}
          />
        </div>

        <img
          className='w-100 card-rounded-bottom'
          alt=''
          src='assetsmedia/svg/illustrations/bg-4.svg'
        />
      </div>
    </>
  )
}

export {CustomerUninvoicedCalls}
