import axios from 'axios'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {KTSVG} from '../../../../_metronic/helpers'
import ServiceTypePolarChart from '../../../pages/dashboard/DashboardAnalytics/ServiceTypePolarChart'

const API_URL = process.env.REACT_APP_API_URL
const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10

export function RoutingServiceTypes() {
  const [logData, setLogData] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(itemsPerPage)
  const [totalItems, setTotalItems] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    fetchAllLogInData(currentPage, rowsPerPage)
  }, [])

  useEffect(() => {
    if (searchTerm.trim() === '') {
      handleSearch()
    }
  }, [searchTerm])

  const fetchAllLogInData = async (page: number, rowsPerPage: number) => {
    setIsLoading(true)
    try {
      const response = await axios.get(`${API_URL}/master/backstop-service-types`, {
        params: {
          page,
          items_per_page: rowsPerPage,
          search: searchTerm,
        },
      })
      const {payload} = response.data
      setLogData(response.data.data)
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearch = () => {
    setCurrentPage(1)
    fetchAllLogInData(1, rowsPerPage)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }
  return (
    <>
      <div className='mb-5'>
        <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
          Routing Service Types
        </h1>

        <p className='text-gray-500 fs-5'>Backstop Routing Service Types details</p>
      </div>
      <div className='card mb-5'>
        <div className='card-header'>
          <div className='card-title d-flex align-items-center position-relative me-4 '>
            {/* Login Data */}
          </div>

          <div className='card-toolbar'>
            <div className='d-flex flex-wrap'>
              <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                  placeholder='Search'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button
                  type='button'
                  className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                  onClick={handleSearch}
                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-4 mb-4 py-5 px-8'>
          <div className='tab-content' id='myTabContent'>
            {/* begin::Body */}
            <div className='py-0 pt-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2 table-hover'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-semibold text-muted text-uppercase'>
                      <th className='min-w-150px'>Code</th>
                      <th className='min-w-150px'>Service Type</th>
                      <th className='min-w-150px'>Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {logData?.length > 0 &&
                      logData.map((item: any, index: number) => (
                        <tr key={index}>
                          <td>{item.code || '-'}</td>
                          <td className=''>{item.value || '-'}</td>
                          <td>
                            {item.isDelete === false && (
                              <span
                                className='badge badge-light-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
                                style={{width: '80px'}}
                              >
                                Active
                              </span>
                            )}
                            {item.isDelete === true && (
                              <span
                                className='badge badge-light-danger px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
                                style={{width: '80px'}}
                              >
                                Inactive
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {logData?.length === 0 && (
                  <div
                    className='py-5 d-flex flex-column align-items-center justify-content-center'
                    style={{width: '90vw !important'}}
                  >
                    <div className='text-center'>
                      <div className='symbol symbol-200px'>
                        <img src='/media/other/nodata.png' alt='' />
                      </div>
                    </div>
                    <div className='d-flex text-center w-100 align-items-center justify-content-center fw-semibold fs-3 text-gray-400'>
                      No matching records found
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <CommonPaginationModel
            currentPage={currentPage}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            total={totalItems}
            onPageChange={(page: number) => {
              setCurrentPage(page)
              fetchAllLogInData(page, rowsPerPage)
            }}
            onRowsPerPageChange={(newRowsPerPage: number) => {
              setRowsPerPage(newRowsPerPage)
              setCurrentPage(1)
              fetchAllLogInData(1, newRowsPerPage)
            }}
          />
        </div>
      </div>
      {isLoading && <CommonLoading />}
    </>
  )
}
