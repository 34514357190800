/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios from 'axios'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import {DownloadModal} from '../../../../Utils/DownloadModal'
import {LogsFilterDropdown} from './LogsFilterDropDown'
import {LogsViewTable} from './LogsViewTable'
import {useDispatch, useSelector} from 'react-redux'
import {
  dashboardCallLogsEffectiffCurrentPage,
  dashboardCallLogsEffectiffRowsPerPage,
  dashboardCallLogsEffectiffSearch,
  dashboardCallLogsInPersonCurrentPage,
  dashboardCallLogsInPersonRowsPerPage,
  dashboardCallLogsInPersonSearch,
  dashboardCallLogsOnDEmandCurrentPage,
  dashboardCallLogsOnDEmandRowsPerPage,
  dashboardCallLogsOnDEmandSearch,
  dashboardCallLogsOperatorCurrentPage,
  dashboardCallLogsOperatorRowsPerPage,
  dashboardCallLogsOperatorSearch,
  dashboardCallLogsScheduledCurrentPage,
  dashboardCallLogsScheduledRowsPerPage,
  dashboardCallLogsScheduledSearch,
} from '../../../redux/tableSlice/tableSlice'

const API_URL = process.env.REACT_APP_API_URL
const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10

type Props = {
  callType: number
  pageTitle: string
  downloadPage: string
}

const MainLogs: React.FC<Props> = ({callType, pageTitle, downloadPage}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const tableRef = useRef<any>(null)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [call_log, setcall_log] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [totalItems, setTotalItems] = useState(0)

  const {currentPage, rowsPerPage, searchQuery , filterData } = useSelector((state: any) => {
    if (callType === 1) {
      return {
        currentPage: state.table.dashboardCallLogsOnDEmand[0].currentPage,
        rowsPerPage: state.table.dashboardCallLogsOnDEmand[0].rowsPerPage,
        searchQuery: state.table.dashboardCallLogsOnDEmand[0].searchQuery,
        filterData : state.table.dashboardCallLogsOnDEmand[0].filterData
      }
    } else if (callType === 2) {
      return {
        currentPage: state.table.dashboardCallLogsInPerson[0].currentPage,
        rowsPerPage: state.table.dashboardCallLogsInPerson[0].rowsPerPage,
        searchQuery: state.table.dashboardCallLogsInPerson[0].searchQuery,
        filterData : state.table.dashboardCallLogsInPerson[0].filterData
      }
    } else if (callType === 3) {
      return {
        currentPage: state.table.dashboardCallLogsOperator[0].currentPage,
        rowsPerPage: state.table.dashboardCallLogsOperator[0].rowsPerPage,
        searchQuery: state.table.dashboardCallLogsOperator[0].searchQuery,
        filterData : state.table.dashboardCallLogsOperator[0].filterData
      }
    } else if (callType === 0) {
      return {
        currentPage: state.table.dashboardCallLogsScheduled[0].currentPage,
        rowsPerPage: state.table.dashboardCallLogsScheduled[0].rowsPerPage,
        searchQuery: state.table.dashboardCallLogsScheduled[0].searchQuery,
        filterData : state.table.dashboardCallLogsScheduled[0].filterData
      }
    } else if (callType === 5) {
      return {
        currentPage: state.table.dashboardCallLogsEffectiff[0].currentPage,
        rowsPerPage: state.table.dashboardCallLogsEffectiff[0].rowsPerPage,
        searchQuery: state.table.dashboardCallLogsEffectiff[0].searchQuery,
        filterData : state.table.dashboardCallLogsEffectiff[0].filterData
      }
    } else {
      return {
        currentPage: 1,
        rowsPerPage: itemsPerPage,
      }
    }
  })

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = searchQuery || '';
    }
    fetchcall_log(filterData, searchQuery, currentPage, rowsPerPage)
  }, [])

  const fetchcall_log = async (
    passingData: any,
    searchQuery: any,
    currentPage: number,
    rowsPerPage: number
  ) => {
    setIsLoading(true)
    try {
      let response = await axios.post(`${API_URL}/AWSConnect/call-log-filter`, passingData, {
        params: {
          page: currentPage,
          items_per_page: rowsPerPage,
          ...(searchQuery.length >0 
            ? {search: searchQuery} 
            : searchQuery
          ),
        },
      })

      const {data, payload} = response.data
      setcall_log(data)
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const searchValue = inputRef.current?.value || ''
      if (callType === 1) {
        dispatch(dashboardCallLogsOnDEmandSearch(searchValue))
        dispatch(dashboardCallLogsOnDEmandCurrentPage(1))
        fetchcall_log(filterData, {search: searchValue}, 1, rowsPerPage)
      }
      if (callType === 0) {
        dispatch(dashboardCallLogsScheduledCurrentPage(1))
        dispatch(dashboardCallLogsScheduledSearch(searchValue))
        fetchcall_log(filterData, {search: searchValue}, 1, rowsPerPage)
      }
      if (callType === 2) {
        dispatch(dashboardCallLogsInPersonCurrentPage(1))
        dispatch(dashboardCallLogsInPersonSearch(searchValue))
        fetchcall_log(filterData, {search: searchValue}, 1, rowsPerPage)
      }
      if (callType === 3) {
        dispatch(dashboardCallLogsOperatorCurrentPage(1))
        dispatch(dashboardCallLogsOperatorSearch(searchValue))
        fetchcall_log(filterData, {search: searchValue}, 1, rowsPerPage)
      }
      if (callType === 5) {
        dispatch(dashboardCallLogsEffectiffCurrentPage(1))
        dispatch(dashboardCallLogsEffectiffSearch(searchValue))
        fetchcall_log(filterData, {search: searchValue}, 1, rowsPerPage)
      }
    }
  }

  return (
    <>
      <div className='card-body p-0'>
        <div className='rounded'>
          <div className='mt-4 d-flex justify-content-between flex-wrap align-items-center'>
            <div className='d-flex'>
              <h4 className='text-center mb-0'>{pageTitle}</h4>
            </div>
            <div className='d-flex flex-wrap'>
              <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                <input
                  ref={inputRef}
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                  placeholder='Search'
                  onKeyDown={handleKeyDown}
                  onChange={() => {
                    const value = inputRef.current?.value || '';
                    if ( value === '') {
                      if (callType === 1) {
                        dispatch(dashboardCallLogsOnDEmandSearch(''))
                        dispatch(dashboardCallLogsOnDEmandCurrentPage(1))
                        fetchcall_log(filterData,'', 1, rowsPerPage)
                      }
                      if (callType === 0) {
                        dispatch(dashboardCallLogsScheduledCurrentPage(1))
                        dispatch(dashboardCallLogsScheduledSearch(''))
                        fetchcall_log(filterData,'', 1, rowsPerPage)
                      }
                      if (callType === 2) {
                        dispatch(dashboardCallLogsInPersonCurrentPage(1))
                        dispatch(dashboardCallLogsInPersonSearch(''))
                        fetchcall_log(filterData,'', 1, rowsPerPage)
                      }
                      if (callType === 3) {
                        dispatch(dashboardCallLogsOperatorCurrentPage(1))
                        dispatch(dashboardCallLogsOperatorSearch(''))
                        fetchcall_log(filterData, '', 1, rowsPerPage)
                      }
                      if (callType === 5) {
                        dispatch(dashboardCallLogsEffectiffCurrentPage(1))
                        dispatch(dashboardCallLogsEffectiffSearch(''))
                        fetchcall_log(filterData, '', 1, rowsPerPage)
                      }
                    }
                  }}
                />

                <button
                  type='button'
                  className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                  onClick={() => {
                    const searchValue = inputRef.current?.value || ''
                    if (callType === 1) {
                      dispatch(dashboardCallLogsOnDEmandSearch(searchValue))
                      dispatch(dashboardCallLogsOnDEmandCurrentPage(1))
                      fetchcall_log(filterData, {search: searchValue}, 1, rowsPerPage)
                    }
                    if (callType === 0) {
                      dispatch(dashboardCallLogsScheduledCurrentPage(1))
                      dispatch(dashboardCallLogsScheduledSearch(searchValue))
                      fetchcall_log(filterData, {search: searchValue}, 1, rowsPerPage)
                    }
                    if (callType === 2) {
                      dispatch(dashboardCallLogsInPersonCurrentPage(1))
                      dispatch(dashboardCallLogsInPersonSearch(searchValue))
                      fetchcall_log(filterData, {search: searchValue}, 1, rowsPerPage)
                    }
                    if (callType === 3) {
                      dispatch(dashboardCallLogsOperatorCurrentPage(1))
                      dispatch(dashboardCallLogsOperatorSearch(searchValue))
                      fetchcall_log(filterData, {search: searchValue}, 1, rowsPerPage)
                    }
                    if (callType === 5) {
                      dispatch(dashboardCallLogsEffectiffCurrentPage(1))
                      dispatch(dashboardCallLogsEffectiffSearch(searchValue))
                      fetchcall_log(filterData, {search: searchValue}, 1, rowsPerPage)
                    }
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                </button>
              </div>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'>Download CSV</Tooltip>}
              >
                <button
                  type='button'
                  className='btn btn-primary btn-sm me-3 btn-icon'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_download_list_modal'
                >
                  <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-muted' />
                </button>
              </OverlayTrigger>

              <div className=''>
                <LogsFilterDropdown
                  setcall_log={setcall_log}
                  setTotalPages={setTotalPages}
                  setCurrentPage={currentPage}
                  setFilterData={filterData}
                  fetchcall_log={fetchcall_log}
                  searchQuery={searchQuery}
                  callType={callType}
                />
              </div>
              <DownloadModal
                title='Select Download Options'
                pageName={downloadPage}
                filterData={filterData}
                searchKey={searchQuery}
                callType={callType}
              />
            </div>
          </div>

          <div className='table-responsive mb-5 mt-3'>
            <LogsViewTable
              className={''}
              title={pageTitle}
              isLoading={isLoading}
              call_log={call_log}
              tableRef={tableRef}
            />
          </div>
          <CommonPaginationModel
            currentPage={currentPage}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            total={totalItems}
            onPageChange={(page: number) => {
              if (callType === 1) {
                dispatch(dashboardCallLogsOnDEmandCurrentPage(page))
                fetchcall_log(filterData, searchQuery, page, rowsPerPage)
              }
              if (callType === 0) {
                dispatch(dashboardCallLogsScheduledCurrentPage(page))
                fetchcall_log(filterData, searchQuery, page, rowsPerPage)
              }
              if (callType === 2) {
                dispatch(dashboardCallLogsInPersonCurrentPage(page))
                fetchcall_log(filterData, searchQuery, page, rowsPerPage)
              }
              if (callType === 3) {
                dispatch(dashboardCallLogsOperatorCurrentPage(page))
                fetchcall_log(filterData, searchQuery, page, rowsPerPage)
              }
              if (callType === 5) {
                dispatch(dashboardCallLogsEffectiffCurrentPage(page))
                fetchcall_log(filterData, searchQuery, page, rowsPerPage)
              }
            }}
            onRowsPerPageChange={(newRowsPerPage: number) => {
              if (callType === 1) {
                dispatch(dashboardCallLogsOnDEmandRowsPerPage(newRowsPerPage))
                dispatch(dashboardCallLogsOnDEmandCurrentPage(1))
                fetchcall_log(filterData, searchQuery, 1, newRowsPerPage)
              }
              if (callType === 0) {
                dispatch(dashboardCallLogsScheduledRowsPerPage(newRowsPerPage))
                dispatch(dashboardCallLogsScheduledCurrentPage(1))
                fetchcall_log(filterData, searchQuery, 1, newRowsPerPage)
              }
              if (callType === 2) {
                dispatch(dashboardCallLogsInPersonRowsPerPage(newRowsPerPage))
                dispatch(dashboardCallLogsInPersonCurrentPage(1))
                fetchcall_log(filterData, searchQuery, 1, newRowsPerPage)
              }
              if (callType === 3) {
                dispatch(dashboardCallLogsOperatorRowsPerPage(newRowsPerPage))
                dispatch(dashboardCallLogsOperatorCurrentPage(1))
                fetchcall_log(filterData, searchQuery, 1, newRowsPerPage)
              }
              if (callType === 5) {
                dispatch(dashboardCallLogsEffectiffRowsPerPage(newRowsPerPage))
                dispatch(dashboardCallLogsEffectiffCurrentPage(1))
                fetchcall_log(filterData, searchQuery, 1, newRowsPerPage)
              }
            }}
          />
        </div>

        <img
          className='w-100 card-rounded-bottom'
          alt=''
          src='assetsmedia/svg/illustrations/bg-4.svg'
        />
      </div>
    </>
  )
}

export {MainLogs}
