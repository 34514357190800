import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

type DeleteConfirmationModalProps = {
  onSubmit: any
  title: string
  content: string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({
  onSubmit,
  title,
  content,
  setOpen,
}) => {
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog mw-500px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>{title}</h2>

              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                style={{cursor: 'pointer'}}
                onClick={() => setOpen(false)}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body text-center'>
              <p>{content}</p>
              {/* begin::Actions */}
              <div className='text-center pt-15'>
                <button
                  className='btn btn-light btn-sm me-3'
                  data-kt-users-modal-action='cancel'
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  onClick={async() => {
                    setSubmitting(true)
                    await onSubmit()
                    setSubmitting(false)
                  }}
                  disabled={isSubmitting}
                  className='btn btn-primary btn-sm me-3'
                >
                  Confirm
                </button>
              </div>
              {/* end::Actions */}
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {DeleteConfirmationModal}
