import React, {FC, useState} from 'react'
import CreatableSelect from 'react-select/creatable'

interface CommonPaginationModelProps {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
  rowsPerPage: number
  total: number
  onRowsPerPageChange: (rowsPerPage: number) => void
}

export const CommonPaginationModel: FC<CommonPaginationModelProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  rowsPerPage,
  total,
  onRowsPerPageChange,
}) => {
  const [isPageCountNull, setIsPageCountNull] = useState(rowsPerPage ? false : true)
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page)
    }
  }

  const options = [
    {value: 10, label: '10'},
    {value: 50, label: '50'},
    {value: 100, label: '100'},
  ]

  const renderPaginationButtons = () => {
    const buttons = []

    // First page button with ellipsis
    if (currentPage > 3) {
      buttons.push(
        <li key='first' className='page-item'>
          <button className='page-link' onClick={() => handlePageChange(1)}>
            1
          </button>
        </li>
      )
      buttons.push(
        <li key='ellipsis-start' className='page-item disabled'>
          <span className='page-link'>...</span>
        </li>
      )
    }

    // Previous button
    if (currentPage !== 1) {
      buttons.push(
        <li key='prev' className='page-item'>
          <button className='page-link' onClick={() => handlePageChange(currentPage - 1)}>
            Previous
          </button>
        </li>
      )
    }

    // Render nearby page buttons
    for (let i = Math.max(currentPage - 2, 1); i <= Math.min(currentPage + 2, totalPages); i++) {
      buttons.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button className='page-link' onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      )
    }

    // Next button
    if (currentPage < totalPages) {
      buttons.push(
        <li key='next' className='page-item'>
          <button className='page-link' onClick={() => handlePageChange(currentPage + 1)}>
            Next
          </button>
        </li>
      )
    }

    // Last page button with ellipsis
    if (currentPage < totalPages - 2) {
      buttons.push(
        <li key='ellipsis-end' className='page-item disabled'>
          <span className='page-link'>...</span>
        </li>
      )
      buttons.push(
        <li key='last' className='page-item'>
          <button className='page-link' onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </button>
        </li>
      )
    }

    return buttons
  }

  const handleRowsPerPageChange = (newValue: any) => {
    if (newValue) {
      onRowsPerPageChange(newValue.value)
      setIsPageCountNull(false)
    } else {
      setIsPageCountNull(true)
    }
  }

  // useEffect(() => {
  //   if ( currentPage !== 1) {
  //     onPageChange(1);
  //   }
  // }, [rowsPerPage]);

  const from = (currentPage - 1) * rowsPerPage + 1
  const to = Math.min(currentPage * rowsPerPage, total)

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
        <div className='d-flex flex-row align-items-center'>
          <div className='d-flex align-items-center'>
            <span className='fw-semibold text-gray-900 fs-7 me-2 text-nowrap'>Rows per page</span>
            <CreatableSelect
              className='react-select-styled react-select-transparent'
              classNamePrefix='react-select'
              options={
                options.find((option) => option.value === rowsPerPage)
                  ? options
                  : [...options, {value: rowsPerPage, label: `${rowsPerPage}`}]
              }
              placeholder=''
              value={!isPageCountNull ? (options.find((option) => option.value === rowsPerPage)
                ? options
                : [...options, {value: rowsPerPage, label: `${rowsPerPage}`}]
              ).find((option) => option.value === rowsPerPage) : null}
              onChange={handleRowsPerPageChange}
              isClearable
            />
          </div>
          <span className='fw-semibold text-gray-700 fs-7 ms-5'>
            {from}-{to} of {total}
          </span>
        </div>
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>{renderPaginationButtons()}</ul>
        </div>
      </div>
    </div>
  )
}
