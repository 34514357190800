import React, {useEffect, useState} from 'react'
import {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

import {UsersViewTable} from './UsersViewTable'
import {AdministratorsViewTable} from './AdministratorsViewTable'
import {InterpretorViewTable} from './InterpretorViewTable'
import {useAuth} from '../../modules/auth'
import { useNavigate, useParams } from 'react-router-dom'

export function MemberView() {
  const {id} = useParams()
  const [activeTab, setActiveTab] = useState<any>(id)
  const navigate = useNavigate()
  const {currentUser} = useAuth()

  useEffect(() => {
    if(id)setActiveTab(Number(id))
  }, [id])

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber)
    navigate(`/members/${tabNumber}`)
  }

  return (
    <>
      <div className='mb-5'>
        <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
          Members
        </h1>

        <p className='text-gray-500 fs-5'>Manage Members</p>
      </div>
      <div className='card mb-5'>
        <div
          className='card-header py-0'
          style={{minWidth: '300px', overflowX: 'auto', minHeight: '50px'}}
        >
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400   ${
                  activeTab === 1 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_1'
                onClick={() => handleTabClick(1)}
              >
                Requesters
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab === 2 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_2'
                onClick={() => handleTabClick(2)}
              >
                Interpreters
              </a>
            </li>
            {currentUser?.result?.role == 'Admin' && (
              <li className='nav-item'>
                <a
                  className={`nav-link fs-7 text-gray-400 ${
                    activeTab === 3 ? 'text-active-dark fw-bold active show' : ''
                  }`}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_3'
                  onClick={() => handleTabClick(3)}
                >
                  Administrators
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className='card-body pt-0'>
          <div className=''>
            <div className='tab-content' id='myTabContent' data-testid="users-view-table">
              {activeTab === 1 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                  <UsersViewTable customerCode={0} userType={'CONSUMER'} className={''} />
                </div>
              )}
              {activeTab === 2 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_2' role='tabpanel' data-testid="interpretor-view-table">
                  <InterpretorViewTable customerCode={0} userType={'INTERPRETER'} className={''}/>
                </div>
              )}
              {activeTab === 3 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_3' role='tabpanel' data-testid="administrators-view-table">
                  <AdministratorsViewTable customerCode={0} userType={'SYSTEM'} className={''} />
                </div>
              )}
            </div>
          </div>
          <img
            className='w-100 card-rounded-bottom'
            alt=''
            src='assetsmedia/svg/illustrations/bg-4.svg'
          />
        </div>
      </div>
    </>
  )
}
