/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {DashboardSmallCards} from './DashboardSmallCards'
import '../Logs/style.css'
import TopAccountBarChart from './TopAccountBarChart'
import CallDurationBarChart from './CallDurationBarChart'
import ServiceTypePolarChart from './ServiceTypePolarChart'
import TopLanguageBarChart from './TopLanguageBarChart'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {DashboardAnalyticsFilter} from './DashboardAnalyticsFilter'
import axios from 'axios'
import {CommonLoading} from '../../../../Utils/commonLoading'
import moment from 'moment'
import toaster from '../../../../Utils/toaster'
import CallDistributionByRequestorDevice from './CallDistributionByRequestorDevice'

const API_URL = process.env.REACT_APP_API_URL

type DashboardAnalytics = {
  isLoading1: any
  isLoading2: any
  isLoading3: any
  isLoading4: any
  isLoading5: any
}
const DashboardAnalytics: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoading1, setIsLoading1] = useState(false)
  const [isLoading2, setIsLoading2] = useState(false)
  const [isLoading3, setIsLoading3] = useState(false)
  const [isLoading4, setIsLoading4] = useState(false)
  const [isLoading5, setIsLoading5] = useState(false)

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [titleDetails, setTitleDetails] = useState<any>({})
  const [callDurationBarChartData, setCallDurationBarChartData] = useState<any>({})
  const [callDistributionDonutChart, setCallDistributionDonutChart] = useState<any>({})
  const [serviceTypePolarChartData, setServiceTypePolarChartData] = useState<any>({})
  const [topAccountBarChartData, setTopAccountBarChartData] = useState<any>({})
  const [topLanguageBarChartData, setTopLanguageBarChartData] = useState<any>({})
  const isAnyLoading = isLoading1 || isLoading2 || isLoading3 || isLoading4 || isLoading5

  const fetchAnalytics = async (query: any = {}) => {
    setIsLoading(true)
    setIsLoading1(true)
    setIsLoading2(true)
    setIsLoading3(true)
    setIsLoading4(true)
    setIsLoading5(true)
    try {
      // fetchTilesDetails()
      let response = await axios.post(`${API_URL}/Dashboard/analytics`, query)
      const {data} = response
      if (data) {
        setTitleDetails(data)
        setStartDate(moment(data?.startDate).format('YYYY-MM-DD'))
        setEndDate(moment(data?.endDate).format('YYYY-MM-DD'))
      } else {
        toaster('error', 'Loading failed!')
      }
      setIsLoading(false)

      // fetchCallDurationBarChartData()
      let response1 = await axios.post(`${API_URL}/Dashboard/analytics/call-duration`, query)
      if (response1?.data) {
        setCallDurationBarChartData(response1?.data)
      }
      setIsLoading1(false)

      // fetchCallDistributionDonutChart()
      let response2 = await axios.post(`${API_URL}/Dashboard/analytics/call-distribution`, query)
      if (response2?.data) {
        setCallDistributionDonutChart(response2?.data)
      }
      setIsLoading2(false)

      // fetchServiceTypePolarChartData()
      let response3 = await axios.post(`${API_URL}/Dashboard/analytics/service-types`, query)
      if (response3?.data) {
        setServiceTypePolarChartData(response3?.data)
      }
      setIsLoading3(false)

      // fetchTopAccountBarChartData()
      let response4 = await axios.post(`${API_URL}/Dashboard/analytics/top-accounts`, query)
      if (response4?.data) {
        setTopAccountBarChartData(response4?.data)
      }
      setIsLoading4(false)

      // fetchTopLanguageBarChartData()
      let response5 = await axios.post(`${API_URL}/Dashboard/analytics/top-languages`, query)
      if (response5?.data) {
        setTopLanguageBarChartData(response5?.data)
      }
      setIsLoading5(false)
    } catch (error) {
      toaster('error', 'Loading failed!')
    } finally {
      setIsLoading(false)
      setIsLoading1(false)
      setIsLoading2(false)
      setIsLoading3(false)
      setIsLoading4(false)
      setIsLoading5(false)
    }
  }

  useEffect(() => {
    fetchAnalytics({})
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-2'>
        <div className='bg-light px-3 py-2' style={{borderRadius: '6px'}}>
          <h5 className='text-gray-700 fs-7 mb-0 fw-semibold'>
            <KTSVG
              path='/media/icons/duotune/files/fil002.svg'
              className='svg-icon-muted svg-icon-3 me-2'
            />
            {moment(titleDetails?.startDate).format('DD MMM, YYYY')} -{' '}
            {moment(titleDetails?.endDate).format('DD MMM, YYYY')}
          </h5>
        </div>
        <div>
          {' '}
          <DashboardAnalyticsFilter
            isAnyLoading={isAnyLoading}
            fetchAnalytics={fetchAnalytics}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>
      </div>

      <>
        <div className='row gy-4 mb-2'>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 '>
            <DashboardSmallCards
              number={titleDetails?.totalCalls}
              className='text-success zoom'
              description='Total Jobs'
              url='bi bi-telephone-fill'
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6  col-xl-2 col-xxl-2 '>
            <DashboardSmallCards
              number={titleDetails?.totalMinutes}
              className='text-info'
              description='Total Minutes '
              url='bi bi-clock-fill'
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6  col-xl-2 col-xxl-2 '>
            <DashboardSmallCards
              number={titleDetails?.uniqueLanguages}
              className='text-primary zoom'
              description='Unique Languages'
              url='bi bi-translate'
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 '>
            <DashboardSmallCards
              number={`${titleDetails?.opiMinutes ?? 0}`}
              className='text-warning'
              description='OPI Minutes'
              url='bi bi-clock-history'
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 '>
            <DashboardSmallCards
              number={`${titleDetails?.vriMinutes ?? 0}`}
              className='text-info'
              description='VRI Minutes'
              url='bi bi-clock-history'
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 '>
            <DashboardSmallCards
              number={`${titleDetails?.osiMinutes ?? 0}`}
              className='text-success zoom'
              description='OSI Minutes'
              url='bi bi-clock-history'
            />
          </div>
        </div>
        <div className='row gy-4 gx-6 mb-2'>
          <div className='col-sm-12 col-xl-4'>
            <CallDurationBarChart details={callDurationBarChartData} loading={isLoading1} />
          </div>
          <div className='col-sm-12 col-xl-4'>
            <CallDistributionByRequestorDevice
              details={callDistributionDonutChart}
              loading={isLoading2}
            />
            {/* <CallDistributionDonutChart details={analyticsDetails?.callDistributionDonutChart} /> */}
          </div>
          <div className='col-sm-12 col-xl-4'>
            <ServiceTypePolarChart details={serviceTypePolarChartData} loading={isLoading3} />
          </div>
          <div className='col-sm-12 col-xl-6 mt-2'>
            <TopAccountBarChart details={topAccountBarChartData} loading={isLoading4} />
          </div>
          <div className='col-sm-12 col-xl-6 mt-2'>
            <TopLanguageBarChart details={topLanguageBarChartData} loading={isLoading5} />
          </div>
        </div>
        {isLoading && <CommonLoading />}
      </>
    </>
  )
}

export {DashboardAnalytics}
