/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import CreatableSelect from 'react-select/creatable'
import {useState, useEffect} from 'react'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }
  return label
}

const UsersListPagination = () => {
  const options = [
    {value: 10, label: '10'},
    {value: 50, label: '50'},
    {value: 100, label: '100'},
  ]
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, items_per_page: pagination.items_per_page || 10})
  }

  // Set default value to the option with label '10'
  const [option1, setOption1] = useState(options[0])
  // Handle changes to the selected option
  const handleChange = (selectedOption: any) => {
    setOption1(selectedOption)
    if (selectedOption !== null && selectedOption !== undefined && selectedOption.value) {
      updateState({items_per_page: selectedOption.value})
    }
  }

  useEffect(() => {
    if (pagination.page !== 1) {
      updateState({page: 1})
    }
  }, [option1])

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
        <span className='fw-semibold text-gray-700 fs-7 me-2 text-nowrap'>Rows per page</span>
        <CreatableSelect
          className='react-select-styled react-select-transparent'
          classNamePrefix='react-select'
          options={options}
          placeholder=''
          value={option1}
          onChange={handleChange}
          isClearable
        />
        <span className='fw-semibold text-gray-700 fs-7 ms-5'>
          {pagination.from}-{pagination.to} of {pagination.total}
        </span>
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            
            {pagination.page > 3 && (
              <li
                className={clsx('page-item', {
                  active: pagination.page === 1,
                  disabled: isLoading,
                })}
              >
                <a className='page-link' onClick={() => updatePage(1)} style={{cursor: 'pointer'}}>
                  1
                </a>
              </li>
            )}
            {pagination.page > 3 && (
              <li className='page-item disabled'>
                <span className='page-link'>...</span>
              </li>
            )}

            {pagination.links
              ?.map((link) => {
                return {...link, label: mappedLabel(link.label)}
              })
              .map((link) => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: pagination.page === link.page,
                    disabled: isLoading,
                    previous: link.label === 'Previous',
                    next: link.label === 'Next',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Previous' || link.label === 'Next',
                      'me-5': link.label === 'Previous',
                    })}
                    onClick={() => {
                      if (link.label === 'Next') {
                        updatePage(pagination.page + 1);
                      } else if (link.label === 'Previous') {
                        updatePage(pagination.page - 1);
                      } else {
                        updatePage(link.page);
                      }
                    }}
                    style={{cursor: 'pointer'}}
                  >
                    {mappedLabel(link.label)}
                  </a>
                </li>
              ))}

            {pagination.page < pagination.last_page - 1 && (
              <li className='page-item disabled'>
                <span className='page-link'>...</span>
              </li>
            )}
            {pagination.page < pagination.last_page - 1 &&
              pagination.page !== pagination.last_page && (
                <li
                  className={clsx('page-item', {
                    active: pagination.page === pagination.last_page,
                    disabled: isLoading,
                  })}
                >
                  <a
                    className='page-link'
                    onClick={() => updatePage(pagination.last_page)}
                    style={{cursor: 'pointer'}}
                  >
                    {pagination.last_page}
                  </a>
                </li>
              )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {UsersListPagination}