import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {useEffect, useRef, useState} from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import './style.css'
import EditableTable from './EditableTable'
import CreateRateModal from './CreateRateModal'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import RateCustomerFilterDropdown from './RateCustomerFilterDropdown'
import {useAuth} from '../../../modules/auth'
import {CommonLoading} from '../../../../Utils/commonLoading'
import { BulkRateUpload } from '../../../../Utils/BulkRateUpload'

const API_URL = process.env.REACT_APP_API_URL
const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10

export function RateAccountView() {
  let {id} = useParams()
  const {currentUser} = useAuth()
  const [languages, setLanguages] = useState([])
  const [serviceTypes, setServiceTypes] = useState([])
  const [communicationTypes, setCommunicationTypes] = useState([])
  const [initialRows, setInitialRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [filterValues, setFilterValues] = useState({
    fK_LanguageFrom: null,
    fK_LanguageTo: [],
    fK_ServiceType: [],
    fK_CommunicationType: [],
  })
  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(itemsPerPage)
  const [totalItems, setTotalItems] = useState<number>(0)

  const fetchLanguages = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/languages/active-shortlist`)
      setLanguages(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAllCommunicationType = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/getall/COMMUNICATION_TYPE`)
      setCommunicationTypes(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAllServices = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/getall/SERVICE_TYPE`)
      setServiceTypes(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAllCustomerRate = async (payload: any) => {
    const params = new URLSearchParams({
      page: currentPage.toString(),
      items_per_page: rowsPerPage.toString(),
    }).toString()

    const obj = {
      ...payload,
      userId: id,
      fK_LanguageTo: payload.fK_LanguageTo.map((item: any) => item.value),
      fK_CommunicationType: payload.fK_CommunicationType.map((item: any) => item.value),
      fK_ServiceType: payload.fK_ServiceType.map((item: any) => item.value),
    }

    try {
      setIsLoading(true)
      let response = await axios.post(`${API_URL}/rates/rate-filter?${params}`, obj)
      const {data, payload} = response.data
      setInitialRows(
        data.map((rate: any) => ({
          ...rate,
          fK_CommunicationType: rate.communicationTypeList,
          fK_LanguageTo: rate.toLanguageList,
          fK_ServiceType: rate.serviceTypeList,
          days: rate.dayList,
        }))
      )
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchLanguages()
    fetchAllServices()
    fetchAllCommunicationType()
  }, [])

  useEffect(() => {
    fetchAllCustomerRate(filterValues)
  }, [currentPage, rowsPerPage])

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_delete_confirm_cus'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Delete Confirmation</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/delete.gif' alt='' />
                </div>
              </div>
              <h4 style={{textAlign: 'center'}}>Are you sure you want to delete this?</h4>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-danger btn-sm' data-bs-dismiss='modal'>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <CreateRateModal
        title='Add Customer Rate'
        languages={languages}
        serviceTypes={serviceTypes}
        communicationTypes={communicationTypes}
        rateCategory='C'
        fetchAllCustomerRate={fetchAllCustomerRate}
      />

      <div className='card-body p-0'>
        <div className='d-flex justify-content-between mb-3 align-items-center flex-wrap'>
          <div className='card-title d-flex align-items-center me-4'>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center'>
                <h5 className='text-black fs-4 fw-semibold mb-0'>Customer Rates</h5>
              </div>
              {currentUser?.result.userType == 'SYSTEM' && (
                <div className='d-flex flex-wrap fs-6 '>
                  <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                    Add and Edit Customer Rates
                  </p>
                </div>
              )}
              {currentUser?.result.userType == 'SYSTEM' && (
                <div className='d-flex flex-wrap fs-6 '>
                  <p className='text-danger mb-0 fw-normal' style={{fontSize: '12px'}}>
                    *The rate times are set according to the Customer's time zone.
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className='d-flex justify-content-end align-items-center'>
            <div className='d-flex align-items-center'>
              <div className='my-0 me-3' style={{display: 'flex', justifyContent: 'end'}}>
                <RateCustomerFilterDropdown
                  languages={languages}
                  serviceTypes={serviceTypes}
                  communicationTypes={communicationTypes}
                  fetchAllCustomerRate={fetchAllCustomerRate}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  rowsPerPage={rowsPerPage}
                  currentPage={currentPage}
                  setRowsPerPage={setRowsPerPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>

            {currentUser?.result.userType == 'SYSTEM' && (
              <div className='d-flex align-items-center'>
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip id='tooltip-filter'>Add Customer Rate</Tooltip>}
                >
                  <div>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary btn-icon'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_add_acc_rate'
                    >
                      <i className='bi bi-plus fs-2'></i>
                    </button>
                  </div>
                </OverlayTrigger>
              </div>
            )}
            {currentUser?.result.userType === 'SYSTEM' && process.env.REACT_APP_SERVER === 'DEV' && (
              <div className='d-flex align-items-center'>
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip id='tooltip-filter'>Add Bulk Customer Rate</Tooltip>}
                >
                  <div className='ms-3'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary btn-icon'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_add_bulk_acc_rate'
                    >
                      <i className='bi bi-upload fs-2'></i>
                    </button>
                  </div>
                </OverlayTrigger>
              </div>
            )}
          </div>
        </div>

        <div className='row g-1'>
          <div className='py-0 pt-3'>
            <EditableTable
              initialRows={initialRows}
              fetchAllCustomerRate={fetchAllCustomerRate}
              rateCategory='C'
              currentPage={currentPage}
              totalPages={totalPages}
              rowsPerPage={rowsPerPage}
              totalItems={totalItems}
              setRowsPerPage={setRowsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
        <BulkRateUpload />
        {isLoading && <CommonLoading />}
      </div>
    </>
  )
}
