import React, {FC, useState, useEffect} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select, {MultiValue} from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {roleQUERIES} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../members-management/users-list/core/_models'
import {useAuth} from '../../../modules/auth'
import {Form, Formik} from 'formik'
import {RootState} from '../../../redux/store'
import {useSelector, useDispatch} from 'react-redux'
import {appointmentsCallenderFilterData} from '../../../redux/tableSlice/tableSlice'

const API_URL = process.env.REACT_APP_API_URL
// const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`
//const GET_ALL_SERVICETYPES = `${API_URL}/master/getall/SERVICE_TYPE`
//const GET_ALL_COMMUNICATIONTYPES = `${API_URL}/master/getall/COMMUNICATION_TYPE`
// const GET_ALL_Languages = `${API_URL}/master/languages/active-shortlist`

type Props = {
  date: any
  fetchAppoinmentDetailsUsingDate: any
  data: any
}
export function CalandarFilterDropdown({fetchAppoinmentDetailsUsingDate, date, data}: Props) {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const dispath = useDispatch()
  const {items: responseCustomer, loading: isFetchingCustomer} = useSelector(
    (state: RootState) => state.customers
  )

  console.log('dff', data)

  useEffect(() => {
    data.accounts.length > 0 ||
    data.communicationTypes.length > 0 ||
    data.langs.length > 0 ||
    data.serviceTypes.length > 0
      ? setIsFilterApplied(true)
      : setIsFilterApplied(false)
  }, [data])

  const {items: responseLanguage, loading: isFetchingLanguage} = useSelector(
    (state: RootState) => state.languages
  )
  const {items: responseServicetypes, loading: isFetchingServicetypes} = useSelector(
    (state: RootState) => state.servicetypes
  )
  const {items: responseCommunicationtypes, loading: isFetchingCommunicationtypes} = useSelector(
    (state: RootState) => state.communicationtypes
  )

  const languageOptions =
    responseLanguage?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const serviceTypeOptions =
    responseServicetypes?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const communicationTypeOptions =
    responseCommunicationtypes
      ?.filter((item: any) => item.identification !== 'ON_DEMAND') // Filter out items with identification 'ON_DEMAND'
      .map((d: any) => ({
        value: d.key ?? '',
        label: d.value ?? '',
      })) ?? []

  const customerOptions =
    responseCustomer?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-350px'>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>
            <Formik
              enableReinitialize
              initialValues={data}
              onSubmit={async (values, {setSubmitting, resetForm}) => {
                setSubmitting(true)
                dispath(appointmentsCallenderFilterData({...values}))
                await fetchAppoinmentDetailsUsingDate(date, values)
                setShowDropdownMenu(false)
                setIsFilterApplied(true)
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                resetForm,
                values,
                errors,
                touched,
                isValid,
                dirty,
                ...formik
              }) => (
                <Form>
                  <div className='separator border-gray-200'></div>
                  <div className='cardpx-4 mt-4'>
                    <div className='row flex-column'>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                                  Customer Name
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={values?.accounts?.length === customerOptions?.length}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'accounts',
                                        customerOptions?.map((option: any) => option.value)
                                      )
                                    } else {
                                      setFieldValue('accounts', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('accounts')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingCustomer ? customerOptions : []}
                                  placeholder='Select customer(s)'
                                  isMulti
                                  value={
                                    customerOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.accounts) &&
                                        (values.accounts as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions: any) => {
                                    setFieldValue(
                                      'accounts',
                                      selectedOptions
                                        ? selectedOptions.map((option: any) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided : any) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='languages' className='form-label fs-7 mb-1'>
                                  Language(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={values?.langs?.length === languageOptions?.length}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'langs',
                                        languageOptions?.map((option: any) => option.value)
                                      )
                                    } else {
                                      setFieldValue('langs', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('langs')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingLanguage ? languageOptions : []}
                                  placeholder='Select Language(s)'
                                  isMulti
                                  value={
                                    languageOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.langs) &&
                                        (values.langs as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions : any) => {
                                    setFieldValue(
                                      'langs',
                                      selectedOptions
                                        ? selectedOptions.map((option: any) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided: any) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='serviceType' className='form-label fs-7 mb-1'>
                                  Service Type(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={
                                    values?.serviceTypes?.length === serviceTypeOptions?.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'serviceTypes',
                                        serviceTypeOptions?.map((option: any) => option.value)
                                      )
                                    } else {
                                      setFieldValue('serviceTypes', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('serviceTypes')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingServicetypes ? serviceTypeOptions : []}
                                  placeholder='Select Service Type(s)'
                                  isMulti
                                  value={
                                    serviceTypeOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.serviceTypes) &&
                                        (values.serviceTypes as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions: any) => {
                                    setFieldValue(
                                      'serviceTypes',
                                      selectedOptions
                                        ? selectedOptions.map((option: any) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided: any) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='communicationType' className='form-label fs-7 mb-1'>
                                  Communication Type(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={
                                    values?.communicationTypes?.length ===
                                    communicationTypeOptions?.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'communicationTypes',
                                        communicationTypeOptions?.map((option: any) => option.value)
                                      )
                                    } else {
                                      setFieldValue('communicationTypes', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('communicationTypes')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={
                                    !isFetchingCommunicationtypes ? communicationTypeOptions : []
                                  }
                                  placeholder='Select : All'
                                  isMulti
                                  value={
                                    communicationTypeOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.communicationTypes) &&
                                        (values.communicationTypes as string[]).includes(
                                          option.value
                                        )
                                    ) || []
                                  }
                                  onChange={(selectedOptions: any) => {
                                    setFieldValue(
                                      'communicationTypes',
                                      selectedOptions
                                        ? selectedOptions.map((option: any) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided: any) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-end pt-5'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      data-kt-menu-dismiss='true'
                      onClick={() => {
                        resetForm()
                        dispath(
                          appointmentsCallenderFilterData({
                            accounts: [],
                            langs: [],
                            serviceTypes: [],
                            communicationTypes: [],
                          })
                        )
                        fetchAppoinmentDetailsUsingDate(date, {
                          accounts: [],
                          langs: [],
                          serviceTypes: [],
                          communicationTypes: [],
                        })
                        setShowDropdownMenu(false)
                        setIsFilterApplied(false)
                      }}
                    >
                      Reset
                    </button>

                    <button
                      type='submit'
                      data-testid='filter-button'
                      aria-expanded='false'
                      role='button'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      onClick={(e) => handleSubmit}
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Apply
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}
