import React, {FC, useState, useEffect} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select, {MultiValue} from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {roleQUERIES} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../members-management/users-list/core/_models'
import {useAuth} from '../../../modules/auth'
import {Form, Formik} from 'formik'
import {RootState} from '../../../redux/store'
import {useSelector, useDispatch} from 'react-redux'
import {
  allAppointmentsCurrentPage,
  allAppointmentsFilterData,
  allAppointmentsRowsPerPage,
} from '../../../redux/tableSlice/tableSlice'
import toaster from '../../../../Utils/toaster'

type Props = {
  setAllAppointments: any
  setCurrentPage: any
  filterData: any
  fetchAllAppointments: any
  searchQuery: any
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
}
export function AppointmentViewTableFilterDropdown({
  setAllAppointments,
  setCurrentPage,
  searchQuery,
  filterData,
  fetchAllAppointments,
  setTotalPages,
}: Props) {
  const {currentUser} = useAuth()
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const dispatch = useDispatch()
  const {rowsPerPage} = useSelector((state: any) => ({
    rowsPerPage: state.table.allAppointments[0].rowsPerPage,
  }))
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  useEffect(() => {
    if (
      filterData?.accounts?.length > 0 ||
      filterData?.communicationTypes?.length > 0 ||
      filterData?.langs?.length > 0 ||
      filterData?.serviceTypes?.length > 0 ||
      filterData?.startDate ||
      filterData?.endDate ||
      filterData?.status?.length > 0
    ) {
      setIsFilterApplied(true)
    }
  }, [filterData])

  const {items: responseCustomer, loading: isFetchingCustomer} = useSelector(
    (state: RootState) => state.customers
  )
  const {items: responseLanguage, loading: isFetchingLanguage} = useSelector(
    (state: RootState) => state.languages
  )
  const {items: responseServicetypes, loading: isFetchingServicetypes} = useSelector(
    (state: RootState) => state.servicetypes
  )
  const {items: responseCommunicationtypes, loading: isFetchingCommunicationtypes} = useSelector(
    (state: RootState) => state.communicationtypes
  )

  const languageOptions =
    responseLanguage?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const serviceTypeOptions =
    responseServicetypes?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const communicationTypeOptions =
  responseCommunicationtypes
  ?.filter((item: any) => item.identification !== 'ON_DEMAND') // Filter out items with identification 'ON_DEMAND'
  .map((d: any) => ({
    value: d.key ?? '',
    label: d.value ?? '',
  })) ?? []

  const customerOptions =
    responseCustomer?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []
  const [data, setdata] = useState(filterData)
  const handleSubmit = (values: any) => {
    try {
      dispatch(allAppointmentsCurrentPage(1))
      fetchAllAppointments(values, searchQuery, 1, rowsPerPage)
      setShowDropdownMenu(false)
      setIsFilterApplied(true)
      dispatch(allAppointmentsFilterData({...values}))
    } catch (error) {
      console.log(error)
      toaster('error', 'Server Error')
    }
  }
  const status = [
    {value: '0', label: 'Open'},
    {value: '1', label: 'Ready to Assign'},
    {value: '2', label: 'Confirmed'},
    {value: '7', label: 'Accepted'},
    {value: '3', label: 'No interpreter'},
    {value: '4', label: 'Abandoned'},
    {value: '5', label: 'Complete'},
    {value: '6', label: 'Cancelled'},
  ]
  const getFilteredStatusOptions = () => {
    if (currentUser?.result.userType === 'INTERPRETER') {
      return status.filter((option) => ['0', '2', '4', '5', '6', '7'].includes(option.value))
    }
    if (currentUser?.result.userType === 'SYSTEM') {
      return status.filter((option) => ['0', '1', '2', '3', '4', '5', '6'].includes(option.value))
    }
    if (currentUser?.result.userType === 'CONSUMER') {
      return status.filter((option) => ['0', '2', '3', '4', '5', '6'].includes(option.value))
    }

    return status
  }

  const handleReset = async () => {
    setdata({
      accounts: [],
      langs: [],
      serviceTypes: [],
      communicationTypes: [],
      startDate: undefined,
      endDate: undefined,
      status: [],
    })

    dispatch(allAppointmentsCurrentPage(1))
    dispatch(allAppointmentsRowsPerPage(10))
    dispatch(
      allAppointmentsFilterData({
        accounts: [],
        langs: [],
        serviceTypes: [],
        communicationTypes: [],
        status: [],
        startDate: null,
        endDate: null,
      })
    )
    fetchAllAppointments(
      {
        accounts: [],
        langs: [],
        serviceTypes: [],
        communicationTypes: [],
        startDate: undefined,
        endDate: undefined,
        status: [],
      },
      searchQuery,
      1,
      10
    )
    setShowDropdownMenu(false)
    setIsFilterApplied(false)
    resetForm()
  }
  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-350px'>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>
            <Formik
              enableReinitialize
              initialValues={data}
              onSubmit={async (values, {setSubmitting, resetForm}) => {
                setSubmitting(true)
                setdata(values)
                handleSubmit(values)
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                resetForm,
                values,
                errors,
                touched,
                isValid,
                dirty,
                ...formik
              }) => (
                <Form>
                  <div className='separator border-gray-200'></div>
                  <div className='card px-4 mt-4'>
                    <div className='row flex-column'>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                                  Customer Name(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={values?.accounts?.length === customerOptions?.length}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'accounts',
                                        customerOptions?.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('accounts', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('accounts')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingCustomer ? customerOptions : []}
                                  placeholder='Select Customer(s)'
                                  isMulti
                                  value={
                                    customerOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.accounts) &&
                                        (values.accounts as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions: any[]) => {
                                    setFieldValue(
                                      'accounts',
                                      selectedOptions
                                        ? selectedOptions.map((option: { value: any }) => option.value)
                                        : []
                                    )
                                  }}
                                  onBlur={(e: any) => setFieldTouched('customers', true)}
                                  styles={{
                                    control: (provided: any) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='languages' className='form-label fs-7 mb-1'>
                                  Language(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={values?.langs?.length === languageOptions?.length}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'langs',
                                        languageOptions?.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('langs', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('langs')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingLanguage ? languageOptions : []}
                                  placeholder='Select Language(s)'
                                  isMulti
                                  value={
                                    languageOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.langs) &&
                                        (values.langs as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions: any[]) => {
                                    setFieldValue(
                                      'langs',
                                      selectedOptions
                                        ? selectedOptions.map((option: { value: any }) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided: any) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='serviceType' className='form-label fs-7 mb-1'>
                                  Service Type(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={
                                    values?.serviceTypes?.length === serviceTypeOptions?.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'serviceTypes',
                                        serviceTypeOptions?.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('serviceTypes', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('serviceTypes')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingServicetypes ? serviceTypeOptions : []}
                                  placeholder='Select : All'
                                  isMulti
                                  value={
                                    serviceTypeOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.serviceTypes) &&
                                        (values.serviceTypes as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions: any[]) => {
                                    setFieldValue(
                                      'serviceTypes',
                                      selectedOptions
                                        ? selectedOptions.map((option: { value: any }) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided: any) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='communicationType' className='form-label fs-7 mb-1'>
                                  Communication Type(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={
                                    values?.communicationTypes?.length ===
                                    communicationTypeOptions?.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'communicationTypes',
                                        communicationTypeOptions?.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('communicationTypes', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('communicationTypes')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={
                                    !isFetchingCommunicationtypes ? communicationTypeOptions : []
                                  }
                                  placeholder='Select Communication Type(s)'
                                  isMulti
                                  value={
                                    communicationTypeOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.communicationTypes) &&
                                        (values.communicationTypes as string[]).includes(
                                          option.value
                                        )
                                    ) || []
                                  }
                                  onChange={(selectedOptions: any[]) => {
                                    setFieldValue(
                                      'communicationTypes',
                                      selectedOptions
                                        ? selectedOptions.map((option: { value: any }) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided: any) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='status' className='form-label fs-7 mb-1'>
                                  Status
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={
                                    values?.status?.length === getFilteredStatusOptions()?.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'status',
                                        getFilteredStatusOptions()?.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('status', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('status')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  value={
                                    getFilteredStatusOptions()?.filter(
                                      (option: any) =>
                                        Array.isArray(values.status) &&
                                        (values.status as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions: any[]) => {
                                    setFieldValue(
                                      'status',
                                      selectedOptions
                                        ? selectedOptions.map((option: { value: any }) => option.value)
                                        : []
                                    )
                                  }}
                                  options={getFilteredStatusOptions()}
                                  isMulti
                                  placeholder='Select Status'
                                  styles={{
                                    control: (provided: any) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='fv-row'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <label htmlFor='startDate' className='form-label fs-7 mb-1'>
                              Start Date
                            </label>
                            <input
                              {...formik.getFieldProps('startDate')}
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              value={values.startDate}
                              onChange={(e) => setFieldValue('startDate', e.target.value)}
                              placeholder='Enter Subject'
                            />
                          </div>
                          <div className='col-sm-6'>
                            <label htmlFor='endDate' className='form-label fs-7 mb-1'>
                              End Date
                            </label>
                            <input
                              {...formik.getFieldProps('endDate')}
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              value={values.endDate}
                              onChange={(e) => setFieldValue('endDate', e.target.value)}
                              placeholder='Start Time'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-end pt-5 px-4'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      data-kt-menu-dismiss='true'
                      onClick={handleReset}
                    >
                      Reset
                    </button>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      onClick={(e) => handleSubmit}
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Apply
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}
function resetForm() {
  throw new Error('Function not implemented.')
}
