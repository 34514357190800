/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {UsersListWrapper} from './users-list/UsersList'

type Props = {
  className: string
  userType: string
}

const UsersViewTable: React.FC<Props> = ({className, userType}) => {
  return (
    <div className={`card ${className}`} data-testid="users-list-wrapper">
      <UsersListWrapper userType={userType} />
    </div>
  )
}

export {UsersViewTable}
