import React, {useState, useEffect, useRef} from 'react'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Select from 'react-select'
import {BillingCustomerFilterDropdown} from './BillingCustomerFilterDropdown'
import {Form, Formik} from 'formik'
import axios from 'axios'
import toaster from '../../../../Utils/toaster'
import {Modal} from 'bootstrap'
import * as Yup from 'yup'
import {useDispatch , useSelector} from 'react-redux'
import {CustomerInvoiceTable} from './CustomerInvoiceViewTable'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useAuth} from '../../../modules/auth'
import { CustomerInvoiceCurrentPage, CustomerInvoiceRowsPerPage, CustomerInvoiceSearch } from '../../../redux/tableSlice/tableSlice'
const moment = require('moment-timezone')

const API_URL = process.env.REACT_APP_API_URL
const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10

const createInvoicesSchema = Yup.object()
  .shape({
    from: Yup.date().required('Required').typeError('Invalid date'),
    to: Yup.date()
      .required('Required')
      .typeError('Invalid date')
      .when('from', (from, schema) => {
        return from
          ? schema.min(from, 'To date must be greater than or equal to From date')
          : schema
      }),
    customers: Yup.array().required('Required').min(1, 'Please select at least one customer'),
    term: Yup.string().required('Required'),
    invoiceDate: Yup.date().required('Required').typeError('Invalid date'),
    isIncludeCalls: Yup.boolean(),
    isIncludeAppointments: Yup.boolean(),
    fK_TimeZoneCode: Yup.string().required('Required'),
  })
  .test('at-least-one-true', function (value) {
    const {isIncludeCalls, isIncludeAppointments} = value || {}

    if (!isIncludeCalls && !isIncludeAppointments) {
      return this.createError({
        path: 'isIncludeCalls', // You can choose where to attach the error
        message: 'Please select at least one of Include Calls or Include Appointments',
      })
    }

    return true
  })

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    border: '1px solid #e4e6ef',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#a4afc4',
  }),
}

/* eslint-disable jsx-a11y/anchor-is-valid */
export function CustomerInvoice() {
  const tableRef = useRef<any>(null)
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const inputRef = useRef<HTMLInputElement>(null)
  const [allAccounts, setAllAccounts] = useState<any[]>([])
  const [terms, setTerms] = useState<any[]>([])
  const [allStatus, setAllStatus] = useState<any[]>([])
  const [initialValues, setInitialValues] = useState({
    from: '',
    to: moment().format('YYYY-MM-DD'),
    customers: [],
    term: '',
    invoiceDate: moment().format('YYYY-MM-DD'),
    isIncludeCalls: false,
    isIncludeAppointments: false,
    fK_TimeZoneCode: '',
  })
  const [isLoading, setIsLoading] = useState(true)
  const [customerInvoices, setCustomerInvoices] = useState([])
  const [unavailableRates, setUnavailableRates] = useState([])
  const [timezoneOptions, setTimeZoneOptions] = useState<any[]>([])

  const [totalPages, setTotalPages] = useState(0)
  const [totalItems, setTotalItems] = useState(0)

  const {currentPage , rowsPerPage , searchQuery , filterData} = useSelector((state: any) => ({
    currentPage: state.table.CustomerInvoice[0].currentPage,
    rowsPerPage: state.table.CustomerInvoice[0].rowsPerPage,
    searchQuery: state.table.CustomerInvoice[0].searchQuery,
    filterData: state.table.CustomerInvoice[0].filterData
  }))

  const fetchCustomerInvoice = async (passingData: any, searchQuery: any, currentPage: number , rowsPerPage: number) => {
    setIsLoading(true)
    try {
      let response = await axios.post(
        `${API_URL}/invoice/overview-filter`,
        {
          ...passingData,
          fromDate: passingData.fromDate === '' ? null : passingData.fromDate,
          company:
            passingData.company?.length > 0
              ? passingData.company.map((d: any) => d.value.toString())
              : [],
          status: passingData.status?.length > 0 ? passingData.status.map((d: any) => d.value) : [],
          filterType: 'I',
        },
        {
          params: {
            page: currentPage,
            items_per_page: rowsPerPage,
            ...(searchQuery.length > 0 
              ? {search: searchQuery}
              : searchQuery
            ),
          },
        }
      )
      const {data, payload} = response.data
      setCustomerInvoices(data)
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const searchValue = inputRef.current?.value || ''
      dispatch(CustomerInvoiceSearch(searchValue))
      dispatch(CustomerInvoiceCurrentPage(1))
      fetchCustomerInvoice(filterData, {search: searchValue}, 1, rowsPerPage)
    }
  }

  const hideModal = (elementID: string) => {
    const modal = document.getElementById(elementID)
    if (modal) {
      const modalInstance = Modal.getInstance(modal)
      if (modalInstance) modalInstance.hide()
    }
  }

  const fetchAccounts = async () => {
    try {
      let response = await axios.get(`${API_URL}/customer/getall-shortlist/CONSUMER/0`)
      setAllAccounts(
        response?.data?.data.map((d: {key: string; value: string}) => ({
          value: d.key ?? '',
          label: d.value ?? '',
        })) ?? []
      )
    } catch (error) {
      console.log(error)
    }
  }

  const fetchTerms = async () => {
    try {
      let response = await axios.get(`${API_URL}/invoice/ddlist-terms`)
      setTerms(
        response?.data?.data.map((d: {key: string; value: string}) => ({
          value: d.key ?? '',
          label: d.value ?? '',
        })) ?? []
      )
    } catch (error) {
      console.log(error)
    }
  }

  const fetchStatus = async () => {
    try {
      let response = await axios.get(`${API_URL}/invoice/ddlist-invoice-status`)
      if (
        currentUser?.result.userType === 'INTERPRETER' ||
        currentUser?.result.userType === 'CONSUMER'
      ) {
        setAllStatus(
          response?.data?.data
            .filter((d: any) => d.identification !== 'DR' && d.identification !== 'VO')
            .map((d: {key: string; value: string}) => ({
              value: d.key ?? '',
              label: d.value ?? '',
            })) ?? []
        )
      } else {
        setAllStatus(
          response?.data?.data.map((d: {key: string; value: string}) => ({
            value: d.key ?? '',
            label: d.value ?? '',
          })) ?? []
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchTimeZones = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/time-zone/active-shortlist`)
      setTimeZoneOptions(
        response?.data?.data?.map((d: any) => ({
          value: d.code,
          label: d.name,
        })) ?? []
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchAccounts()
    fetchTerms()
    fetchStatus()
    fetchTimeZones()
    if (inputRef.current) {
      inputRef.current.value = searchQuery || '';
    }
    fetchCustomerInvoice(filterData, searchQuery, currentPage, rowsPerPage)
  }, [])

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Customer Invoices',
    sheet:
      filterData?.fromDate !== ''
        ? `${filterData?.fromDate} - ${filterData?.toDate}`
        : `Until ${filterData?.toDate}`,
  })

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_add_cus-invoice'>
        <div className='modal-dialog'>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={createInvoicesSchema}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              setSubmitting(true)
              try {
                const response = await axios.post(`${API_URL}/invoice/invoice`, {
                  ...values,
                  customers: values.customers.map((d: any) => d.value),
                })

                if (response.data.status === 'S') {
                  toaster('success', response.data.text ?? 'Successfully Updated')
                  resetForm()
                  hideModal('kt_add_cus-invoice')
                  dispatch(CustomerInvoiceCurrentPage(1))
                  fetchCustomerInvoice(filterData, searchQuery, 1, rowsPerPage)
                } else if (response.data.status === 'E') {
                  toaster('error', response.data.text ?? 'Updated Error')
                  if (response.data.text === 'Rates error!') {
                    setUnavailableRates(response.data.result)
                  }
                }
              } catch (ex) {
                toaster('error', 'Server Error')
                console.error(ex)
              } finally {
                setSubmitting(true)
              }
            }}
          >
            {({
              isSubmitting,
              handleChange,
              handleBlur,
              setFieldTouched,
              setFieldValue,
              handleSubmit,
              resetForm,
              values,
              errors,
              touched,
              isValid,
              dirty,
              ...formik
            }) => (
              <Form>
                <div className='modal-content'>
                  <div className='modal-header py-2'>
                    {unavailableRates?.length > 0 ? (
                      <h4 className='modal-title'>Unavailable Rates</h4>
                    ) : (
                      <h4 className='modal-title'>Create Invoices</h4>
                    )}
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => {
                        resetForm()
                        setUnavailableRates([])
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='row g-4 g-xl-6'>
                      {unavailableRates?.length === 0 ? (
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <div className='mb-3'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label fs-7'
                            >
                              Time Zone
                            </label>

                            <div className='w-100'>
                              <Select
                                className='react-select-styled react-select-solid react-select-sm custom-input-height'
                                classNamePrefix='react-select'
                                options={timezoneOptions}
                                placeholder='Select Time Zone'
                                styles={customStyles}
                                value={
                                  values.fK_TimeZoneCode === ''
                                    ? ''
                                    : timezoneOptions.find(
                                        (option: any) => option.value === values.fK_TimeZoneCode
                                      )
                                }
                                noOptionsMessage={() => 'No time zone found'}
                                onChange={(selectedOption: any) => {
                                  setFieldValue('fK_TimeZoneCode', selectedOption?.value)
                                }}
                                onBlur={(e: any) => setFieldTouched('fK_TimeZoneCode', true)}
                              />
                              {errors?.fK_TimeZoneCode && touched?.fK_TimeZoneCode && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{errors.fK_TimeZoneCode}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='mb-3'>
                            <div className='d-flex w-100 flex-1 align-items-center'>
                              <div className='col-sm-5 col-md-5 col-lg-5'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1'
                                >
                                  From
                                </label>
                                <input
                                  type='date'
                                  id='from'
                                  name='from'
                                  className='form-control form-control-white form-select-sm custom-input-height'
                                  placeholder='Enter Subject'
                                  value={values.from}
                                  onChange={(e) => setFieldValue('from', e.target.value)}
                                  onBlur={() => {
                                    setFieldTouched('from', true)
                                    setFieldTouched('to', true)
                                  }}
                                  max={new Date().toISOString().split('T')[0]}
                                />
                              </div>
                              <div className='col-sm-2 col-md-2 col-lg-2 h-100'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='form-label fs-7 mb-1'
                                ></label>
                                <span className='fs-7 text-gray-600  d-flex align-items-center justify-content-center'>
                                  -
                                </span>
                              </div>
                              <div className='col-sm-5 col-md-5 col-lg-5'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1'
                                >
                                  To
                                </label>
                                <input
                                  type='date'
                                  id='to'
                                  name='to'
                                  className='form-control form-control-white form-select-sm custom-input-height'
                                  placeholder='Start Time'
                                  value={values.to}
                                  onChange={(e) => setFieldValue('to', e.target.value)}
                                  onBlur={() => {
                                    setFieldTouched('from', true)
                                    setFieldTouched('to', true)
                                  }}
                                  max={new Date().toISOString().split('T')[0]}
                                />
                              </div>
                            </div>
                            {((errors.from && touched?.from) || (errors.to && touched?.to)) && (
                              <div className='fv-plugins-message-container mx-1'>
                                {errors.from && touched?.from && (
                                  <div className='fv-help-block'>{String(errors?.from)}</div>
                                )}
                                {errors?.to && touched?.to && (
                                  <div className='fv-help-block'>{String(errors?.to)}</div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className='mb-3'>
                            <div className='row d-flex mb-3'>
                              <div className='col-md-12'>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label fs-7 mb-1'
                                    >
                                      Customer(s)
                                    </label>
                                  </div>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      checked={values?.customers?.length === allAccounts?.length}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setFieldValue('customers', allAccounts)
                                        } else {
                                          setFieldValue('customers', [])
                                        }
                                      }}
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg1'>
                                      All
                                    </label>
                                  </div>
                                </div>
                                <Select
                                  {...formik.getFieldProps('customers')}
                                  isMulti
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={allAccounts}
                                  placeholder='Select Customer'
                                  styles={customStyles}
                                  value={values.customers}
                                  onChange={(selectedOption: any) => {
                                    setFieldValue('customers', selectedOption)
                                  }}
                                  onBlur={(e: any) => setFieldTouched('customers', true)}
                                />
                                {errors?.customers && touched?.customers && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{String(errors?.customers ?? '')}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='mb-3 row'>
                            <div className='col-md-6'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label fs-7 mb-1'
                              >
                                Terms
                              </label>
                              <Select
                                className='react-select-styled react-select-solid react-select-sm'
                                classNamePrefix='react-select'
                                options={terms}
                                placeholder='Select Term'
                                onChange={(option: { value: any }) => setFieldValue('term', option?.value || '')}
                                value={
                                  values.term === ''
                                    ? ''
                                    : terms?.find((option) => option.value === values.term)
                                }
                                onBlur={(e: any) => setFieldTouched('term', true)}
                                styles={{
                                  control: (provided: any) => ({
                                    ...provided,
                                    width: '100%',
                                    border: '1px solid #e4e6ef',
                                  }),
                                }}
                              />
                              {errors?.term && touched?.term && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{String(errors?.term ?? '')}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className='col-md-6'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label fs-7 mb-1'
                              >
                                Invoice Date
                              </label>
                              <input
                                type='date'
                                id='invoiceDate'
                                name='invoiceDate'
                                className='form-control form-control-white form-select-sm custom-input-height'
                                placeholder='Enter Subject'
                                value={values.invoiceDate}
                                onChange={(e) => setFieldValue('invoiceDate', e.target.value)}
                                onBlur={() => {
                                  setFieldTouched('invoiceDate', true)
                                }}
                                max={new Date().toISOString().split('T')[0]}
                              />
                              {errors?.invoiceDate && touched?.invoiceDate && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{String(errors?.invoiceDate ?? '')}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='mb-3'>
                            <div className='row d-flex mb-3'>
                              <div className='col-md-6'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    id='isIncludeCalls'
                                    name='isIncludeCalls'
                                    checked={values?.isIncludeCalls}
                                    onChange={(e) =>
                                      setFieldValue('isIncludeCalls', e.target.checked)
                                    }
                                    onBlur={handleBlur}
                                  />
                                  <label className='form-check-label' htmlFor='flexRadioLg1'>
                                    Include Calls
                                  </label>
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    id='isIncludeAppointments'
                                    name='isIncludeAppointments'
                                    checked={values?.isIncludeAppointments}
                                    onChange={(e) =>
                                      setFieldValue('isIncludeAppointments', e.target.checked)
                                    }
                                    onBlur={handleBlur}
                                  />
                                  <label className='form-check-label' htmlFor='flexRadioLg1'>
                                    Include Appointments
                                  </label>
                                </div>
                              </div>
                            </div>
                            {errors?.isIncludeCalls && touched?.isIncludeCalls && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{String(errors?.isIncludeCalls ?? '')}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className='table-responsive'>
                          <div className='card'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2 table-hover'>
                              <thead>
                                <tr className='fw-semibold text-muted text-uppercase'>
                                  <th className='min-w-150px '>Customer</th>
                                  <th className='min-w-200px '>Communication Type</th>
                                  <th className='min-w-150px '>Service Type</th>
                                  <th className='min-w-200px '>Language</th>
                                  <th className='min-w-150px '>Day</th>
                                  <th className='min-w-100px text-end'>call Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                {unavailableRates?.map((item: any, index: any) => (
                                  <tr>
                                    <td>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <a className='text-gray-800 text-hover-primary fs-6'>
                                          {item?.customer ?? '-'}
                                        </a>
                                      </div>
                                    </td>
                                    <td>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <a className='text-gray-800 text-hover-primary fs-6'>
                                          {item?.communicationType ?? '-'}
                                        </a>
                                      </div>
                                    </td>
                                    <td>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <a className='text-gray-800 text-hover-primary fs-6'>
                                          {item?.serviceType ?? '-'}
                                        </a>
                                      </div>
                                    </td>

                                    <td>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <a className='text-gray-800 text-hover-primary fs-6'>
                                          {item?.fromLanguage ?? '-'} - {item?.toLanguage ?? '-'}
                                        </a>
                                      </div>
                                    </td>

                                    <td>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <a className='text-gray-800 text-hover-primary fs-6'>
                                          {item?.day ?? '-'}
                                        </a>
                                      </div>
                                    </td>
                                    <td>
                                      <div className='d-flex justify-content-start flex-column text-end'>
                                        <a className='text-gray-800 text-hover-primary fs-6'>
                                          {item?.callTime ?? '-'}
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              {/* end::Table body */}
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='separator my-3'></div>
                    {unavailableRates?.length === 0 && (
                      <span className='fs-8 fst-italic'>
                        Note: appointments with signatures still pending will not be included.
                      </span>
                    )}
                  </div>
                  {unavailableRates?.length === 0 && (
                    <div className='modal-footer py-3'>
                      <button
                        type='button'
                        className='btn btn-light btn-sm'
                        data-bs-dismiss='modal'
                        onClick={() => {
                          resetForm()
                          setUnavailableRates([])
                        }}
                      >
                        Close
                      </button>

                      <button
                        type='button'
                        className='btn btn-primary btn-sm'
                        onClick={() => handleSubmit()}
                        disabled={isSubmitting || !dirty}
                      >
                        Create Invoice
                      </button>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {/* <div className='modal fade' tabIndex={-1} id='kt_invoice-created'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Invoice Created</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center mb-5'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/task.gif' alt='' />
                </div>
              </div>
              <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                Please review/edit as needed then send for approval once ready.
              </h4>
            </div>
            <div className='modal-footer py-3'>
              <Link to='/customer-invoice/customer-invoice-viewer'>
                <button type='button' className='btn btn-primary btn-sm' data-bs-dismiss='modal'>
                  Ok
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div> */}

      <div className='card-body p-0' style={{minHeight: '70vh'}}>
        <div className='d-flex justify-content-between mb-3 align-items-center flex-wrap'>
          <div className='card-title d-flex align-items-center me-4 mb-3'>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center'>
                {currentUser?.result?.userType === 'SYSTEM' ? (
                  <h5 className='text-black fs-4 fw-semibold mb-0'>Customer Invoices</h5>
                ) : (
                  <h5 className='text-black fs-4 fw-semibold mb-0'>My Invoices</h5>
                )}
              </div>
              <div className='d-flex flex-wrap fs-6 '>
                {currentUser?.result?.userType === 'SYSTEM' ? (
                  <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                    Manage Customer Invoices
                  </p>
                ) : (
                  <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                    Manage My Invoices
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className='d-flex'>
            <div className='d-flex flex-wrap'>
              <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                <input
                  ref={inputRef}
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                  placeholder='Search'
                  onKeyDown={handleKeyDown}
                  onChange={() => {
                    const value = inputRef.current?.value || ''
                    if(value === '') {
                      dispatch(CustomerInvoiceSearch(''))
                      fetchCustomerInvoice(filterData, '', 1 , rowsPerPage)
                    }
                  }}
                />

                <button
                  type='button'
                  className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                  onClick={() => {
                    const searchValue = inputRef.current?.value || ''
                    dispatch(CustomerInvoiceSearch(searchValue))
                    dispatch(CustomerInvoiceCurrentPage(1))
                    fetchCustomerInvoice(filterData, {search: searchValue}, 1 , rowsPerPage)
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                </button>
              </div>
              <div className='my-0 me-3' style={{display: 'flex', justifyContent: 'end'}}>
                <BillingCustomerFilterDropdown
                  allAccounts={allAccounts}
                  filterData={filterData}
                  setFilterData={filterData}
                  setCurrentPage={currentPage}
                  allStatus={allStatus}
                  fetchCustomerInvoice={fetchCustomerInvoice}
                  searchQuery={searchQuery}
                />
              </div>
              {/* <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'>Send to Quick Books</Tooltip>}
              >
                <div>
                  <button type='button' className='btn btn-sm btn-success btn-icon me-3'>
                    qb
                  </button>
                </div>
              </OverlayTrigger> */}
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'>Download CSV</Tooltip>}
              >
                <button
                  type='button'
                  className='btn btn-primary btn-sm me-3 btn-icon'
                  onClick={onDownload}
                  data-bs-toggle='modal'
                  data-bs-target='#kt_download_list_modal'
                >
                  <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-muted' />
                </button>
              </OverlayTrigger>
              {currentUser?.result?.userType === 'SYSTEM' && (
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip id='tooltip-filter'>Create Invoice</Tooltip>}
                >
                  <div>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary btn-icon'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_add_cus-invoice'
                    >
                      <i className='bi bi-plus fs-2'></i>
                    </button>
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </div>
        </div>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <CustomerInvoiceTable
            className='table align-middle table-row-dashed fs-6 gy-5'
            isLoading={isLoading}
            data={customerInvoices}
            tableRef={tableRef}
          />
          {/* end::Table */}
        </div>
        <CommonPaginationModel
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          total={totalItems}
          onPageChange={(page: number) => {
            dispatch(CustomerInvoiceCurrentPage(page))
            fetchCustomerInvoice(filterData, searchQuery, page, rowsPerPage)
          }}
          onRowsPerPageChange={(newRowsPerPage: number) => {
            dispatch(CustomerInvoiceCurrentPage(1))
            dispatch(CustomerInvoiceRowsPerPage(newRowsPerPage))
            fetchCustomerInvoice(filterData, searchQuery, 1, newRowsPerPage)
          }}
        />
        {/* end::Table container */}
      </div>
    </>
  )
}
