import React, {useEffect, useState} from 'react'
import {Overview} from './Overview'
import {CustomerInvoice} from './CustomerInvoice'
import {InterpreterInvoice} from './InterpreterInvoice'
import {MyInvoice} from './MyInvoice'
import {useAuth} from '../../../modules/auth'
import {useNavigate, useParams} from 'react-router-dom'
import {CustomerUninvoicedCalls} from './un-invoiced-calls/CustomerUninvoicedCalls'
import {InterperterUninvoicedCalls} from './un-invoiced-calls/InterperterUninvoicedCalls'

export function BillingView() {
  const {currentUser} = useAuth()
  const {id} = useParams()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(currentUser?.result?.userType === 'SYSTEM' ? 2 : 1)

  useEffect(() => {
    if (id) {
      if (currentUser?.result.userType === 'SYSTEM') {
        if (id === '2' || id === '3' || id === '4' || id === '5') {
          setActiveTab(Number(id))
        } else {
          navigate('error/404')
        }
      }
      if (currentUser?.result?.userType === 'CONSUMER') {
        if (id === '1' || id === '4') {
          setActiveTab(Number(id))
        } else {
          navigate('error/404')
        }
      }
      if (currentUser?.result?.userType === 'INTERPRETER') {
        if (id === '1' || id === '5') {
          setActiveTab(Number(id))
        } else {
          navigate('error/404')
        }
      }
    }
  }, [id])

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    // setActiveTab(tabNumber)
    navigate(`/billing/${tabNumber}`)
  }
  return (
    <>
      <div className='mb-3 d-flex flex-column'>
        {/* <div className='d-flex flex-column'>
          <h1 className='mb-0 fw-semibold fs-2'>Billing</h1>
          <span className='text-gray-500'>Manage Invoices</span>
        </div> */}
        <div className='d-flex'>
          <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
            Billing
          </h1>
          {/* <div className='d-flex align-items-start'>
            <UnderDevelopmentBadge className='' />
          </div> */}
        </div>
        <div className='d-flex'>
          <p className='text-gray-500 fs-5 mb-2'>Manage Invoices</p>
        </div>
      </div>
      <div className='card mb-5'>
        <div
          className='card-header py-0'
          style={{minWidth: '300px', overflowX: 'auto', minHeight: '50px'}}
        >
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
            {/* {currentUser?.result?.userType === 'SYSTEM' && (
              <li className='nav-item'>
                <a
                  className={`nav-link fs-7 text-gray-400   ${
                    activeTab === 1 ? 'text-active-dark fw-bold active show' : ''
                  }`}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_1'
                  onClick={() => handleTabClick(1)}
                >
                  Overview
                </a>
              </li>
            )} */}
            {(currentUser?.result?.userType === 'CONSUMER' ||
              currentUser?.result?.userType === 'INTERPRETER') && (
              <li className='nav-item'>
                <a
                  className={`nav-link fs-7 text-gray-400 ${
                    activeTab === 1 ? 'text-active-dark fw-bold active show' : ''
                  }`}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_1'
                  onClick={() => handleTabClick(1)}
                >
                  My Invoice
                </a>
              </li>
            )}

            {currentUser?.result?.userType === 'SYSTEM' && (
              <li className='nav-item'>
                <a
                  className={`nav-link fs-7 text-gray-400 ${
                    activeTab === 2 ? 'text-active-dark fw-bold active show' : ''
                  }`}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_2'
                  onClick={() => handleTabClick(2)}
                >
                  Customer Invoice
                </a>
              </li>
            )}
            {currentUser?.result?.userType === 'SYSTEM' && (
              <li className='nav-item'>
                <a
                  className={`nav-link fs-7 text-gray-400 ${
                    activeTab === 3 ? 'text-active-dark fw-bold active show' : ''
                  }`}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_3'
                  onClick={() => handleTabClick(3)}
                >
                  Interpreter Invoice
                </a>
              </li>
            )}
            {(currentUser?.result?.userType === 'SYSTEM' ||
              currentUser?.result?.userType === 'CONSUMER') && (
              <li className='nav-item'>
                <a
                  className={`nav-link fs-7 text-gray-400 ${
                    activeTab === 4 ? 'text-active-dark fw-bold active show' : ''
                  }`}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_4'
                  onClick={() => handleTabClick(4)}
                >
                  {currentUser?.result?.userType === 'CONSUMER'
                    ? 'My Un-Invoiced calls'
                    : 'Customer Un-Invoiced calls'}
                </a>
              </li>
            )}
            {(currentUser?.result?.userType === 'SYSTEM' ||
              currentUser?.result?.userType === 'INTERPRETER') && (
              <li className='nav-item'>
                <a
                  className={`nav-link fs-7 text-gray-400 ${
                    activeTab === 5 ? 'text-active-dark fw-bold active show' : ''
                  }`}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_5'
                  onClick={() => handleTabClick(5)}
                >
                  {currentUser?.result?.userType === 'INTERPRETER'
                    ? 'My Un-Invoiced calls'
                    : 'Interpreter Un-Invoiced calls'}
                </a>
              </li>
            )}
          </ul>
        </div>

        <div className=''>
          <div className='tab-content' id='myTabContent'>
            {/* {activeTab === 1 && currentUser?.result?.userType === 'SYSTEM' && (
              <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                <div className='card-body '>
                  <Overview />
                </div>
              </div>
            )} */}
            {activeTab === 1 && currentUser?.result?.userType === 'INTERPRETER' && (
              <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                <div className='card-body '>
                  {' '}
                  <InterpreterInvoice />
                </div>
              </div>
            )}
            {activeTab === 1 && currentUser?.result?.userType === 'CONSUMER' && (
              <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                <div className='card-body '>
                  {' '}
                  <CustomerInvoice />
                </div>
              </div>
            )}
            {activeTab === 2 && currentUser?.result?.userType === 'SYSTEM' && (
              <div className='tab-pane fade show active' id='kt_tab_pane_2' role='tabpanel'>
                <div className='card-body '>
                  <CustomerInvoice />
                </div>
              </div>
            )}
            {activeTab === 3 && currentUser?.result?.userType === 'SYSTEM' && (
              <div className='tab-pane fade show active' id='kt_tab_pane_3' role='tabpanel'>
                <div className='card-body '>
                  {' '}
                  <InterpreterInvoice />
                </div>
              </div>
            )}
            {activeTab === 4 &&
              (currentUser?.result?.userType === 'SYSTEM' ||
                currentUser?.result?.userType === 'CONSUMER') && (
                <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
                  <div className='card-body '>
                    <CustomerUninvoicedCalls
                      callType={50}
                      pageTitle={
                        currentUser?.result?.userType === 'CONSUMER'
                          ? 'My Un-Invoiced Calls'
                          : 'Customer Un-Invoiced Calls'
                      }
                      downloadPage={'customer-uninvoiced-calls'}
                    />
                  </div>
                </div>
              )}
            {activeTab === 5 &&
              (currentUser?.result?.userType === 'SYSTEM' ||
                currentUser?.result?.userType === 'INTERPRETER') && (
                <div className='tab-pane fade show active' id='kt_tab_pane_5' role='tabpanel'>
                  <div className='card-body '>
                    {' '}
                    <InterperterUninvoicedCalls
                      callType={50}
                      pageTitle={
                        currentUser?.result?.userType === 'INTERPRETER'
                          ? 'My Un-Invoiced Calls'
                          : 'Interpreter Un-Invoiced Calls'
                      }
                      downloadPage={'Interpreter-uninvoiced-calls'}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  )
}
