import React, {useState, useEffect} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import './style.css'
import {Form, Formik} from 'formik'
import {Modal} from 'bootstrap'
import * as Yup from 'yup'
import {useDispatch , useSelector} from 'react-redux'
import toaster from '../../../../Utils/toaster'
import {useAuth} from '../../../modules/auth'
import { CustomerInvoiceCurrentPage, CustomerInvoiceFilter, CustomerInvoiceRowsPerPage } from '../../../redux/tableSlice/tableSlice'
const moment = require('moment-timezone')

const API_URL = process.env.REACT_APP_API_URL

const createInvoicesSchema = Yup.object().shape({
  fromDate: Yup.date().typeError('Invalid date'),
  toDate: Yup.date()
    .typeError('Invalid date')
    .when('fromDate', (fromDate, schema) => {
      return fromDate
        ? schema.min(fromDate, 'To date must be greater than or equal to From date')
        : schema
    }),
  company: Yup.array(),
})

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    maxHeight: '300px',
    border: '1px solid #e4e6ef',
    overflow: 'auto',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#a4afc4',
  }),
}

type Props = {
  allAccounts: any
  filterData: any
  setFilterData: any
  setCurrentPage: any
  allStatus: any
  fetchCustomerInvoice: any
  searchQuery: any
}

export function BillingCustomerFilterDropdown({
  allAccounts,
  filterData,
  setFilterData,
  setCurrentPage,
  allStatus,
  fetchCustomerInvoice,
  searchQuery,
}: Props) {
  const {currentUser} = useAuth()
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const dispatch = useDispatch()
  const {rowsPerPage} = useSelector((state: any) => ({
    rowsPerPage: state.table.CustomerInvoice[0].rowsPerPage,
  }))

  useEffect(() => {
    if (filterData?.fromDate !== '' || filterData?.company?.length > 0 || filterData?.status?.length > 0) {
      setIsFilterApplied(true)
    }
  }, [filterData])

  return (
    <Dropdown show={showDropdownMenu} onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
      <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
        <Dropdown.Toggle
          variant='primary'
          id='dropdown-basic'
          className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-danger' : ''}`}
        >
          <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
        </Dropdown.Toggle>
      </OverlayTrigger>

      <Dropdown.Menu className='p-4 w-400px' style={{minHeight: '100px'}}>
        <Formik
          enableReinitialize
          initialValues={filterData}
          validationSchema={createInvoicesSchema}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            setSubmitting(true)
            try {
              dispatch(CustomerInvoiceCurrentPage(1))
              dispatch(CustomerInvoiceFilter({
                ...values,
              }))
              fetchCustomerInvoice(values, searchQuery, 1 , rowsPerPage)
              setShowDropdownMenu(false)
              setIsFilterApplied(true)
            } catch (ex) {
              toaster('error', 'Server Error')
              console.error(ex)
            } finally {
              setSubmitting(true)
            }
          }}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue,
            handleSubmit,
            resetForm,
            values,
            errors,
            touched,
            isValid,
            dirty,
            ...formik
          }) => (
            <Form>
              <div className='px-4 pb-4'>
                <div className='fs-4 text-dark fw-semibolder'>Filter</div>
              </div>

              <div className='separator border-gray-200'></div>
              <div className='px-4 mt-4'>
                <div className='row flex-column'>
                  <div className='col-sm-12 col-md-12'>
                    {currentUser?.result?.userType === 'SYSTEM' && (
                      <div className='mb-3'>
                        <div className='col-md-12'>
                          <div className='d-flex justify-content-between align-items-center'>
                            <div>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                Customer(s)
                              </label>
                            </div>
                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                checked={values?.company?.length === allAccounts?.length}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setFieldValue('company', allAccounts)
                                  } else {
                                    setFieldValue('company', [])
                                  }
                                }}
                              />
                              <label className='form-check-label' htmlFor='flexRadioLg1'>
                                All
                              </label>
                            </div>
                          </div>
                          <Select
                            {...formik.getFieldProps('company')}
                            isMulti
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={allAccounts}
                            placeholder='Select Customer(s)'
                            styles={customStyles}
                            value={values.company}
                            onChange={(selectedOption: any) => {
                              setFieldValue('company', selectedOption)
                            }}
                            onBlur={(e: any) => setFieldTouched('company', true)}
                          />
                          {errors?.company && touched?.company && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{String(errors?.company ?? '')}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className='mb-3'>
                      <div className='col-md-12'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label fs-7 mb-1'
                            >
                              Status(s)
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={values?.status?.length === allStatus?.length}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue('status', allStatus)
                                } else {
                                  setFieldValue('status', [])
                                }
                              }}
                            />
                            <label className='form-check-label' htmlFor='flexRadioLg1'>
                              All
                            </label>
                          </div>
                        </div>
                        <Select
                          {...formik.getFieldProps('status')}
                          isMulti
                          className='react-select-styled react-select-solid react-select-sm'
                          classNamePrefix='react-select'
                          options={allStatus}
                          placeholder='Select Status'
                          styles={customStyles}
                          value={values.status}
                          onChange={(selectedOption: any) => {
                            setFieldValue('status', selectedOption)
                          }}
                          onBlur={(e: any) => setFieldTouched('status', true)}
                        />
                        {errors?.status && touched?.status && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{String(errors?.status ?? '')}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='fv-row mb-3'>
                    <div className='row '>
                      <div className='mb-3'>
                        <div className='d-flex w-100 flex-1 align-items-center'>
                          <div className='col-sm-5 col-md-5 col-lg-5'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label fs-7 mb-1'
                            >
                              From
                            </label>
                            <input
                              type='date'
                              id='fromDate'
                              name='fromDate'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              placeholder='Enter Subject'
                              value={values.fromDate}
                              onChange={(e) => setFieldValue('fromDate', e.target.value)}
                              onBlur={() => {
                                setFieldTouched('fromDate', true)
                                setFieldTouched('toDate', true)
                              }}
                              max={new Date().toISOString().split('T')[0]}
                            />
                          </div>
                          <div className='col-sm-2 col-md-2 col-lg-2 h-100'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label fs-7 mb-1'
                            ></label>
                            <span className='fs-7 text-gray-600  d-flex align-items-center justify-content-center'>
                              -
                            </span>
                          </div>
                          <div className='col-sm-5 col-md-5 col-lg-5'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label fs-7 mb-1'
                            >
                              To
                            </label>
                            <input
                              type='date'
                              id='toDate'
                              name='toDate'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              placeholder='Start Time'
                              value={values.toDate}
                              onChange={(e) => setFieldValue('toDate', e.target.value)}
                              onBlur={() => {
                                setFieldTouched('fromDate', true)
                                setFieldTouched('toDate', true)
                              }}
                              max={new Date().toISOString().split('T')[0]}
                            />
                          </div>
                        </div>
                        {((errors.fromDate && touched?.fromDate) ||
                          (errors.toDate && touched?.toDate)) && (
                          <div className='fv-plugins-message-container mx-1'>
                            {errors.fromDate && touched?.fromDate && (
                              <div className='fv-help-block'>{String(errors?.fromDate)}</div>
                            )}
                            {errors?.toDate && touched?.toDate && (
                              <div className='fv-help-block'>{String(errors?.toDate)}</div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-end pt-3 px-4'>
                <button
                  type='reset'
                  className='btn btn-sm btn-light me-2'
                  data-kt-menu-dismiss='true'
                  onClick={() => {
                    resetForm()
                    dispatch(CustomerInvoiceCurrentPage(1))
                    dispatch(CustomerInvoiceRowsPerPage(rowsPerPage))
                    dispatch(CustomerInvoiceFilter({
                      fromDate: '',
                      toDate: moment().format('YYYY-MM-DD'),
                      company: [],
                      status: [],
                    }))
                    fetchCustomerInvoice(
                      {
                        fromDate: '',
                        toDate: moment().format('YYYY-MM-DD'),
                        company: [],
                        status: [],
                      },
                      searchQuery,
                      1,
                      10
                    )
                    setShowDropdownMenu(false)
                    setIsFilterApplied(false)
                  }}
                >
                  Reset
                </button>

                <button
                  type='submit'
                  className='btn btn-sm btn-primary'
                  data-kt-menu-dismiss='true'
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  Apply
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Dropdown.Menu>
    </Dropdown>
  )
}
