/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, useRef} from 'react'
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {
  RequesterManagementSearch,
  RequesterManagementCurrentPage,
  InterperterManagementSearch,
  InterperterManagementCurrentPage,
  AdministratorManagementSearch,
  AdministratorManagementCurrentPage,
  CompanyUserManagementSearch,
  CompanyUserManagementCurrentPage,
} from '../../../../../redux/tableSlice/tableSlice'
import {useDispatch, useSelector} from 'react-redux'

type UsersListSearchComponentProps = {
  userType: string
  customerCode ?: number
}

const UsersListSearchComponent: React.FC<UsersListSearchComponentProps> = ({userType , customerCode}) => {
  const {updateState} = useQueryRequest()
  const inputRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const {rowsPerPage, search} = useSelector((state: any) => {
    if (userType === 'CONSUMER' && customerCode === 0) {
      return {
        rowsPerPage: state.table.RequesterManagement[0]?.rowsPerPage,
        search: state.table.RequesterManagement[0]?.searchQuery,
      }
    } else if (userType === 'INTERPRETER') {
      return {
        rowsPerPage: state.table.InterperterManagement[0]?.rowsPerPage,
        search: state.table.InterperterManagement[0]?.searchQuery,
      }
    } else if (userType === 'SYSTEM') {
      return {
        rowsPerPage: state.table.AdministratorManagement[0]?.rowsPerPage,
        search: state.table.AdministratorManagement[0]?.searchQuery,
      }
    } else {
      return {
        rowsPerPage: state.table.CompanyUserManagement[0]?.rowsPerPage,
        search: state.table.CompanyUserManagement[0]?.searchQuery,
      }
    }
  })
  const [searchTerm, setSearchTerm] = useState<string>(search)

  const handleSearch = () => {
    if (inputRef.current) {
      const newSearchTerm = inputRef.current.value
      updateState({
        search: newSearchTerm,
        items_per_page: rowsPerPage,
        page: 1,
        total: 0,
        to: 0,
        from: 0,
        last_page: 1,
      })
      if (userType === 'CONSUMER' && customerCode === 0) {
        dispatch(RequesterManagementSearch(newSearchTerm))
        dispatch(RequesterManagementCurrentPage(1))
      }
      if (userType === 'INTERPRETER') {
        dispatch(InterperterManagementSearch(newSearchTerm))
        dispatch(InterperterManagementCurrentPage(1))
      }
      if (userType === 'SYSTEM') {
        dispatch(AdministratorManagementSearch(newSearchTerm))
        dispatch(AdministratorManagementCurrentPage(1))
      }
      if (userType === 'CONSUMER' && customerCode !== 0) {
        dispatch(CompanyUserManagementSearch(newSearchTerm))
        dispatch(CompanyUserManagementCurrentPage(1))
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    if (searchTerm === '') {
      updateState({
        search: '',
        items_per_page: rowsPerPage,
        page: 1,
        total: 0,
        to: 0,
        from: 0,
        last_page: 1,
      })
      if (userType === 'CONSUMER' && customerCode === 0) {
        dispatch(RequesterManagementSearch(''))
        dispatch(RequesterManagementCurrentPage(1))
      }
      if (userType === 'INTERPRETER') {
        dispatch(InterperterManagementSearch(''))
        dispatch(InterperterManagementCurrentPage(1))
      }
      if (userType === 'SYSTEM') {
        dispatch(AdministratorManagementSearch(''))
        dispatch(AdministratorManagementCurrentPage(1))
      }
      if (userType === 'CONSUMER' && customerCode !== 0) {
        dispatch(CompanyUserManagementSearch(''))
        dispatch(CompanyUserManagementCurrentPage(1))
      }
    }
  }, [searchTerm])

  useEffect(() => {
    setSearchTerm(search)
  }, [search])

  return (
    <div className='card-title m-0'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative'>
        <input
          ref={inputRef}
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-white form-control-sm w-250px custom-search-radius'
          placeholder={`Search ${userType === 'SYSTEM' ? 'Administrator' : ''}${
            userType === 'INTERPRETER' ? 'Intrepreter' : ''
          }${userType === 'CONSUMER' ? 'Requester' : ''}`}
          onKeyDown={handleKeyDown}
          value={searchTerm}
          onChange={() => setSearchTerm(inputRef.current?.value || '')}
        />
        <button
          type='button'
          className='btn btn-primary btn-sm custom-search-btn-radius px-4 btn-icon'
          onClick={handleSearch}
        >
          <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
        </button>
      </div>
      {/* end::Search */}
    </div>
  )
}

export {UsersListSearchComponent}
