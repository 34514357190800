import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useNavigate, useParams} from 'react-router-dom'
import Select, {SingleValue} from 'react-select'
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap'
import CSVTemplate from '../../../common/componenets/tableColumnTemplate/tableColumnTemplate'
import {Form, Formik} from 'formik'
import toaster from '../../../../Utils/toaster'
import axios from 'axios'
import * as Yup from 'yup'
import {InvoicePdf} from './invoice-pdf/InvoicePdf'
import {PDFViewer, pdf} from '@react-pdf/renderer'
import moment from 'moment'
import {CommonLoading} from '../../../../Utils/commonLoading'
import clsx from 'clsx'
import {useAuth} from '../../../modules/auth'
import {VoucherPdf} from './invoice-pdf/VoucherPdf'
import {Modal} from 'bootstrap'

const API_URL = process.env.REACT_APP_API_URL

const addnoteSchema = Yup.object().shape({
  note: Yup.string().required('Required'),
})

export function InterpreterInvoiceViewer() {
  const MyPDFViewer: any = PDFViewer
  const {id} = useParams()
  const [activities, setActivities] = useState<any>([])
  const [notes, setNotes] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [invoiceDetails, setInvoiceDetails] = useState<any>(null)
  const [deleteCallLineCode, setDeleteCallLineCode] = useState<any>(null)
  const [allStatus, setAllStatus] = useState<any>([])
  const {currentUser} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      fetchInvoiceDetails()
      fetchStatus()
    }
  }, [])

  const fetchInvoiceDetails = async () => {
    try {
      setIsLoading(true)
      let response = await axios.get(`${API_URL}/invoice/voucher/${id}`)
      setInvoiceDetails(response.data.data)
      let response3 = await axios.get(`${API_URL}/invoice/voucher-history/${id}`)
      setActivities(response3.data.data)
      let response5 = await axios.get(`${API_URL}/invoice/voucher-note/${id}`)
      setNotes(response5.data.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const timeZone = [
    {value: 'option 1', label: '(GMT+05:30) Sri Jayawardenepura'},
    {value: 'option 2', label: '(GMT+05:30) Mumbai'},
    {value: 'option 3', label: '(GMT-05:00) Mexico City'},
  ]
  const [activeTab, setActiveTab] = useState(1)
  const [activeColumnTab, setActiveColumnTab] = useState(1)

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber)
  }
  const handleTabColumnClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveColumnTab(tabNumber)
  }

  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  const handleCalllineDelete = async () => {
    try {
      const result: any = await axios.delete(
        `${API_URL}/invoice/voucher-line/${deleteCallLineCode}`
      )
      if (result?.data?.status == 'S') {
        toaster('success', result?.text ?? 'Successfully Deleted')
        try {
          setIsLoading(true)
          let response = await axios.get(`${API_URL}/invoice/voucher/${id}`)
          setInvoiceDetails(response.data.data)
        } catch (ex) {
          console.log(ex)
        } finally {
          setIsLoading(false)
        }
      } else toaster('error', result?.text ?? 'Something went wrong')
    } catch (ex) {
      console.error(ex)
    }
  }

  const fetchStatus = async () => {
    try {
      let response = await axios.get(`${API_URL}/invoice/ddlist-invoice-status`)
      setAllStatus(
        response?.data?.data.map((d: {key: string; value: string; identification: string}) => ({
          value: d.key ?? '',
          label: d.value ?? '',
          identification: d.identification ?? '',
        })) ?? []
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleDownload = async () => {
    const blob = await pdf(<VoucherPdf invoiceDetails={invoiceDetails} />).toBlob()
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${invoiceDetails?.voucherNo}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleStatusChange = async (statusIdentification: string) => {
    try {
      const result: any = await axios.put(`${API_URL}/invoice/voucher-status`, {
        code: id,
        fK_StatusCode:
          allStatus?.find((x: any) => x.identification === statusIdentification)?.value ?? '',
      })
      console.log('result', result)
      if (result?.data?.status == 'S') {
        toaster('success', result?.data?.text ?? 'Successfully Updated')
        try {
          setIsLoading(true)
          let response = await axios.get(`${API_URL}/invoice/voucher/${id}`)
          setInvoiceDetails(response.data.data)
          let response3 = await axios.get(`${API_URL}/invoice/voucher-history/${id}`)
          setActivities(response3.data.data)
        } catch (ex) {
          console.log(ex)
        } finally {
          setIsLoading(false)
          if (statusIdentification === 'DW') handleDownload()
        }
      } else toaster('error', result?.text ?? 'Something went wrong')
    } catch (ex) {
      console.error(ex)
    }
  }

  const handleRegenarate = async () => {
    try {
      setIsLoading(true)
      const result: any = await axios.put(`${API_URL}/invoice/voucher-regenerate/${id}`)
      if (result?.data?.status == 'S') {
        toaster('success', result?.data?.text ?? 'Successfully Regenerated')
        const modal = document.getElementById('kt_regenarate_confirm')
        if (modal) {
          const modalInstance = Modal.getInstance(modal)
          if (modalInstance) modalInstance.hide()
        }
        try {
          await fetchInvoiceDetails()
        } catch (ex) {
          console.log(ex)
        }
      } else {
        toaster('error', result?.text ?? 'Something went wrong')
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog'>
          <Formik
            initialValues={{note: ''}}
            validationSchema={addnoteSchema}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              setSubmitting(true)
              try {
                const result: any = await axios.post(`${API_URL}/invoice/voucher-note`, {
                  fK_VoucherCode: id,
                  note: values?.note,
                })
                if (result?.data?.status == 'S') {
                  setSubmitting(true)
                  resetForm()
                  toaster('success', result?.text ?? 'Successfully Inserted')
                  try {
                    setIsLoading(true)
                    let response5 = await axios.get(`${API_URL}/invoice/voucher-note/${id}`)
                    setNotes(response5.data.data)
                  } catch (ex) {
                    console.log(ex)
                  } finally {
                    setIsLoading(false)
                  }
                } else toaster('error', result?.text ?? 'Something went wrong')
              } catch (ex) {
                console.error(ex)
              }
            }}
          >
            {({
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              values,
              touched,
              isValid,
              dirty,
              isSubmitting,
              handleSubmit,
              resetForm,
              ...formik
            }) => (
              <Form>
                <div className='modal-content'>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Invoice Note</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => resetForm()}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1  fs-7 mb-1'
                          >
                            Write a Note
                          </label>
                          <textarea
                            placeholder='write Note..'
                            name='note'
                            id='note'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.note}
                            className='form-control form-control-sm custom-input-height  custom-input-height  form-select-white mb-lg-0'
                          ></textarea>
                          {errors.note && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.note}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer py-3'>
                    <button
                      type='button'
                      className='btn btn-light btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => resetForm()}
                    >
                      Close
                    </button>
                    <button
                      className='btn btn-primary btn-sm'
                      data-bs-dismiss='modal'
                      disabled={isSubmitting || !isValid || !dirty}
                      onClick={() => handleSubmit()}
                    >
                      Save Note
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_note-invoice'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Notes</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='mb-3'>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Note
                    </label>
                    <textarea
                      placeholder=''
                      name='Note'
                      className='form-control form-select-white mb-lg-0'
                    ></textarea>
                  </div>
                  <div className='form-check form-check-custom form-check-light form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg'
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg'>
                      Show to Customer
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-primary btn-sm'>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_detach_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Confirmation</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center mb-5'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/broken-chain.gif' alt='' />
                </div>
              </div>
              <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                Are you sure you would like to detach this call from the invoice?
              </h4>
            </div>
            <div className='modal-footer py-3'>
              <button
                type='button'
                className='btn btn-light btn-sm'
                data-bs-dismiss='modal'
                onClick={() => setDeleteCallLineCode(null)}
              >
                Close
              </button>

              <button
                type='button'
                className='btn btn-danger btn-sm'
                data-bs-dismiss='modal'
                onClick={() => handleCalllineDelete()}
              >
                Detach
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_regenarate_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Confirmation</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center mb-5'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/loading.gif' alt='' />
                </div>
              </div>
              <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                Are you sure you would like to regenarate rates from the invoice?
              </h4>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button
                type='button'
                className='btn btn-success btn-sm'
                onClick={() => handleRegenarate()}
              >
                Regenarate
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_void_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Confirmation</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center mb-5'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/warning.gif' alt='' />
                </div>
              </div>
              <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                Are you sure you would like to void this invoice? This will detach all appointment
                and calls currently part of this invoice
              </h4>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button
                type='button'
                className='btn btn-danger btn-sm'
                data-bs-dismiss='modal'
                onClick={() => handleStatusChange('VO')}
              >
                Void
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_pdf_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Download</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {/* <div className='text-center'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/download.gif' alt='' />
                </div>
              </div>
              <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                Save as PDF
              </h4> */}
              <div style={{height: '60rem'}}>
                <MyPDFViewer width='100%' height='100%' style={{border: 'none'}}>
                  <InvoicePdf />
                </MyPDFViewer>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-success btn-sm' data-bs-dismiss='modal'>
                PDF Download
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_CSV_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Download</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/download.gif' alt='' />
                </div>
              </div>
              <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                Save as CSV
              </h4>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-success btn-sm' data-bs-dismiss='modal'>
                CSV Download
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_CSV_appointment'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Download</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/info.gif' alt='' />
                </div>
              </div>
              <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                This document does not contain any records and can’t be downloaded.
              </h4>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-primary btn-sm' data-bs-dismiss='modal'>
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_select-column'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'> Select Column</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='card mb-5'>
                    <div
                      className='card-header py-0 px-0'
                      style={{minWidth: '300px', overflowX: 'auto', minHeight: '40px'}}
                    >
                      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
                        <li className='nav-item'>
                          <a
                            className={`nav-link fs-7 text-gray-400   ${
                              activeColumnTab === 1 ? 'text-active-dark fw-bold active show' : ''
                            }`}
                            data-bs-toggle='tab'
                            href='#kt_tab_column_1'
                            onClick={() => handleTabColumnClick(1)}
                          >
                            System
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className={`nav-link fs-7 text-gray-400 ${
                              activeColumnTab === 2 ? 'text-active-dark fw-bold active show' : ''
                            }`}
                            data-bs-toggle='tab'
                            href='#kt_tab_column_2'
                            onClick={() => handleTabColumnClick(2)}
                          >
                            Pre-Call Custom Fields
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className={`nav-link fs-7 text-gray-400 ${
                              activeColumnTab === 3 ? 'text-active-dark fw-bold active show' : ''
                            }`}
                            data-bs-toggle='tab'
                            href='#kt_tab_column_3'
                            onClick={() => handleTabColumnClick(3)}
                          >
                            Interpreter Profile Custom Fields
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className={`nav-link fs-7 text-gray-400 ${
                              activeColumnTab === 4 ? 'text-active-dark fw-bold active show' : ''
                            }`}
                            data-bs-toggle='tab'
                            href='#kt_tab_column_4'
                            onClick={() => handleTabColumnClick(4)}
                          >
                            Requestor Profile Custom Fields
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className={`nav-link fs-7 text-gray-400 ${
                              activeColumnTab === 5 ? 'text-active-dark fw-bold active show' : ''
                            }`}
                            data-bs-toggle='tab'
                            href='#kt_tab_column_5'
                            onClick={() => handleTabColumnClick(5)}
                          >
                            Account Profile Custom Fields
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className={`nav-link fs-7 text-gray-400 ${
                              activeColumnTab === 6 ? 'text-active-dark fw-bold active show' : ''
                            }`}
                            data-bs-toggle='tab'
                            href='#kt_tab_column_6'
                            onClick={() => handleTabColumnClick(6)}
                          >
                            Saved Templates
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='card-body pt-0 px-0'>
                      <div className=''>
                        <div className='tab-content' id='myTabContent'>
                          {activeColumnTab === 1 && (
                            <div
                              className='tab-pane fade show active'
                              id='kt_tab_column_1'
                              role='tabpanel'
                            >
                              <div className='row g-4 py-2'>
                                <div className='col-md-12'>
                                  <div className='d-flex align-items-center position-relative flex-nowrap mt-3'>
                                    <input
                                      type='text'
                                      data-kt-user-table-filter='search'
                                      className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                      placeholder='Search'
                                    />
                                    <button
                                      type='button'
                                      className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen021.svg'
                                        className=''
                                      />
                                    </button>
                                  </div>
                                </div>
                                <div
                                  className='row g-4 py-2'
                                  style={{maxHeight: '300px', overflowY: 'auto'}}
                                >
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg0'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg0'>
                                        Call Id
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg1'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg1'>
                                        Interpreter
                                      </label>
                                    </div>
                                  </div>

                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg2'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg2'>
                                        Interpreter ID
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg3'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg3'>
                                        Requestor
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg4'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg4'>
                                        Account Name
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg5'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg5'>
                                        Date/Time
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg6'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg6'>
                                        Language 1
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg7'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg7'>
                                        Language 2
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg8'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg8'>
                                        Type
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg9'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg9'>
                                        Duration
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg10'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg10'>
                                        Client Billable Duration
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg11'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg11'>
                                        Interpreter Billable Duration
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg12'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg12'>
                                        Finished By
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg13'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg13'>
                                        Requestor Rating
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg14'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg14'>
                                        Requestor Call Quality Rating
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg15'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg15'>
                                        Interpreter Peer Rating
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg16'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg16'>
                                        Interpreter Call Quality Rating
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg17'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg17'>
                                        Account Charge
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg18'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg18'>
                                        Account Minimum
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg19'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg19'>
                                        Interpreter Minimum
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg20'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg20'>
                                        Account Rounding
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg21'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg21'>
                                        Interpreter Rounding
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg22'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg22'>
                                        Account Thresholds
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg23'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg23'>
                                        Interpreter Thresholds
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg24'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg24'>
                                        Account Special Rate Amount
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg25'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg25'>
                                        Interpreter Special Rate Amount
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg26'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg26'>
                                        Account Conference Charge
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg27'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg27'>
                                        Interpreter Conference Pay
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg28'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg28'>
                                        Account Invoice ID
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg29'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg29'>
                                        Account Invoice Status
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg30'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg30'>
                                        Interpreter Invoice ID
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg31'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg31'>
                                        Interpreter Peer Rating
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg32'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg32'>
                                        Queue Time (s)
                                      </label>
                                    </div>
                                  </div>

                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg33'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg33'>
                                        Call Center Queue/Hold Time (s)
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg34'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg34'>
                                        Gender Requested
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg35'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg35'>
                                        Client Device Type
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg36'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg36'>
                                        From Number
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg37'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg37'>
                                        Requestor Device Info
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg38'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg38'>
                                        Other Participants
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg39'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg39'>
                                        Conference Duration
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg40'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg40'>
                                        Operator Name
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg41'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg41'>
                                        Operator Duration
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg42'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg42'>
                                        Intake Duration
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg43'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg43'>
                                        Toll-Free Dialed
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg44'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg44'>
                                        Total Interpreter Pay
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg45'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg45'>
                                        Crowd
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg46'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg46'>
                                        Account ID
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg47'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg47'>
                                        Pending Duration Updates
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg48'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg48'>
                                        Duration Adjusted to 0
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg49'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg49'>
                                        Call Status
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg50'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg50'>
                                        Call Status (Operator)
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg51'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg51'>
                                        Client Company UniqueId
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg52'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg52'>
                                        PRequestor Pin
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {activeColumnTab === 2 && (
                            <div
                              className='tab-pane fade show active'
                              id='kt_tab_column_2'
                              role='tabpanel'
                            >
                              <div className='row g-4 py-2'>
                                <div className='col-md-12'>
                                  <div className='row g-4 py-2'>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center position-relative flex-nowrap mt-3'>
                                        <input
                                          type='text'
                                          data-kt-user-table-filter='search'
                                          className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                          placeholder='Search'
                                        />
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen021.svg'
                                            className=''
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      className='row g-4 py-2'
                                      style={{maxHeight: '300px', overflowY: 'auto'}}
                                    >
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg0'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg0'
                                          >
                                            Name of Requestor
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg1'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg1'
                                          >
                                            Department
                                          </label>
                                        </div>
                                      </div>

                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg2'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg2'
                                          >
                                            Call Center
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg3'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg3'
                                          >
                                            School Name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg4'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg4'
                                          >
                                            Division
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg5'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg5'
                                          >
                                            Service Center
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg6'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg6'
                                          >
                                            Client name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg7'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg7'
                                          >
                                            Maryland Auto
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg8'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg8'
                                          >
                                            Department or Service Area
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg9'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg9'
                                          >
                                            Email Address
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg10'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg10'
                                          >
                                            DE CDW
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg11'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg11'
                                          >
                                            Using the key pad, please enter the last five digits of
                                            your Endeavors phone number
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg12'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg12'
                                          >
                                            Caller's Name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg13'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg13'
                                          >
                                            Employee Identification Number
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg14'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg14'
                                          >
                                            Please clearly state your department
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg15'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg15'
                                          >
                                            Officer''s Name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg16'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg16'
                                          >
                                            Form Type
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg17'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg17'
                                          >
                                            Requester's first and last name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg18'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg18'
                                          >
                                            Region Number
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg19'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg19'
                                          >
                                            Medical Record Number
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg20'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg20'
                                          >
                                            Patient's Name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg21'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg21'
                                          >
                                            State Dept - Assigned Attorney
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg22'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg22'
                                          >
                                            State Dept Case ID
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg23'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg23'
                                          >
                                            Adventist Medical Group
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg24'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg24'
                                          >
                                            Clinic or department
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg25'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg25'
                                          >
                                            Location Code
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg26'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg26'
                                          >
                                            Program Code
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg27'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg27'
                                          >
                                            Are you on site with the participant? 1=yes, 2=no
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg28'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg28'
                                          >
                                            Onsite with participant?
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg29'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg29'
                                          >
                                            CBP - Are you a member of US Border patrol
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg30'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg30'
                                          >
                                            Claim or Policy Number
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg31'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg31'
                                          >
                                            Name of Adjuster
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg32'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg32'
                                          >
                                            Claim number as requested by Florida Peninsula Insurance
                                            compliance department
                                          </label>
                                        </div>
                                      </div>

                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg33'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg33'
                                          >
                                            Requester store name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg34'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg34'
                                          >
                                            Employee's name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg35'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg35'
                                          >
                                            Provide the Claim ID, using no spaces or dashes
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg36'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg36'
                                          >
                                            Two-digit local agency number
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg37'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg37'
                                          >
                                            Three digit clinic number
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {activeColumnTab === 3 && (
                            <div
                              className='tab-pane fade show active'
                              id='kt_tab_column_3'
                              role='tabpanel'
                            >
                              <div className='row g-4 py-2'>
                                <div className='col-md-12'>
                                  <div className='row g-4 py-2'>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center position-relative flex-nowrap mt-3'>
                                        <input
                                          type='text'
                                          data-kt-user-table-filter='search'
                                          className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                          placeholder='Search'
                                        />
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen021.svg'
                                            className=''
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      className='row g-4 py-2'
                                      style={{maxHeight: '300px', overflowY: 'auto'}}
                                    >
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg0'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg0'
                                          >
                                            Additional Expiration
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {activeColumnTab === 4 && (
                            <div
                              className='tab-pane fade show active'
                              id='kt_tab_column_4'
                              role='tabpanel'
                            >
                              <div className='row g-4 py-2'>
                                <div className='col-md-12'>
                                  <div className='row g-4 py-2'>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center position-relative flex-nowrap mt-3'>
                                        <input
                                          type='text'
                                          data-kt-user-table-filter='search'
                                          className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                          placeholder='Search'
                                        />
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen021.svg'
                                            className=''
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      className='row g-4 py-2'
                                      style={{maxHeight: '300px', overflowY: 'auto'}}
                                    >
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg0'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg0'
                                          >
                                            Department Name
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {activeColumnTab === 5 && (
                            <div
                              className='tab-pane fade show active'
                              id='kt_tab_column_5'
                              role='tabpanel'
                            >
                              <div className='row g-4 py-2'>
                                <div className='col-md-12'>
                                  <div className='row g-4 py-2'>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center position-relative flex-nowrap mt-3'>
                                        <input
                                          type='text'
                                          data-kt-user-table-filter='search'
                                          className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                          placeholder='Search'
                                        />
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen021.svg'
                                            className=''
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      className='row g-4 py-2'
                                      style={{maxHeight: '300px', overflowY: 'auto'}}
                                    >
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <p>No Custome Field</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {activeColumnTab === 6 && (
                            <div
                              className='tab-pane fade show active'
                              id='kt_tab_column_6'
                              role='tabpanel'
                            >
                              <div className='row g-4 py-2'>
                                <div className='col-md-12'>
                                  <div className='row g-4 py-2'>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center position-relative flex-nowrap mt-3'>
                                        <input
                                          type='text'
                                          data-kt-user-table-filter='search'
                                          className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                          placeholder='Search'
                                        />
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen021.svg'
                                            className=''
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      className='row g-4 py-2'
                                      style={{maxHeight: '300px', overflowY: 'auto'}}
                                    >
                                      <div className='col-sm-12 col-md-12 col-lg-12'>
                                        <CSVTemplate />
                                        <CSVTemplate />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {activeColumnTab !== 6 && (
                        <div className='row '>
                          <div className='separator my-2'></div>
                          <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
                            <div className='mb-2'>
                              <div
                                className='accordion-header py-3 d-flex'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_2_item_1'
                              >
                                <span className='accordion-icon'>
                                  <KTSVG
                                    className='svg-icon svg-icon-4'
                                    path='media/icons/duotune/arrows/arr064.svg'
                                  />
                                </span>
                                <h3 className='fs-7 text-gray-800 fw-semibold mb-0 ms-4'>
                                  Advanced
                                </h3>
                              </div>
                              <div
                                id='kt_accordion_2_item_1'
                                className='fs-6 collapse ps-8'
                                data-bs-parent='#kt_accordion_2'
                              >
                                <div className='mb-3'>
                                  <div className='row d-flex mb-3'>
                                    <div className='col-md-12'>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='form-label fs-7 mb-1'
                                      >
                                        Date/time fields will be displayed in your local time zone.
                                        You can change this in the drop-down below
                                      </label>
                                      <Select
                                        className='react-select-styled react-select-solid react-select-sm'
                                        classNamePrefix='react-select'
                                        options={timeZone}
                                        defaultValue={timeZone[0]}
                                        placeholder='Select'
                                        styles={{
                                          control: (provided: any) => ({
                                            ...provided,
                                            width: '100%',
                                            border: '1px solid #e4e6ef',
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <div className='d-flex justify-content-between align-items-center w-100 flex-wrap'>
                <div className='d-flex'>
                  {activeColumnTab !== 6 && (
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='flexRadioLg111'
                      />
                      <label className='form-check-label' htmlFor='flexRadioLg111'>
                        Save selected columns as CSV template
                      </label>
                    </div>
                  )}
                </div>
                <div className='d-flex'>
                  <button
                    type='button'
                    className='btn btn-light btn-sm me-3'
                    data-bs-dismiss='modal'
                  >
                    Close
                  </button>

                  <button type='button' className='btn btn-success btn-sm'>
                    Download CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mb-3'>
        <div className='d-flex align-items-center '>
          <button
            onClick={() => navigate(-1)}
            className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'
          >
            <i className='bi bi-arrow-left-circle' style={{fontSize: '18px'}}></i>
          </button>
          <div>
            <h1 className='mb-0 fw-semibold fs-2'>Invoice Viewer</h1>
            <span className='text-gray-500'>Invoice history check and Print</span>
          </div>
        </div>
      </div>

      {invoiceDetails?.voucherLineList?.find((x: any) => x.total === 0) &&
        invoiceDetails?.statusIdentification === 'DR' && (
          <div className='d-flex justify-content-between align-items-center  px-3 py-2 mb-2 rounded shadow-sm bg-white'>
            <div>
              <span className='text-danger fs-7 fw-semibold'>
                You unable to approve the draft invoice because there are missing rates for the
                highlighted calls.Please update the rates and click "Regenerate."
              </span>
            </div>
            <div>
              <OverlayTrigger
                placement='bottom'
                overlay={<Tooltip id='tooltip-filter'>Regenerate Rates</Tooltip>}
              >
                <div className='d-flex align-items-center mb-2 me-3'>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary btn-icon px-4'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_regenarate_confirm'
                  >
                    {/* <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-muted' /> */}
                    <i className='bi bi-arrow-repeat'></i>
                  </button>
                </div>
              </OverlayTrigger>
            </div>
          </div>
        )}

      {/* <div className='d-flex justify-content-between align-items-center  px-3 py-2 mb-2 rounded shadow-sm bg-success text-white'>
        <span className='fs-8 d-flex align-items-center justify-content-center fw-semibold'>
          Sent To QuickBooks
        </span>
        <div>
          <span className='text-white fs-8 fw-semibold'>Invoice has been added to QuickBooks.</span>
        </div>
      </div> */}
      <div className='card mb-5 mb-xl-5 shadow-sm' style={{borderTop: '3px solid #a3a242'}}>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border border-primary border-3 rounded-circle'>
                {invoiceDetails?.avatar !== undefined &&
                invoiceDetails?.avatar !== null &&
                invoiceDetails?.avatar !== '' ? (
                  <>
                    <>
                      <img
                        src={process.env.REACT_APP_IMG_URL + '/profile/' + invoiceDetails?.avatar}
                        alt='user'
                        className='rounded-circle'
                        style={{objectFit: 'cover'}}
                      />
                    </>
                    {/* )} */}
                  </>
                ) : (
                  <>
                    <img
                      src={toAbsoluteUrl('../media/avatars/blank.png')}
                      alt='user'
                      className='rounded-circle '
                      style={{objectFit: 'cover'}}
                    />
                  </>
                )}
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-3'>
                      {invoiceDetails?.interpreter ?? 'Loading...'}
                    </a>
                    {invoiceDetails?.statusIdentification === 'DR' && (
                      <span className='badge badge-dark px-3 py-2 fs-9 d-flex align-items-center justify-content-center text-white-800'>
                        {invoiceDetails?.voucherStatus ?? '-'}
                      </span>
                    )}
                    {invoiceDetails?.statusIdentification === 'VO' && (
                      <span className='badge badge-danger px-3 py-2 fs-9 d-flex align-items-center justify-content-center text-white-800'>
                        {invoiceDetails?.voucherStatus ?? '-'}
                      </span>
                    )}
                    {invoiceDetails?.statusIdentification === 'PD' && (
                      <span className='badge badge-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center text-white-800'>
                        {invoiceDetails?.voucherStatus ?? '-'}
                      </span>
                    )}
                    {invoiceDetails?.statusIdentification === 'DW' && (
                      <span className='badge badge-warning px-3 py-2 fs-9 d-flex align-items-center justify-content-center text-white-800'>
                        {invoiceDetails?.voucherStatus ?? '-'}
                      </span>
                    )}
                    {invoiceDetails?.statusIdentification === 'AP' && (
                      <span className='badge badge-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center text-white-800'>
                        {invoiceDetails?.voucherStatus ?? '-'}
                      </span>
                    )}
                  </div>

                  <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2 '>
                    <a className='d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2'>
                      <i className='bi bi-receipt me-2'></i>
                      {invoiceDetails?.voucherNo ?? 'Loading...'}
                    </a>
                  </div>
                </div>
                <div className='d-flex '>
                  {/* <div className='d-flex flex-wrap'>
                    <OverlayTrigger
                      placement='top'
                      overlay={<Tooltip id='tooltip-filter'>Send to Quick Books</Tooltip>}
                    >
                      <div>
                        <button type='button' className='btn btn-sm btn-success btn-icon me-3'>
                          qb
                        </button>
                      </div>
                    </OverlayTrigger>
                  </div> */}
                  {invoiceDetails?.statusIdentification !== 'VO' && (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={<Tooltip id='tooltip-filter'>Add Note</Tooltip>}
                    >
                      <div className='d-flex align-items-center mb-2 me-3'>
                        <button
                          type='button'
                          className='btn btn-sm btn-primary btn-icon px-4'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_2'
                          data-bs-backdrop='static'
                          data-bs-keyboard='false'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen005.svg'
                            className='svg-icon-muted'
                          />
                        </button>
                      </div>
                    </OverlayTrigger>
                  )}
                  {(invoiceDetails?.statusIdentification === 'DR' ||
                    invoiceDetails?.statusIdentification === 'DW' ||
                    invoiceDetails?.statusIdentification === 'AP') &&
                    currentUser?.result.userType == 'SYSTEM' && (
                      <div className='d-flex flex-wrap'>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='primary'
                            id='dropdown-basic'
                            className='btn btn-sm btn-primary btn-icon me-3 no-caret'
                          >
                            <i className='bi bi-three-dots fs-4'></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className='dropdown-menu-left-1'>
                            {invoiceDetails?.statusIdentification === 'DR' && (
                              <Dropdown.Item
                                data-bs-toggle='modal'
                                data-bs-target='#kt_void_confirm'
                              >
                                <a href='#' className='text-gray-800 text-hover-primary'>
                                  Void
                                </a>
                              </Dropdown.Item>
                            )}
                            {invoiceDetails?.statusIdentification === 'DR' && (
                              <Dropdown.Item onClick={() => handleStatusChange('AP')}>
                                Approve
                              </Dropdown.Item>
                            )}
                            {invoiceDetails?.statusIdentification === 'AP' && (
                              <Dropdown.Item onClick={() => handleStatusChange('DW')}>
                                Download
                              </Dropdown.Item>
                            )}
                            {invoiceDetails?.statusIdentification === 'DW' && (
                              <Dropdown.Item onClick={() => handleStatusChange('PD')}>
                                Mark Paid
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  {(invoiceDetails?.statusIdentification === 'DW' ||
                    invoiceDetails?.statusIdentification === 'PD') && (
                    <div className='d-flex flex-wrap'>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='primary'
                          id='dropdown-basic'
                          className='btn btn-sm btn-primary btn-icon me-3 no-caret'
                        >
                          <i className='bi bi-download fs-4'></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='dropdown-menu-left'>
                          {/* <Dropdown.Item>Print</Dropdown.Item> */}
                          {/* <Dropdown.Item data-bs-toggle='modal' data-bs-target='#kt_pdf_confirm'>
                          <a href='#' className='text-gray-800 text-hover-primary'>
                            Download to PDF
                          </a>
                        </Dropdown.Item> */}
                          <Dropdown.Item onClick={() => handleDownload()}>
                            <a href='#' className='text-gray-800 text-hover-primary'>
                              Download to PDF
                            </a>
                          </Dropdown.Item>
                          {/* <Dropdown.Item data-bs-toggle='modal' data-bs-target='#kt_CSV_confirm'>
                          <a href='#' className='text-gray-800 text-hover-primary'>
                            Download All Columns to CSV Calls
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item data-bs-toggle='modal' data-bs-target='#kt_select-column'>
                          <a href='#' className='text-gray-800 text-hover-primary'>
                            Download Select Columns to CSV Calls
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item data-bs-toggle='modal' data-bs-target='#kt_CSV_appointment'>
                          <a href='#' className='text-gray-800 text-hover-primary'>
                            Download All Columns to CSV Appointments
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item data-bs-toggle='modal' data-bs-target='#kt_CSV_appointment'>
                          <a href='#' className='text-gray-800 text-hover-primary'>
                            Download Select Columns to CSV Appointments
                          </a>
                        </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                  {/* <div className='d-flex flex-wrap'>
                    <OverlayTrigger
                      placement='top'
                      overlay={<Tooltip id='tooltip-filter'>Download</Tooltip>}
                    >
                      <div>
                        <button type='button' className='btn btn-sm btn-primary btn-icon'>
                          <i className='bi bi-download fs-4'></i>
                        </button>
                      </div>
                    </OverlayTrigger>
                  </div> */}
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-4'>
                  <div className='d-flex flex-wrap'>
                    <div className='card-body p-0'>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Invoice Date </label>
                        <div className='col-lg-8 fv-row'>
                          <span className='fs-6 text-gray-900'>
                            : {moment(invoiceDetails?.voucherDate).format('YYYY-MM-DD')}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Due Date</label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span className=' fs-6 me-2'>
                            : {moment(invoiceDetails?.dueDate).format('YYYY-MM-DD')}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Time Zone</label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span className=' fs-6 me-2'>: {invoiceDetails?.timeZone ?? 'N/A'}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column flex-grow-1 pe-4'>
                  <div className='d-flex flex-wrap'>
                    <div className='card-body p-0'>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Date Range</label>
                        <div className='col-lg-8 fv-row'>
                          <span className='fs-6 text-gray-900'>
                            : {moment(invoiceDetails?.fromDate).format('YYYY-MM-DD')} to{' '}
                            {moment(invoiceDetails?.toDate).format('YYYY-MM-DD')}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Created Date</label>
                        <div className='col-lg-8 fv-row'>
                          <span className='fs-6 text-gray-900'>
                            : {moment(invoiceDetails?.createDate).format('YYYY-MM-DD')}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Call | Appt Count</label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span className=' fs-6 me-2'>: {invoiceDetails?.callCount ?? 'N/A'} <span className='fw-bold'>|</span> {invoiceDetails?.appoinmentCount ?? 'N/A'}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5'>
        <div
          className='card-header py-0'
          style={{minWidth: '300px', overflowX: 'auto', minHeight: '40px'}}
        >
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400   ${
                  activeTab === 1 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_1'
                onClick={() => handleTabClick(1)}
              >
                Invoice
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab === 2 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_2'
                onClick={() => handleTabClick(2)}
              >
                History/Notes
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab === 3 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_3'
                onClick={() => handleTabClick(3)}
              >
                Print View
              </a>
            </li>
          </ul>
        </div>
        <div className='card-body pt-0'>
          <div className=''>
            <div className='tab-content' id='myTabContent'>
              {activeTab === 1 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                  <div className='mt-5'>
                    <span className='badge badge-warning'>Taxes Not Included</span>
                  </div>
                  <div className='mt-2 px-2 py-2 bg-light border border-gray-300 rounded'>
                    <span className='text-dark fw-bold'>
                      Line Count: {invoiceDetails?.voucherLineList?.length ?? 0}
                    </span>
                  </div>
                  <div className='py-0 pt-3 mb-xl-5 mt-3'>
                    {/* <h4 className='mb-3'>Overview</h4> */}
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bold text-muted text-uppercase'>
                            <th className='min-w-150px '>Call</th>
                            <th className='min-w-100px '>appointment Id</th>
                            <th className='min-w-100px '>Interpreter</th>
                            <th className='min-w-100px '>Type</th>
                            <th className='min-w-100px '>Date/Time</th>
                            <th className='min-w-100px '>Duration</th>
                            <th className='min-w-100px '>Rate</th>
                            <th className='min-w-100px '>Extras</th>
                            <th className='min-w-100px '>Total</th>
                            {invoiceDetails?.statusIdentification === 'DR' &&
                              currentUser?.result?.userType === 'SYSTEM' && (
                                <th className='min-w-100px text-end'>Action</th>
                              )}
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {invoiceDetails?.voucherLineList?.map((item: any, index: any) => (
                            <tr
                              key={index}
                              style={item?.total === 0 ? {backgroundColor: '#ffd1d7'} : {}}
                            >
                              <td>
                                <div className='d-flex flex-column'>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>
                                    {item?.contactId ?? 'N/A'}
                                  </a>
                                  <span className='text-gray-500 fs-9'>
                                    {item?.fromLanguage ?? 'N/A'} to {item?.toLanguage ?? 'N/A'}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <a className='text-gray-800 text-hover-primary  fs-7'>
                                  {item?.appointmentId ?? 'N/A'}
                                </a>
                              </td>
                              <td>
                                <a className='text-gray-800 text-hover-primary  fs-7'>
                                  {item?.interpreter ?? 'N/A'}
                                </a>
                              </td>
                              <td>
                                <a className='text-gray-800 text-hover-primary  fs-7'>
                                  {item?.callType ?? 'N/A'}
                                </a>
                              </td>
                              <td>
                                <a className='text-gray-800 text-hover-primary  fs-7'>
                                  {moment(item?.callTime).format('YYYY-MM-DD hh:mm A')}
                                </a>
                              </td>
                              <td>
                                <a className='text-gray-800 text-hover-primary  fs-7'>
                                  {item?.duration} minutes
                                </a>
                              </td>
                              <td>
                                <a className='text-gray-800 text-hover-primary  fs-7'>
                                  ${item?.amount}
                                </a>
                              </td>
                              <td>
                                <a className='text-gray-800 text-hover-primary  fs-7'>
                                  ${item?.extra}
                                </a>
                              </td>
                              <td>
                                <a className='text-gray-800 text-hover-primary  fs-7'>
                                  ${item?.total}
                                </a>
                              </td>

                              {invoiceDetails?.statusIdentification === 'DR' &&
                                currentUser?.result?.userType === 'SYSTEM' && (
                                  <td>
                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                      <a
                                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_detach_confirm'
                                        onClick={() => setDeleteCallLineCode(item?.code)}
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/coding/cod008.svg'
                                          className='svg-icon-muted '
                                        />
                                      </a>
                                    </div>
                                  </td>
                                )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <div className='separator my-5'></div>
                  <div className='mt-5'>
                    <span className='badge badge-warning'>Taxes Not Included</span>
                  </div>
                  <div className='d-flex justify-content-between align-items-center mt-2'>
                    <div className='d-flex flex-grow-1  px-2 py-2 bg-light border border-gray-300 rounded'>
                      <span className='text-dark fw-bold'>Additional Items: $0.00</span>
                    </div>
                  </div> */}
                </div>
              )}
              {activeTab === 2 && (
                // <div className='tab-pane fade show active' id='kt_tab_pane_2' role='tabpanel'>
                //   <div className='row g-4'>
                //     <div className='col-md-6 col-sm-12 pt-5 '>
                //       <h3 className='fs-6 fw-normal text-gray-600 mb-5'>Invoice History</h3>
                //       <div className='card mb-2 shadow-sm'>
                //         <div className='rounded-sm'>
                //           <div className='card-body py-2'>
                //             <div className='w-100'>
                //               <div className='d-flex justify-content-between flex-nowrap w-100'>
                //                 <div className='d-flex align-items-center '>
                //                   <div className='symbol-group symbol-hover me-2'>
                //                     <div className='symbol symbol-circle symbol-50px'>
                //                       <img
                //                         src={toAbsoluteUrl('../media/logos/Untitled-1.png')}
                //                         alt=''
                //                       />
                //                     </div>
                //                   </div>

                //                   <div className='d-flex flex-column flex-1 w-auto'>
                //                     <span className='fs-7'>Invoice created.</span>
                //                   </div>
                //                 </div>
                //                 <div className='text-gray-600 fw-semibold d-flex fs-8'>Admin </div>
                //               </div>
                //             </div>
                //           </div>
                //           <div className='py-2 card-footer'>
                //             <div className='row g-1'>
                //               <div className='col-sm-12 col-md-12 col-lg-12 text-end text-gray-400 fs-8'>
                //                 04/01/24 10:35 pm
                //               </div>
                //             </div>
                //           </div>
                //         </div>
                //       </div>
                //     </div>
                //     <div className='col-md-6 col-sm-12 pt-5'>
                //       <h3 className='fs-6 fw-normal text-gray-600 mb-5'>Notes</h3>
                //       <div className='card mb-2 shadow-sm'>
                //         <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                //           <div className='text-center'>
                //             <div className='symbol symbol-200px '>
                //               <img src='/media/other/nodata.png' alt='' />
                //             </div>
                //           </div>
                //           <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                //             No matching records found
                //           </div>
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // </div>
                <div className='tab-pane fade show active' id='kt_tab_pane_2' role='tabpanel'>
                  <div className='row g-4'>
                    <div className='col-md-6 col-sm-12 pt-5 '>
                      <h3 className='fs-6 fw-semibold text-gray-800 mb-5'>Invoice History</h3>
                      <div className='separator my-3'></div>
                      <div
                        className='scrollable-notes'
                        style={{maxHeight: '50vh', overflowY: 'auto'}}
                      >
                        {activities?.length > 0 ? (
                          activities?.map((item: any, index: number) => (
                            <div
                              className='card bg-white mb-2 border border-dotted border-gray-400 border-1 mx-2'
                              key={index}
                            >
                              <div className='card-body p-4'>
                                <div className='d-flex justify-content-between flex-nowrap w-100'>
                                  <div className='d-flex align-items-center w-100'>
                                    <div className='symbol-group symbol-hover me-3'>
                                      {item.profileImages !== '' ? (
                                        item.profileImages !== '' ? (
                                          <div className='symbol symbol-50px symbol-circle d-flex align-items-start'>
                                            <img
                                              src={
                                                process.env.REACT_APP_IMG_URL +
                                                '/profile/' +
                                                item.profileImages
                                              }
                                              alt=''
                                              className=''
                                              style={{objectFit: 'cover'}}
                                            />
                                          </div>
                                        ) : (
                                          <div className='symbol symbol-50px symbol-circle overflow-hidden '>
                                            <div
                                              className={clsx(
                                                'symbol-label fs-3',
                                                'badge-light-success',
                                                `bg-light-success`
                                              )}
                                            >
                                              <span style={{fontSize: '20x'}}>
                                                {(item?.userName ?? '').charAt(0).toUpperCase() +
                                                  (item?.userName ?? '').charAt(1).toUpperCase()}
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      ) : (
                                        <div className='symbol symbol-100px symbol-circle overflow-hidden'>
                                          <div
                                            className={clsx(
                                              'symbol-label fs-3',
                                              'badge-light-success',
                                              `bg-light-success`
                                            )}
                                          >
                                            <span style={{fontSize: '20x'}}>
                                              {(item?.userName ?? '').charAt(0).toUpperCase() +
                                                (item?.userName ?? '').charAt(1).toUpperCase()}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className='d-flex flex-column w-100'>
                                      <div className='d-flex justify-content-between w-100 flex-wrap'>
                                        <div className='d-flex flex-wrap'>
                                          <span className='fw-bold d-flex align-items-center fs-6 me-1 me-3'>
                                            {item?.name}
                                          </span>
                                          <div className='d-flex'>
                                            <span className='badge  badge-secondary px-3 fs-8'>
                                              {item?.userName ?? 'N/A'}
                                            </span>
                                          </div>
                                        </div>

                                        <div className='d-flex text-gray-600'>
                                          <span className='fs-8'>
                                            <i className='bi bi-calendar-check me-2'></i>
                                            {moment(item.insertedDateTime).format(
                                              'MM/DD/YY hh:mm A'
                                            )}
                                          </span>
                                        </div>
                                      </div>

                                      <div className='d-flex flex-column flex-1 mt-1'>
                                        <span className='fs-7'>{item?.description ?? 'N/A'}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className='card bg-white mb-2 border border-dotted border-gray-400 border-1 mx-2'>
                            <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                              <div className='text-center'>
                                <div className='symbol symbol-200px '>
                                  <img src='/media/other/nodata.png' alt='' />
                                </div>
                              </div>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                No matching records found
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 pt-5'>
                      <h3 className='fs-6 fw-semibold text-gray-800 mb-5'>Notes</h3>
                      <div className='separator my-3'></div>
                      <div
                        className='scrollable-notes'
                        style={{maxHeight: '50vh', overflowY: 'auto'}}
                      >
                        {notes?.length > 0 ? (
                          notes?.map((item: any) => (
                            <div className='card bg-white mb-2 border border-dotted border-gray-400 border-1 mx-2'>
                              <div className='card-body p-4'>
                                <div className='d-flex justify-content-between flex-nowrap w-100'>
                                  <div className='d-flex align-items-center w-100'>
                                    <div className='symbol-group symbol-hover me-3'>
                                      {item.avatar ? (
                                        <div className='symbol symbol-circle symbol-50px'>
                                          <img
                                            src={
                                              process.env.REACT_APP_IMG_URL +
                                              '/profile/' +
                                              item.avatar
                                            }
                                            alt=''
                                            className='rounded-circle'
                                            style={{objectFit: 'cover'}}
                                          />
                                        </div>
                                      ) : (
                                        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                          <div
                                            className={clsx(
                                              'symbol-label fs-3',
                                              'badge-light-success',
                                              `bg-light-success`
                                            )}
                                          >
                                            <span style={{fontSize: '20px'}}>
                                              {(item?.insertedBy ?? '').charAt(0).toUpperCase() +
                                                (item?.insertedBy ?? '').charAt(1).toUpperCase()}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className='d-flex flex-column w-100'>
                                      <div className='d-flex justify-content-between w-100 flex-wrap'>
                                        <div className='d-flex flex-wrap'>
                                          {/* <span className='fw-bold d-flex align-items-center fs-6 me-1 me-3'>
                                            {item?.noteHeader}
                                          </span> */}
                                          <div className='d-flex'>
                                            <span className='badge  badge-secondary px-3 fs-8'>
                                              {item?.insertedByName ?? 'N/A'}
                                            </span>
                                          </div>
                                        </div>

                                        <div className='d-flex text-gray-600'>
                                          <span className='fs-8'>
                                            <i className='bi bi-calendar-check me-2'></i>
                                            {moment(item.insertedDateTime)
                                              .local()
                                              .format('MM/DD/YY hh:mm A')}
                                          </span>
                                        </div>
                                      </div>

                                      <div className='d-flex flex-column flex-1 mt-1'>
                                        <span className='fs-7'>{item?.note ?? 'N/A'}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className='card bg-white mb-2 border border-dotted border-gray-400 border-1 mx-2'>
                            <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                              <div className='text-center'>
                                <div className='symbol symbol-200px '>
                                  <img src='/media/other/nodata.png' alt='' />
                                </div>
                              </div>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                No matching records found
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 3 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_3' role='tabpanel'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex justify-content-between align-items-center flex-wrap mt-5'>
                      <div className='d-flex flex-column'>
                        <span className='fw-bold me-2 fs-2'>Ad Astra</span>
                        <span className='text-gray-800 fs-6'>
                          8701 Georgia Ave, Suit # 800 Silver Spring, Maryland, 20910 US
                        </span>
                        {/* <span className='text-gray-800 fs-7 fw-bold'>P: +18157081344</span> */}
                      </div>
                      <div className='symbol-group symbol-hover me-2'>
                        <div className='symbol symbol-circle symbol-100px'>
                          <img src={toAbsoluteUrl('../media/logos/Untitled-1.png')} alt='' />
                        </div>
                      </div>
                    </div>
                    <div className='separator my-3 border-3 border-gray-600'></div>
                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                      <div className='d-flex flex-column'>
                        <span className='pt-1 fw-semibold fs-7 ' style={{minWidth: '160px'}}>
                          To :
                        </span>
                        <span className='fw-bold me-2 fs-6'>{invoiceDetails?.interpreter}</span>
                        {invoiceDetails?.interpreterAddress &&
                          invoiceDetails?.interpreterAddress
                            .split(',')
                            .map((item: any, index: number) => (
                              <span className='text-gray-700 fs-7' key={index}>
                                {item}
                                {invoiceDetails?.interpreterAddress?.split(',').length !== index + 1
                                  ? ','
                                  : '.'}
                              </span>
                            ))}
                        {/* <span className='text-gray-700 fs-7'>8701 Georgia Ave, Suit # 800</span>
                        <span className='text-gray-700 fs-7'>Silver Spring, Maryland, 20910</span>
                        <span className='text-gray-700 fs-7'>US</span> */}
                      </div>
                      <div className='d-flex flex-column align-items-end justify-content-end'>
                        <span className='fw-semibold me-2 fs-7 text-gray-700'>
                          Invoice # :{' '}
                          <span className='text-primary fw-bold'>
                            {' '}
                            {invoiceDetails?.voucherNo ?? 'N/A'}
                          </span>
                        </span>
                        <span className='fw-semibold me-2 fs-7 text-gray-700'>
                          Invoice Date :
                          <span className='text-dark fw-bold fs-7'>
                            {' '}
                            {moment(invoiceDetails?.voucherDate)?.format('YYYY-MM-DD')}
                          </span>
                        </span>
                        <span className='fw-semibold me-2 fs-7 text-gray-700'>
                          Due Date:
                          <span className='text-dark fw-bold fs-7'>
                            {' '}
                            {moment(invoiceDetails?.dueDate)?.format('YYYY-MM-DD')}
                          </span>
                        </span>
                        <span className='fw-semibold me-2 fs-7 text-gray-700'>
                          Time Zone:
                          <span className='text-dark fw-bold fs-7'>
                            {' '}
                            {invoiceDetails?.timeZone ?? 'N/A'}
                          </span>
                        </span>
                      </div>
                    </div>

                    <div className='separator my-3'></div>
                    <div className=''>
                      <div className='px-2 py-2 bg-light border border-gray-300 rounded'>
                        <span className='text-dark fw-bold'>
                          Line Count: {invoiceDetails?.voucherLineList?.length ?? 'N/A'}
                        </span>
                      </div>
                      <div className='py-0 pt-3 mb-xl-5 mt-3'>
                        {/* <h4 className='mb-3'>Overview</h4> */}
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                          {/* begin::Table */}
                          <table
                            className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'
                            id='sumtable'
                          >
                            {/* begin::Table head */}
                            <thead>
                              <tr className='fw-bold text-muted text-uppercase'>
                                <th className='min-w-150px '>Call</th>
                                <th className='min-w-100px '>Interpreter</th>
                                <th className='min-w-100px '>Type</th>
                                <th className='min-w-100px '>Date/Time</th>
                                <th className='min-w-100px '>Duration</th>
                                <th className='min-w-100px '>Rate</th>
                                <th className='min-w-100px '>Extras</th>
                                <th className='min-w-100px text-end'>Total</th>
                                {/* <th className='min-w-100px text-end'>Action</th> */}
                              </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                              {invoiceDetails?.voucherLineList?.map((item: any, index: number) => (
                                <tr>
                                  <td>
                                    <div className='d-flex flex-column'>
                                      <a className='text-gray-800 text-hover-primary  fs-7'>
                                        {item?.contactId ?? 'N/A'}
                                      </a>
                                      <span className='text-gray-500 fs-9'>
                                        {item?.fromLanguage ?? 'N/A'} to {item?.toLanguage ?? 'N/A'}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <a className='text-gray-800 text-hover-primary  fs-7'>
                                      {item?.interpreter ?? 'N/A'}
                                    </a>
                                  </td>
                                  <td>
                                    <a className='text-gray-800 text-hover-primary  fs-7'>
                                      {item?.callType ?? 'N/A'}
                                    </a>
                                  </td>
                                  <td>
                                    <a className='text-gray-800 text-hover-primary  fs-7'>
                                      {moment(item?.callTime)?.format('MM/DD/YYYY hh:mm A') ??
                                        'N/A'}
                                    </a>
                                  </td>
                                  <td>
                                    <a className='text-gray-800 text-hover-primary  fs-7'>
                                      {item?.duration ?? 'N/A'} minutes
                                    </a>
                                  </td>
                                  <td>
                                    <a className='text-gray-800 text-hover-primary  fs-7'>
                                      ${item?.amount ?? 'N/A'}
                                    </a>
                                  </td>
                                  <td>
                                    <a className='text-gray-800 text-hover-primary  fs-7'>
                                      ${item?.extra ?? 'N/A'}
                                    </a>
                                  </td>
                                  <td className='text-end'>
                                    <a className='text-gray-800 text-hover-primary  fs-7'>
                                      ${item?.total ?? 'N/A'}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                              {/* <tr>
                                <td colSpan={7} className='text-end'>
                                  <a className='text-dark fs-6'>Calls :</a>
                                </td>
                                <td className='text-end border-bottom border-bottom-1 border-gray-600'>
                                  <a className='text-dark fs-6'>$3.00</a>
                                </td>
                              </tr> */}
                              <tr>
                                <td colSpan={7} className='text-end'>
                                  <a className='text-dark fs-6 fw-bold'>
                                    <span className='text-danger fs-7 me-2 fw-normal'>
                                      (*Taxes Not Included)
                                    </span>{' '}
                                    TOTAL :
                                  </a>
                                </td>
                                <td className='text-end '>
                                  <a className='text-dark fs-6 fw-bold'>
                                    $
                                    {invoiceDetails?.voucherLineList?.reduce(
                                      (accumulator: number, item: any) => {
                                        return accumulator + (+item?.total || 0)
                                      },
                                      0
                                    )}
                                  </a>
                                </td>
                              </tr>
                            </tbody>

                            {/* end::Table body */}
                          </table>

                          {/* end::Table */}
                        </div>
                        {/* <div className='d-flex text-end justify-content-end w-100'>
                          <div className='flex-column'>
                            <div className='d-flex align-items-end justify-content-end'>
                              <a className='text-dark fs-6 fw-bold'>Call :</a>

                              <a className='text-dark fs-6 fw-bold'>$3.00</a>
                            </div>
                            <div className='d-flex border-bottom border-bottom-2 border-dark'>
                              <a className='text-dark fs-6 fw-bold'>
                                <span className='text-danger fs-6'>(*Taxes Not Included)</span>{' '}
                                TOTAL :
                              </a>
                              <div className='text-end'>
                                <a className='text-dark fs-6 fw-bold'> $3.00</a>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        {/* end::Table container */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isLoading && <CommonLoading />}
      </div>
    </>
  )
}
