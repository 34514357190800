import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {DataProvider} from './UI/DataContext'
import {useAuth} from '../app/modules/auth'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {Provider} from 'react-redux'
import {store} from './redux/store'
import CCPContainer from './CCPContainer'
import RequesterWidget from './RequesterWidget'

const App = () => {
  const {currentUser, auth} = useAuth()

  return (
    <Provider store={store}>
      <DataProvider>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {auth?.isAuthenticated == true && currentUser?.result.userType == 'INTERPRETER' && (
          <CCPContainer />
        )}
        <Suspense fallback={<LayoutSplashScreen />}>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <RequesterWidget />
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </Suspense>
      </DataProvider>
    </Provider>
  )
}

export {App}
