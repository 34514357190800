/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {UsersListWrapper} from './users-list/UsersList'

type Props = {
  className: string
  userType: string
  customerCode: number
}

const AdministratorsViewTable: React.FC<Props> = ({className, userType, customerCode}) => {
  return (
    <div className={`card ${className}`} data-testid="users-list-wrapper">
      <UsersListWrapper customerCode={customerCode} userType={userType} />
    </div>
  )
}

export {AdministratorsViewTable}
