import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Select, {MultiValue, SingleValue} from 'react-select'
import {Link, useParams} from 'react-router-dom'
import {useEffect, useRef, useState} from 'react'
import UnderDevelopmentBadge from '../../../common/componenets/underDevelopment/underDevelopmentBadge'
import axios, {AxiosResponse} from 'axios'
import {DropdownResponse} from '../users-list/core/_models'
import {ResponseObject, roleQUERIES} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {DeleteConfirmationModal} from '../../../common/Models/confirmation-modal/DeleteConfirmationModal'
import toaster from '../../../../Utils/toaster'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import {CommonLoading} from '../../../../Utils/commonLoading'
/* eslint-disable jsx-a11y/anchor-is-valid */
export function RoutingView() {
  const API_URL = process.env.REACT_APP_API_URL

  const [isLoading, setIsLoading] = useState(false)
  const [currentItemCode, setCurrentItemCode] = useState<string | null>(null)

  const USER_URL = `${API_URL}/accounts/dd-list-accounts/INTERPRETER`
  const getinterpreter = (): Promise<DropdownResponse> => {
    var dt = axios.get(`${USER_URL}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
    return dt
  }
  const {isFetching: isFetchingInterpreter, data: responseInterpreterMaster} = useQuery(
    `${roleQUERIES.interpreter_LIST}`,
    () => {
      return getinterpreter()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const interpreterOptions =
    responseInterpreterMaster?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  useEffect(() => {
    fetchInterpreterOptions()
  }, [])
  const fetchInterpreterOptions = async () => {
    setIsLoading(true)
    try {
      let response = await axios.get(`${API_URL}/customer/excludes/${id}`, {
        params: {
          page: 0,
          items_per_page: 10,
        },
      })
      const {data} = response.data
      setinterpreter(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const call = [
    {value: 'option 1', label: 'Eff 1'},
    {value: 'option 2', label: 'Eff 2'},
    {value: 'option 3', label: 'Eff 3'},
  ]
  const mode = [
    {value: 'option 1', label: 'Prioritize'},
    {value: 'option 2', label: 'Call Only'},
  ]
  const [isRatePerMile, setIsRatePerMile] = useState(true)
  const {id} = useParams()
  const [isChecked, setIsChecked] = useState(false)
  const [isChecked2, setIsChecked2] = useState(false)
  const [isTravelTime, setIsTravelTime] = useState(true)
  const [interpreter, setinterpreter] = useState<any>({})
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteId, setdeletedId] = useState(null)
  const [selectedInterpreters, setSelectedInterpreters] =
    useState<SingleValue<{value: string; label: string}>>()
  const modalRef = useRef<HTMLDivElement>(null)
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }
  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2)
  }
  const resetFormFields = () => {
    setSelectedInterpreters(null)
  }
  const handleSubmit = async () => {
    const SUBMIT_FILTERS_URL = `${API_URL}/customer/excludes-list`
    const data = {
      fK_User: selectedInterpreters?.value,
      fK_Customer: id,
    }

    try {
      await axios.post(SUBMIT_FILTERS_URL, data)
      toaster('success', 'Successfuly inserted')
      fetchInterpreterOptions()
      resetFormFields()
    } catch (error) {
      toaster('error', 'Loading failed!')
    }
  }

  const openDeleteModal = (code: string) => {
    setCurrentItemCode(code)
    setDeleteModalOpen(true)
  }
  const handleDeleteSubmit = async () => {
    if (currentItemCode) {
      await handledelete(currentItemCode)
      setDeleteModalOpen(false)
      setCurrentItemCode(null)
    }
  }
  const handledelete = (code: any) => {
    const SUBMIT_FILTERS_URL = `${API_URL}/customer/excludes`
    const data = {
      code: code,
      isDeleted: true,
    }

    axios
      .put(SUBMIT_FILTERS_URL, data)
      .then((response) => {
        const {status, text} = response.data
        if (status === 'S') {
          toaster('success', 'Successfuly deleted')
          fetchInterpreterOptions()
          setDeleteModalOpen(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_client_acc_rount'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Client Account Routing Settings</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 '>
                <div className='d-flex flex-column mb-2'>
                  <div className='mb-3'>
                    <span className='d-flex mb-2'>
                      <strong className='me-2'>Use Adastra Professional Interpreter Network</strong>
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span className='text-gray-800 text-hover-primary'>
                            Follow system rule (currently On)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span className='text-gray-600 text-hover-primary '>On</span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span className='text-gray-800 text-hover-primary'>Off</span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <span className='d-flex mb-2 d-flex flex-wrap'>
                      <strong className='me-2'>Conference Calling -</strong>
                      <span className='' style={{fontSize: '12px'}}>
                        Allow interpreters in your pool to dial out to the external numbers and
                        share the link to join the conference
                      </span>
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan12'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span className='text-gray-800 text-hover-primary'>
                            Follow system rule (currently On)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan12'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span className='text-gray-600 text-hover-primary '>On</span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan12'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span className='text-gray-800 text-hover-primary'>Off</span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='modal fade' tabIndex={-1} id='kt_bpin'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex flex-column'>
                <h4 className='modal-title'>BPIN Exclusion List </h4>
                <span className='text-gray-400 ' style={{fontSize: '12px'}}>
                  Listed BPIN interpreters won't ever receive a call from this account
                </span>
              </div>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 '>
                <div className='col-sm-12 col-md-9 col-lg-9 '>
                  <label htmlFor='exampleFormControlInput1' className='form-label'>
                    Interpreter ID
                  </label>
                  <div className='input-group input-group-sm'>
                    <span className='input-group-text'>#</span>

                    <input
                      type='number'
                      className='form-control form-control-white form-control-sm'
                      placeholder='Id'
                    />
                  </div>
                </div>
                <div className='col-sm-12 col-md-3 col-lg-3 d-flex align-items-end justify-content-end'>
                  <button type='button' className='btn btn-light btn-sm'>
                    Add to list
                  </button>
                </div>
              </div>
              <div className='row g-4 '>
                <div className='col-sm-12'>
                  <div className='table-responsive'>
                    <table className='table table-striped table-rounded table-hover table-row-gray-300 align-middle gs-0 gy-2'>
                      <thead>
                        <tr className='fw-semibold text-muted text-uppercase'>
                          <th className='min-w-50px '>Interpreter ID</th>
                          <th className='min-w-50px text-end'>Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a className='text-gray-800 text-hover-primary fs-6'>#1451</a>
                          </td>

                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <a
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_delete_confirm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-muted '
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a className='text-gray-800 text-hover-primary fs-6'>#5231</a>
                          </td>

                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <a
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_delete_confirm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-muted '
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a className='text-gray-800 text-hover-primary fs-6 '>#5631</a>
                          </td>

                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <a
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_delete_confirm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-muted '
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_interpreter_call_list'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex flex-column'>
                <h4 className='modal-title'>Interpreter Call List</h4>
              </div>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 '>
                <div className='row g-2'>
                  <div className='col-sm-2 col-md-2 col-lg-2 d-flex align-items-end'>
                    <div className='form-check form-check-custom form-check-light form-check-sm mb-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='flexRadioLg11'
                      />
                      <label className='form-check-label' htmlFor='flexRadioLg11'>
                        On
                      </label>
                    </div>
                  </div>
                  <div className='col-sm-10 col-md-10 col-lg-10'>
                    <div className=''>
                      <label className='form-label '>Mode</label>
                      <div>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={mode}
                            placeholder='Select Mode'
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-sm-12 col-md-12 col-lg-12 '>
                  <div className=''>
                    <label className='form-label '>Interpreters</label>
                    <div>
                      <div className='w-100'>
                        <Select
                          className='react-select-styled react-select-solid react-select-sm'
                          classNamePrefix='react-select'
                          options={interpreters}
                          placeholder='Select Interpreters'
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '100%',
                              border: '1px solid #e4e6ef',
                            }),
                          }}
                          isMulti
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12 '>
                  <div className=''>
                    <label className='form-label '>Call Centers</label>
                    <div>
                      <div className='w-100'>
                        <Select
                          className='react-select-styled react-select-solid react-select-sm'
                          classNamePrefix='react-select'
                          options={call}
                          placeholder='Select Call Centers'
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '100%',
                              border: '1px solid #e4e6ef',
                            }),
                          }}
                          isMulti
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div> */}
      <div className='modal fade' tabIndex={-1} id='kt_interpreter_donotcall_list'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex flex-column'>
                <h4 className='modal-title'>Exclusion List</h4>
              </div>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={resetFormFields}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 '>
                {/*<div className='col-sm-2 col-md-2 col-lg-2 d-flex align-items-end'>
                  <div className='form-check form-check-custom form-check-light form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg11'
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg11'>
                      On
                    </label>
                  </div>
                </div>*/}

                <div className='col-sm-12 col-md-12 col-lg-12 '>
                  <div className=''>
                    <label className='form-label '>Interpreters</label>
                    <div>
                      <div className='w-100'>
                        <Select
                          className='react-select-styled react-select-solid react-select-sm'
                          classNamePrefix='react-select'
                          options={interpreterOptions}
                          placeholder='Select Interpreters'
                          styles={{
                            control: (provided: any) => ({
                              ...provided,
                              width: '100%',
                              border: '1px solid #e4e6ef',
                            }),
                          }}
                          onChange={setSelectedInterpreters}
                          value={selectedInterpreters}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='col-sm-12 col-md-12 col-lg-12 '>
                  <div className=''>
                    <label className='form-label '>Call Centers</label>
                    <div>
                      <div className='w-100'>
                        <Select
                          className='react-select-styled react-select-solid react-select-sm'
                          classNamePrefix='react-select'
                          options={call}
                          placeholder='Select Call Centers'
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '100%',
                              border: '1px solid #e4e6ef',
                            }),
                          }}
                          isMulti
                        />
                      </div>
                    </div>
                  </div>
                </div>*/}
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button
                type='button'
                className='btn btn-light btn-sm'
                data-bs-dismiss='modal'
                onClick={resetFormFields}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-primary btn-sm'
                data-bs-dismiss='modal'
                onClick={handleSubmit}
                disabled={!selectedInterpreters}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='card-body p-0'>
        <div className='card-title d-flex align-items-center me-4 mb-3'>
          <div className='d-flex flex-column'>
            <div className='d-flex align-items-center'>
              <h5 className='text-black fs-4 fw-semibold mb-0'> Routing</h5>
            </div>
            <div className='d-flex flex-wrap fs-6'>
              <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                Enter routing rules to be applied to this account. It overrides system-level
                settings. Click Edit to add/remove interpreters.
              </p>
            </div>
          </div>
          {/* <UnderDevelopmentBadge className='' /> */}
        </div>
        <div className='row g-1 g-xl-1'>
          {process.env.REACT_APP_SERVER === 'DEV' && (
            <div className='col-md-12 col-sm-12 '>
              <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
                <div className='d-flex justify-content-between align-items-center w-100'>
                  <div className='d-flex fs-3 fw-semibold mb-3'>
                    Client account routing settings
                  </div>

                  <div className='d-flex align-items-center'>
                    <a
                      className='btn btn-color-primary btn-active-primary btn-sm'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_client_acc_rount'
                      style={{fontSize: '12px'}}
                    >
                      <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                      Edit
                    </a>
                  </div>
                </div>

                <div className='d-flex flex-column mb-2'>
                  <div className='mb-3'>
                    <span className='d-flex mb-2'>
                      <strong className='me-2'>Use Adastra Professional Interpreter Network</strong>
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Follow system rule (currently On)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            On
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Off
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <span className='d-flex mb-2 d-flex flex-wrap'>
                      <strong className='me-2'>Conference Calling -</strong>
                      <span>
                        Allow interpreters in your pool to dial out to the external numbers and
                        share the link to join the conference
                      </span>
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan12'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Follow system rule (currently On)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan12'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            On
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan12'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Off
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='row g-1 g-xl-4'>
          <div className='col-md-12 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex flex-column'>
                  <div className='d-flex fs-3 fw-semibold'> Exclusion List</div>
                  <span className='text-gray-500' style={{fontSize: '12px'}}>
                    Interpreters from this list won't be available for call
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary btn-icon'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_interpreter_donotcall_list'
                  >
                    <i className='bi bi-plus fs-2'></i>
                  </button>
                </div>
              </div>
              <div className='row g-4 '>
                <div className='py-0 pt-3'>
                  <div className='table-responsive'>
                    <table className='table table-striped table-rounded table-hover table-row-gray-300 align-middle gs-0 gy-2'>
                      <thead>
                        <tr className='fw-semibold text-muted text-uppercase'>
                          <th className='min-w-100px '>Full Name</th>
                          <th className='min-w-100px '>Email </th>
                          <th className='min-w-100px text-end'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {interpreter?.length > 0 ? (
                          interpreter?.map((item: any) => (
                            <tr style={{cursor: 'pointer'}}>
                              <td>
                                <a className='text-gray-800 text-hover-primary fs-6'>
                                  {item?.userName}
                                </a>
                              </td>
                              <td>
                                <a className='text-gray-800 text-hover-primary fs-6'>
                                  {item?.email}
                                </a>
                              </td>
                              <td className='text-end'>
                                <a
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1 '
                                  type='button'
                                  onClick={() => openDeleteModal(item?.code)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                  />
                                </a>

                                {isDeleteModalOpen && (
                                  <DeleteConfirmationModal
                                    title='Confirmation'
                                    content={'Are you sure you would like to delete ?'}
                                    setOpen={setDeleteModalOpen}
                                    onSubmit={handleDeleteSubmit}
                                  />
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>
                              <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                                <div className='text-center'>
                                  <div className='symbol symbol-200px '>
                                    <img src='/media/other/nodata.png' alt='' />
                                  </div>
                                </div>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                  No matching records found
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {isLoading && <CommonLoading />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
function setIsLoading(arg0: boolean) {
  throw new Error('Function not implemented.')
}
