import clsx from 'clsx'
import {FC, PropsWithChildren, useMemo } from 'react'
import {HeaderProps} from 'react-table'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {User} from '../../core/_models'
import { useDispatch} from 'react-redux'
import { AdministratorManagementOrder, AdministratorManagementSort, CompanyUserManagementOrder, CompanyUserManagementSort, InterperterManagementOrder, InterperterManagementSort, RequesterManagementOrder, RequesterManagementSort } from '../../../../../redux/tableSlice/tableSlice'

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<User>>
}
const UserCustomHeader: FC<Props> = ({className, title, tableProps}) => {
  const id = tableProps.column.id
  const dispath = useDispatch()
  const {state, updateState} = useQueryRequest()

  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === id
  }, [state, id])
  const order: 'asc' | 'desc' | undefined = useMemo(() => state.order, [state])

  const sortColumn = () => {
    // avoid sorting for these columns
    if (id === 'actions' || id === 'selection' || id === 'accountStatus') {
      return
    }

    if (!isSelectedForSorting) {
      // enable sort asc
      if(state?.customerCode === 0 && state?.userType === 'CONSUMER'){
        dispath(RequesterManagementSort(id))
        dispath(RequesterManagementOrder('asc'))
      }
      if (state?.userType === 'INTERPRETER') {
        dispath(InterperterManagementSort(id))
        dispath(InterperterManagementOrder('asc'))
      }
      if (state?.userType === 'SYSTEM') {
        dispath(AdministratorManagementSort(id))
        dispath(AdministratorManagementOrder('asc'))
      }
      if(state?.customerCode !== 0 && state?.userType === 'CONSUMER'){
        dispath(CompanyUserManagementSort(id))
        dispath(CompanyUserManagementOrder('asc'))
      }
      updateState({...state, sort: id, order: 'asc'})
      return
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === 'asc') {
        // enable sort desc
        if(state?.customerCode === 0 && state?.userType === 'CONSUMER'){
          dispath(RequesterManagementSort(id))
          dispath(RequesterManagementOrder('desc'))
        }
        if (state?.userType === 'INTERPRETER') {
          dispath(InterperterManagementSort(id))
          dispath(InterperterManagementOrder('desc'))
        }
        if (state?.userType === 'SYSTEM') {
          dispath(AdministratorManagementSort(id))
          dispath(AdministratorManagementOrder('desc'))
        }
        if(state?.customerCode !== 0 && state?.userType === 'CONSUMER'){
          dispath(CompanyUserManagementSort(id))
          dispath(CompanyUserManagementOrder('desc'))
        }
        updateState({...state, sort: id, order: 'desc'})
        return
      }

      // disable sort
      if(state?.customerCode === 0 && state?.userType === 'CONSUMER'){
        dispath(RequesterManagementSort(null))
        dispath(RequesterManagementOrder(null))
      }
      if (state?.userType === 'INTERPRETER') {
        dispath(InterperterManagementSort(null))
        dispath(InterperterManagementOrder(null))
      }
      if (state?.userType === 'SYSTEM') {
        dispath(AdministratorManagementSort(null))
        dispath(AdministratorManagementOrder(null))
      }
      if(state?.customerCode !== 0 && state?.userType === 'CONSUMER'){
        dispath(CompanyUserManagementSort(null))
        dispath(CompanyUserManagementOrder(null))
      }
      updateState({...state, sort: undefined, order: undefined})
    }
  }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting && order !== undefined && `table-sort-${order}`
      )}
      style={{cursor: 'pointer'}}
      onClick={sortColumn}
    >
      {title}
    </th>
  )
}

export {UserCustomHeader}
