/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, useRef} from 'react'
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useDispatch, useSelector} from 'react-redux'
import { customerManagementCurrentPage, customerManagementSearch } from '../../../../../redux/tableSlice/tableSlice'

const UsersListSearchComponent = () => {
  const {updateState} = useQueryRequest()
  const inputRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const {rowsPerPage, search} = useSelector( (state:any) => {
    return {
      rowsPerPage: state.table.customerManagement[0]?.rowsPerPage,
      search: state.table.customerManagement[0]?.searchQuery
    }
  })
  const [searchTerm, setSearchTerm] = useState<string>(search)

  const handleSearch = () => {
    if (inputRef.current) {
      const newSearchTerm = inputRef.current.value
      // setSearchTerm(newSearchTerm)
      updateState({
        search: newSearchTerm,
        items_per_page: rowsPerPage,
        page: 1,
        total: 0,
        to: 0,
        from: 0,
        last_page: 1,
      })
      dispatch(customerManagementSearch(newSearchTerm))
      dispatch(customerManagementCurrentPage(1))
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }
  useEffect(() => {
    if (searchTerm === '') {
      updateState({
        search: '',
        items_per_page: rowsPerPage,
        page: 1,
        total: 0,
        to: 0,
        from: 0,
        last_page: 1,
      })
      dispatch(customerManagementSearch(''))
      dispatch(customerManagementCurrentPage(1))
    }
  }, [searchTerm])

  useEffect(() => {
    setSearchTerm(search)
  }, [search])

  return (
    <div className='d-flex justify-content-end align-items-center'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative flex-nowrap'>
        <input
          ref={inputRef}
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-white form-control-sm w-250px custom-search-radius'
          placeholder='Search Customer'
          onKeyDown={handleKeyDown}
          value={searchTerm}
          onChange={() => setSearchTerm(inputRef.current?.value || '')}
        />
        <button
          className='btn btn-primary btn-sm btn-icon px-3 custom-search-btn-radius'
          onClick={handleSearch}
        >
          <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
        </button>
      </div>
      {/* end::Search */}
    </div>
  )
}

export {UsersListSearchComponent}
