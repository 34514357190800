/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {AppointmentViewTableFilterDropdown} from './AppointmentViewTableFilterDropdown '
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios from 'axios'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {useAuth} from '../../../modules/auth'
import {getTotalAppointments, statusMapping} from '../../../../Utils/commonData'
import toaster from '../../../../Utils/toaster'
import {UpcomingAppointmentFilterDropdown} from './UpcomingAppointmentFilterDropdown'
import {
  upcomingAppointmentsCurrentPage,
  upcomingAppointmentsRowsPerPage,
  upcomingAppointmentsSearch,
} from '../../../redux/tableSlice/tableSlice'

const API_URL = process.env.REACT_APP_API_URL
// const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10

type Props = {
  className: string
}

const UpcomingAppointmentViewTable: React.FC<Props> = ({className}) => {
  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)
  const [allAppointments, setAllAppointments] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const [isLoadingAccept, setIsLoadingAccept] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const {currentPage, rowsPerPage, searchQuery , filterData} = useSelector((state: any) => {
    return {
      currentPage: state.table.upcomingAppoinment[0].currentPage,
      rowsPerPage: state.table.upcomingAppoinment[0].rowsPerPage,
      searchQuery: state.table.upcomingAppoinment[0].searchQuery,
      filterData: state.table.upcomingAppoinment[0].filterData,
    }
  })

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = searchQuery || ''
    }
    fetchAllAppointments(filterData, searchQuery, currentPage, rowsPerPage)
  }, [])

  const fetchAllAppointments = async (
    filterData: any,
    searchQuery: any,
    currentPage: number,
    rowsPerPage: number
  ) => {
    setIsLoading(true)
    try {
      let response = await axios.post(`${API_URL}/Appoinment/upcoming-appointments`, filterData, {
        params: {
          page: currentPage,
          items_per_page: rowsPerPage,
          ...(searchQuery.length > 0 ? {search: searchQuery} : searchQuery),
        },
      })
      const {data, payload} = response.data
      setAllAppointments(data)
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const searchValue = inputRef.current?.value || ''
      dispatch(upcomingAppointmentsCurrentPage(1))
      dispatch(upcomingAppointmentsRowsPerPage(10))
      dispatch(upcomingAppointmentsSearch(searchValue))
      fetchAllAppointments(filterData, {search: searchValue}, 1, rowsPerPage)
    }
  }

  const handleAcceptReject = async (status: number, id: number, userID: string) => {
    setIsLoadingAccept(true)
    try {
      let response = await axios.put(
        `${API_URL}/Appoinment/interpreter-status/${status}/${id}/${userID}`
      )
      if (response.data.status === 'S') {
        toaster('success', 'Successfuly updated')
        fetchAllAppointments(filterData, searchQuery, currentPage, rowsPerPage)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingAccept(false)
    }
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header d-flex align-items-center px-0'>
        <div className='card-title'>Upcoming Appointments</div>
        <div className='card-toolbar d-flex align-items-end'>
          <div className='d-flex'>
            <div className='d-flex align-items-end'>
              <div className='d-flex align-items-center position-relative'>
                <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                  <input
                    ref={inputRef}
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                    placeholder='Search'
                    onKeyDown={handleKeyDown}
                    onChange={() => {
                      const value = inputRef.current?.value || ''
                      if (value === '') {
                        dispatch(upcomingAppointmentsSearch(''))
                        dispatch(upcomingAppointmentsCurrentPage(1))
                        fetchAllAppointments(filterData, '', 1, rowsPerPage)
                      }
                    }}
                  />
                  <button
                    type='button'
                    role='button'
                    aria-label='Accept'
                    className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                    onClick={() => {
                      const searchValue = inputRef.current?.value || ''
                      dispatch(upcomingAppointmentsCurrentPage(1))
                      dispatch(upcomingAppointmentsSearch(searchValue))
                      fetchAllAppointments(filterData, {search: searchValue}, 1, rowsPerPage)
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                  </button>
                </div>
              </div>
            </div>
            <div className='d-flex text-end'>
              <UpcomingAppointmentFilterDropdown
                setAllAppointments={setAllAppointments}
                setTotalPages={setTotalPages}
                setCurrentPage={currentPage}
                searchQuery={searchQuery}
                fetchAllAppointments={fetchAllAppointments}
                filterData={filterData} 
              />
            </div>
          </div>
        </div>
      </div>

      {/* begin::Body */}
      <div className='py-0 pt-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2 table-hover'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-semibold text-muted text-uppercase'>
                {/* <th className='min-w-150px '>Appointment ID</th> */}
                {currentUser?.result?.userType !== 'INTERPRETER' && (
                  <th className='min-w-250px '>Customer Name</th>
                )}
                <th className='min-w-150px '>Date</th>
                <th className='min-w-150px '>Time</th>
                <th className='min-w-150px '>Language</th>
                <th className='min-w-150px '>Service Type</th>
                <th className='min-w-200px'>Communication Type</th>
                {/* {currentUser?.result?.userType === 'INTERPRETER' && (
                  <th className='min-w-100px'>Status</th>
               
                {currentUser?.result?.userType === 'INTERPRETER' && (
                  <th className='min-w-100px text-end'>Action</th>
                )} */}
                {/* <th className='min-w-100px text-end'>Action</th> */}
              </tr>
            </thead>

            <tbody>
              {allAppointments?.length > 0 ? (
                allAppointments.map((item: any, index: number) => (
                  <tr style={{cursor: 'pointer'}}>
                    {currentUser?.result?.userType !== 'INTERPRETER' && (
                      <td
                        className=''
                        onClick={() => navigate(`/upcomingAppointmentViewer/${item?.code}`)}
                      >
                        <span className='text-gray-800 text-hover-primary fs-6'>
                          {item?.customerName}
                        </span>
                      </td>
                    )}
                    <td onClick={() => navigate(`/upcomingAppointmentViewer/${item?.code}`)}>
                      <span className='text-gray-800 text-hover-primary fs-6'>
                        {moment(item?.startTime).format('YYYY-MM-DD')}
                      </span>
                    </td>
                    <td onClick={() => navigate(`/upcomingAppointmentViewer/${item?.code}`)}>
                      <span className='text-gray-800 text-hover-primary fs-6'>
                        {moment(item.startTime).format('h:mm A')}-{' '}
                        {moment(item.endTime).format('h:mm A')}
                      </span>
                    </td>
                    <td onClick={() => navigate(`/upcomingAppointmentViewer/${item?.code}`)}>
                      <span className='text-gray-800 text-hover-primary fs-6'>
                        {item?.languageFrom ?? '-'} to {item?.languageTo ?? '-'}
                      </span>
                    </td>
                    <td onClick={() => navigate(`/upcomingAppointmentViewer/${item?.code}`)}>
                      <span className='text-gray-800 text-hover-primary fs-6'>
                        {item?.serviceType}
                      </span>
                    </td>
                    <td onClick={() => navigate(`/upcomingAppointmentViewer/${item?.code}`)}>
                      <span className='text-gray-800 text-hover-primary fs-6 '>
                        {item?.communicationType}
                      </span>
                    </td>
                    {/* {currentUser?.result?.userType === 'INTERPRETER' && (
                      <td>{statusMapping[item?.assignStatus]}</td>
                    )}
                    {currentUser?.result?.userType === 'INTERPRETER' && (
                      <td className='text-end'>
                        <div className='d-flex justify-content-end'>
                          <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='tooltip-filter'>Accept</Tooltip>}
                          >
                            <div className='d-flex justify-content-start flex-shrink-0 me-2'>
                              <button
                                className='btn btn-success btn-sm'
                                disabled={item?.assignStatus !== 0 || isLoadingAccept}
                                onClick={() =>
                                  handleAcceptReject(1, item.code, currentUser?.result?.code)
                                }
                              >
                                <i className='bi bi-check-lg'></i>
                              </button>
                            </div>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='tooltip-filter'>Decline</Tooltip>}
                          >
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <button
                                className='btn btn-danger btn-sm'
                                disabled={item?.assignStatus !== 0 || isLoadingAccept}
                                onClick={() =>
                                  handleAcceptReject(2, item.code, currentUser?.result?.code)
                                }
                              >
                                <i className='bi bi-x-lg'></i>
                              </button>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </td>
                    )} */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                      <div className='text-center'>
                        <div className='symbol symbol-200px '>
                          <img src='/media/other/nodata.png' alt='' />
                        </div>
                      </div>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                        No matching records found
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <CommonPaginationModel
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          total={totalItems}
          onPageChange={(page: number) => {
            dispatch(upcomingAppointmentsCurrentPage(page))
            fetchAllAppointments(filterData, searchQuery, page, rowsPerPage)
          }}
          onRowsPerPageChange={(newRowsPerPage: number) => {
            dispatch(upcomingAppointmentsRowsPerPage(newRowsPerPage))
            dispatch(upcomingAppointmentsCurrentPage(1))
            fetchAllAppointments(filterData, searchQuery, 1, newRowsPerPage)
          }}
        />
      </div>
      {isLoading && <CommonLoading />}
    </div>
  )
}

export {UpcomingAppointmentViewTable}
