import React, {createContext, useMemo, useEffect, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select from 'react-select'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {KTSVG} from '../../../../../../_metronic/helpers/components/KTSVG'
import {
  createResponseContext,
  initialQueryResponse,
  roleQUERIES,
} from '../../../../../../_metronic/helpers'
import axios, {AxiosResponse} from 'axios'
import {DropdownResponse, User} from '../../core/_models'
import {useQuery} from 'react-query'
import {usersColumns} from '../../table/columns/_columns'
import {QueryResponseProvider, useQueryResponse} from '../../core/QueryResponseProvider'
import {Form, Formik} from 'formik'
import {
  RequesterManagementFilter,
  RequesterManagementCurrentPage,
  RequesterManagementRowsPerPage,
  RequesterManagementSort,
  RequesterManagementOrder,
  CompanyUserManagementFilter,
  CompanyUserManagementCurrentPage,
  CompanyUserManagementRowsPerPage,
  CompanyUserManagementSort,
  CompanyUserManagementOrder,
  InterperterManagementFilter,
  InterperterManagementCurrentPage,
  InterperterManagementRowsPerPage,
  InterperterManagementSort,
  InterperterManagementOrder,
  AdministratorManagementFilter,
  AdministratorManagementCurrentPage,
  AdministratorManagementRowsPerPage,
  AdministratorManagementSort,
  AdministratorManagementOrder,
} from '../../../../../redux/tableSlice/tableSlice'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`

const status: any = [
  {value: 'ACTIVE', label: 'Active'},
  {value: 'DESABLE', label: 'Inactive'},
  {value: 'PENDING', label: 'Pending'},
]

type Props = {
  isUserLoading?: boolean
  userType: string
  customerCode: number
}
export const mycontext = createContext({})
const UserListFilterDropdown: React.FC<Props> = ({userType, isUserLoading, customerCode}) => {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const dispatch = useDispatch()
  const contextValue = useSelector((state: any) =>
    userType === 'CONSUMER' && customerCode === 0
      ? state.table.RequesterManagement[0]?.filterData : 
    userType === 'CONSUMER' && customerCode !== 0
      ? state.table.CompanyUserManagement[0]?.filterData :
    userType === 'INTERPRETER'
      ? state.table.InterperterManagement[0]?.filterData :
    userType === 'SYSTEM'
      ? state.table.AdministratorManagement[0]?.filterData : ''
  );
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const GET_ROLES_URL = `${API_URL}/roles/ddlist-role/${userType}`

  const getRoles = (): Promise<any> => {
    return axios.get(GET_ROLES_URL).then((d: any) => d)
  }

  const {isFetching: isFetchingRole, data: responseRole} = useQuery(
    `${roleQUERIES.Role_LIST}`,
    getRoles,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    if (
      contextValue.customers?.length > 0 ||
      contextValue.status?.length > 0 ||
      contextValue.roles?.length > 0 ||
      contextValue.joinDate ||
      contextValue.loginDate
    ) {
      setIsFilterApplied(true)
    }
  }, [contextValue])

  const RoleOptions = Array.isArray(responseRole?.data)
    ? responseRole.data.map((d: any) => ({
        value: d.key ?? '',
        label: d.value ?? '',
      }))
    : []

  const getCustomers = (): Promise<DropdownResponse> => {
    return axios.get(GET_ALL_CUSTOMER_NAME).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
    `${roleQUERIES.Customer_LIST}`,
    getCustomers,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const customerOptions: any =
    responseCustomer?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const columns = useMemo(() => usersColumns, [])

  const handleReset = async () => {
    setShowDropdownMenu(false)
    if(userType === 'CONSUMER' && customerCode === 0) {
      dispatch(
        RequesterManagementFilter({
          customers: [],
          status: [],
          roles: [],
          joinDate: null,
          loginDate: null,
        })
      )
      dispatch(RequesterManagementCurrentPage(1))
      dispatch(RequesterManagementRowsPerPage(10))
      dispatch(RequesterManagementSort(null))
      dispatch(RequesterManagementOrder(null))
    }
    if(userType === 'CONSUMER' && customerCode !== 0){
      dispatch(
        CompanyUserManagementFilter({
          status: [],
          roles: [],
          joinDate: null,
          loginDate : null
        })
      )
      dispatch(CompanyUserManagementCurrentPage(1))
      dispatch(CompanyUserManagementRowsPerPage(10))
      dispatch(CompanyUserManagementSort(null))
      dispatch(CompanyUserManagementOrder(null))
    }
    if(userType === 'INTERPRETER'){
      dispatch(InterperterManagementFilter({
        status: [],
        joinDate: null,
        loginDate : null
      }))
      dispatch(InterperterManagementCurrentPage(1))
      dispatch(InterperterManagementRowsPerPage(10))
      dispatch(InterperterManagementSort(null))
      dispatch(InterperterManagementOrder(null))
    }
    if(userType === 'SYSTEM'){
      dispatch(AdministratorManagementFilter({
        status: [],
        joinDate: null,
        loginDate : null,
        roles : []
      }))
      dispatch(AdministratorManagementCurrentPage(1))
      dispatch(AdministratorManagementRowsPerPage(10))
      dispatch(AdministratorManagementSort(null))
      dispatch(AdministratorManagementOrder(null))
    }
    setIsFilterApplied(false)
    //refetch()
  }

  return (
    <>
      <mycontext.Provider value={contextValue}>
        <QueryResponseProvider userType={userType} customerCode={customerCode} />
        <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
            <Dropdown.Toggle
              variant='primary'
              id='dropdown-basic'
              className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-danger' : ''}`}
            >
              <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-muted' />
            </Dropdown.Toggle>
          </OverlayTrigger>
          {showDropdownMenu && (
            <Dropdown.Menu className='p-4 max-w-300px w-300px' style={{minHeight: '100%'}}>
              <Formik
                enableReinitialize
                initialValues={contextValue}
                onSubmit={async (values, {setSubmitting}) => {
                  setSubmitting(true)
                  // setContextValue(values)
                  if(userType === 'CONSUMER' && customerCode === 0) {
                    dispatch(RequesterManagementFilter(values))
                    dispatch(RequesterManagementCurrentPage(1))
                  }
                  if(userType === 'CONSUMER' && customerCode !== 0){
                    dispatch(CompanyUserManagementFilter(values))
                    dispatch(CompanyUserManagementCurrentPage(1))
                  }
                  if(userType === 'INTERPRETER'){
                    dispatch(InterperterManagementFilter(values))
                    dispatch(InterperterManagementCurrentPage(1))
                  }
                  if(userType === 'SYSTEM'){
                    dispatch(AdministratorManagementFilter(values))
                    dispatch(AdministratorManagementCurrentPage(1))
                  }
                  setShowDropdownMenu(false)
                  setIsFilterApplied(true)
                  setSubmitting(false)
                }}
              >
                {({
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  setFieldTouched,
                  setFieldValue,
                  handleSubmit,
                  resetForm,
                  values,
                  errors,
                  touched,
                  isValid,
                  dirty,
                  ...formik
                }) => (
                  <Form>
                    <div className='px-4 pb-4'>
                      <div className='fs-4 text-dark fw-semibolder'>Filter</div>
                    </div>
                    <div className='separator border-gray-200'></div>
                    <div className='px-4 mt-4'>
                      <div className='row flex-column'>
                        {customerCode === 0 && userType === 'CONSUMER' && (
                          <div className='mb-3'>
                            <div className='col-md-12'>
                              <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                  <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                                    Customer Name(s)
                                  </label>
                                </div>
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    checked={
                                      values.customers?.length === customerOptions?.length
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue(
                                          'customers',
                                          customerOptions?.map((option: any) => option.value)
                                        )
                                      } else {
                                        setFieldValue('customers', [])
                                      }
                                    }}
                                  />
                                  <label className='form-check-label' htmlFor='flexRadioLg1'>
                                    All
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className='row g-4 g-xl-4'>
                              <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div className='w-100'>
                                  <Select
                                    {...formik.getFieldProps('customers')}
                                    className='react-select-styled react-select-solid react-select-sm'
                                    classNamePrefix='react-select'
                                    options={!isFetchingCustomer ? customerOptions : []}
                                    placeholder='Select Customer(s)'
                                    isMulti
                                    value={
                                      customerOptions?.filter(
                                        (option: any) =>
                                          Array.isArray(values.customers) &&
                                          (values.customers as string[]).includes(option.value)
                                      ) || []
                                    }
                                    onChange={(selectedOptions: any[]) => {
                                      setFieldValue(
                                        'customers',
                                        selectedOptions
                                          ? selectedOptions.map((option: { value: any }) => option.value)
                                          : []
                                      )
                                    }}
                                    onBlur={(e: any) => setFieldTouched('customers', true)}
                                    styles={{
                                      control: (provided: any) => ({
                                        ...provided,
                                        width: '100%',
                                        maxHeight: '300px',
                                        overflow: 'auto',
                                        border: '1px solid #e4e6ef',
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='col-sm-12 col-md-12'>
                          <div className='mb-3'>
                            <div className='col-md-12'>
                              <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                  <label htmlFor='status' className='form-label fs-7 mb-0'>
                                    Status
                                  </label>
                                </div>
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    checked={
                                      status?.filter(
                                        (option: any) =>
                                          Array.isArray(values.status) &&
                                          (values.status as string[]).includes(option.value)
                                      ).length === status?.length
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue(
                                          'status',
                                          status?.map((option: any) => option.value)
                                        )
                                      } else {
                                        setFieldValue('status', [])
                                      }
                                    }}
                                  />
                                  <label className='form-check-label' htmlFor='flexRadioLg1'>
                                    All
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className='row g-4 g-xl-4'>
                              <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div className='w-100'>
                                  <Select
                                    {...formik.getFieldProps('status')}
                                    isMulti
                                    className='react-select-styled react-select-solid react-select-sm'
                                    classNamePrefix='react-select'
                                    options={status}
                                    value={
                                      status?.filter(
                                        (option: any) =>
                                          Array.isArray(values.status) &&
                                          (values.status as string[]).includes(option.value)
                                      ) || []
                                    }
                                    onChange={(selectedOptions: any[]) => {
                                      setFieldValue(
                                        'status',
                                        selectedOptions
                                          ? selectedOptions.map((option: { value: any }) => option.value)
                                          : []
                                      )
                                    }}
                                    placeholder='Select Status'
                                    styles={{
                                      control: (provided: any) => ({
                                        ...provided,
                                        width: '100%',
                                        maxHeight: '300px',
                                        overflowY: 'auto',
                                        border: '1px solid #e4e6ef',
                                      }),
                                      menu: (provided: any) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {userType !== 'INTERPRETER' && (
                          <div className='mb-3'>
                            <div className='col-md-12'>
                              <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                  <label htmlFor='role' className='form-label fs-7 mb-1'>
                                    Role(s)
                                  </label>
                                </div>
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    checked={
                                      values.roles?.length === RoleOptions?.length ? true : false
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue(
                                          'roles',
                                          RoleOptions.map((option: {value: any}) => option.value)
                                        )
                                      } else {
                                        setFieldValue('roles', [])
                                      }
                                    }}
                                  />
                                  <label className='form-check-label' htmlFor='flexRadioLg1'>
                                    All
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className='row g-4 g-xl-4'>
                              <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div className='w-100'>
                                  <Select
                                    {...formik.getFieldProps('roles')}
                                    className='react-select-styled react-select-solid react-select-sm'
                                    classNamePrefix='react-select'
                                    options={!isFetchingRole ? RoleOptions : []}
                                    placeholder='Select Role(s)'
                                    isMulti
                                    value={
                                      RoleOptions?.filter(
                                        (option: any) =>
                                          Array.isArray(values.roles) &&
                                          (values.roles as string[]).includes(option.value)
                                      ) || []
                                    }
                                    onChange={(selectedOptions: any[]) => {
                                      setFieldValue(
                                        'roles',
                                        selectedOptions
                                          ? selectedOptions.map((option: { value: any }) => option.value)
                                          : []
                                      )
                                    }}
                                    styles={{
                                      control: (provided: any) => ({
                                        ...provided,
                                        width: '100%',
                                        maxHeight: '300px',
                                        overflow: 'auto',
                                        border: '1px solid #e4e6ef',
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='mb-3'>
                          <label htmlFor='joinDate' className='form-label fs-7 mb-1'>
                            Joined Date
                          </label>
                          <input
                            {...formik.getFieldProps('joinDate')}
                            type='date'
                            className='form-control form-control-solid'
                            id='joinDate'
                            value={values.joinDate}
                            onChange={(e) => setFieldValue('joinDate', e.target.value)}
                          />
                        </div>
                        <div className='mb-3'>
                          <label htmlFor='loginDate' className='form-label fs-7 mb-1'>
                            Login Date
                          </label>
                          <input
                            {...formik.getFieldProps('loginDate')}
                            type='date'
                            className='form-control form-control-solid'
                            id='loginDate'
                            value={values.loginDate}
                            onChange={(e) => setFieldValue('loginDate', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='text-end mt-3 px-4'>
                        <button
                          type='reset'
                          className='btn btn-sm btn-light me-2'
                          onClick={handleReset}
                        >
                          Reset
                        </button>

                        <button
                          type='button'
                          onClick={(e) => {
                            handleSubmit()
                          }}
                          className='btn btn-sm btn-primary'
                          disabled={isSubmitting || !isValid || !dirty}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Dropdown.Menu>
          )}
        </Dropdown>
      </mycontext.Provider>
    </>
  )
}
export {UserListFilterDropdown}
