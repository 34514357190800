import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Select from 'react-select'
import {useEffect, useState} from 'react'
import toaster from '../../../../Utils/toaster'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import {Modal} from 'bootstrap'
import { CommonLoading } from '../../../../Utils/commonLoading'

const API_URL = process.env.REACT_APP_API_URL

const travelTimeSchema = Yup.object().shape({
  rateInterpreter: Yup.number()
    .required('Required')
    .positive('Must be greater than 0')
    .notOneOf([0], 'Required'),
  rateCustomer: Yup.number()
    .required('Required')
    .positive('Must be greater than 0')
    .notOneOf([0], 'Required'),
  isMinTime: Yup.boolean(),
  isMaxTime: Yup.boolean(),
  minTimeH: Yup.number(),
  minTimeM: Yup.number().when('isMinTime', {
    is: true,
    then: Yup.number()
      .required('Required')
      .test(
        'is-less-than-max',
        'Minimum time must be less than maximum time',
        function (value) {
          const {maxTimeM, isMaxTime, minTimeH} = this.parent
          const totalMinTime = (minTimeH || 0) * 60 + (value || 0)
          if (isMaxTime && maxTimeM !== undefined) {
            const totalMaxTime = (this.parent.maxTimeH || 0) * 60 + (maxTimeM || 0)
            if (totalMinTime >= totalMaxTime) {
              return false // total min time must be less than total max time
            }
          }
          return true
        }
      ),
    otherwise: Yup.number().notRequired(),
  }),
  maxTimeH: Yup.number(),
  maxTimeM: Yup.number().when('isMaxTime', {
    is: true,
    then: Yup.number()
      .required('Required')
      .test(
        'is-greater-than-min',
        'Maximum time must be greater than minimum time',
        function (value) {
          const {minTimeM, isMinTime, maxTimeH} = this.parent
          const totalMaxTime = (maxTimeH || 0) * 60 + (value || 0)
          if (isMinTime && minTimeM !== undefined) {
            const totalMinTime = (this.parent.minTimeH || 0) * 60 + (minTimeM || 0)
            if (totalMaxTime <= totalMinTime) {
              return false // total max time must be greater than total min time
            }
          }
          return true
        }
      ),
    otherwise: Yup.number().notRequired(),
  }),
  isAutomaticInterpreter: Yup.boolean(),
  isAutomaticCustomer: Yup.boolean(),
})

const fuelDistanceSchema = Yup.object().shape({
  ratePerMile: Yup.number()
    .required('Required')
    .positive('Must be greater than 0')
    .notOneOf([0], 'Required'),
  isMinMiles: Yup.boolean(),
  minMile: Yup.number().when('isMinMiles', {
    is: true, // Only validate when isMinTime is true
    then: Yup.number()
      .required('Required')
      .positive('Must be greater than 0')
      .test(
        'is-less-than-max',
        'Minimum miles must be less than maximum miles',
        function (value: any) {
          const {maxMile, isMaxTime} = this.parent
          if (isMaxTime && maxMile !== undefined && value > maxMile) {
            return false // minTimeH must be less than maxTimeH
          }
          return true
        }
      ),
    otherwise: Yup.number().notRequired(),
  }),

  isMaxMile: Yup.boolean(),
  maxMile: Yup.number().when('isMaxMile', {
    is: true, // Only validate when isMaxTime is true
    then: Yup.number()
      .required('Required')
      .positive('Must be greater than 0')
      .test(
        'is-greater-than-min',
        'Maximum miles must be greater than minimum miles',
        function (value: any) {
          const {minMile, isMinMiles} = this.parent
          if (isMinMiles && minMile !== undefined && value < minMile) {
            return false // maxTimeH must be greater than minTimeH
          }
          return true
        }
      ),
    otherwise: Yup.number().notRequired(),
  }),

  isAutomaticInterpreter: Yup.boolean(),
  isAutomaticCustomer: Yup.boolean(),
  isRoundTrip: Yup.boolean(),
})

const HoursOptions = Array.from({length: 24}, (_, i) => ({
  value: i,
  label: String(i).padStart(2, '0'),
}))

const MinutesOptions = Array.from({length: 60}, (_, i) => ({
  value: i,
  label: String(i).padStart(2, '0'),
}))

/* eslint-disable jsx-a11y/anchor-is-valid */
export function ExpensesView() {
  const [fuelDistanceInitialValues, setFuelDistanceInitialValues] = useState({
    code: '',
    ratePerMile: '',
    isMinMiles: false,
    minMile: '',
    isMaxMile: false,
    maxMile: '',
    isAutomaticInterpreter: true,
    isAutomaticCustomer: true,
    isRoundTrip: false,
  })
  const [travelTimeInitialValues, setTravelTimeInitialValues] = useState({
    code: '',
    rateInterpreter: '',
    rateCustomer: '',
    isMinTime: false,
    minTimeH: 0,
    minTimeM: 0,
    isMaxTime: false,
    maxTimeH: 0,
    maxTimeM: 0,
    isAutomaticInterpreter: true,
    isAutomaticCustomer: true,
  })

  const [fuelDistanceDetails, setFuelDistanceDetails] = useState<any>({})
  const [travelTimeDetails, setTravelTimeDetails] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)

  const fetchFuelDistanceDetails = async () => {
    try {
      setIsLoading(true)
      let response = await axios.get(`${API_URL}/invoice/distence-rule`)
      const {data} = response.data
      if (data) setFuelDistanceDetails(data)
    } catch (error) {
      toaster('error', 'Loading failed!')
      console.log(error)
    }finally{
      setIsLoading(false)
    }
  }

  const fetchTravelTimeDetails = async () => {
    try {
      setIsLoading(true)
      let response = await axios.get(`${API_URL}/invoice/traval-time-rule`)
      const {data} = response.data
      if (data) setTravelTimeDetails(data)
    } catch (error) {
      toaster('error', 'Loading failed!')
      console.log(error)
    }finally{
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchFuelDistanceDetails()
    fetchTravelTimeDetails()
  }, [])

  const formatTravelTime = (TimeH: number, TimeM: number) => {
    if (TimeH > 0 || TimeM > 0) {
      const timeParts = []
      if (TimeH > 0) timeParts.push(`${TimeH}hr`)
      if (TimeM > 0) timeParts.push(`${TimeM}min`)

      return timeParts.join(' ')
    }
    return 'Not configured'
  }

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_edit_mileage_expense'>
        <Formik
          enableReinitialize
          initialValues={fuelDistanceInitialValues}
          validationSchema={fuelDistanceSchema}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            setSubmitting(true)

            try {
              let response = await axios.post(`${API_URL}/invoice/distence-rule`, {
                ...values,
                minMile: values.minMile === '' ? 0 : values.minMile,
                maxMile: values.maxMile === '' ? 0 : values.maxMile,
              })

              if (response.data.status === 'S') {
                toaster('success', response.data.text ?? 'Successfully Updated')
                fetchFuelDistanceDetails()
                const modal = document.getElementById('kt_edit_mileage_expense')
                if (modal) {
                  const modalInstance = Modal.getInstance(modal)
                  if (modalInstance) modalInstance.hide()
                }
                resetForm()
              } else if (response.data.status === 'E') {
                toaster('error', response.data.text ?? 'Updated Error')
              }
            } catch (ex) {
              toaster('error', 'Server Error')
              console.error(ex)
            } finally {
              setSubmitting(true)
            }
          }}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue,
            handleSubmit,
            resetForm,
            values,
            errors,
            touched,
            isValid,
            dirty,
            ...formik
          }) => (
            <Form>
              <div className='modal-dialog modal-md'>
                <div className='modal-content '>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Distance Rule</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => {
                        resetForm()
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
                    <div className='row g-4 '>
                      <div className='col-sm-12 col-md-12 col-lg-12 '>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className='required form-label fs-7 mb-1 '
                        >
                          Rate per Mile
                        </label>
                        <div className='input-group input-group-sm'>
                          {/* <span className='input-group-text'>Hr</span> */}
                          <span className='input-group-text'>$</span>
                          <input
                            type='number'
                            className='form-control form-control-white form-control-sm'
                            placeholder='Enter Rate per Mile'
                            id='ratePerMile'
                            name='ratePerMile'
                            value={values.ratePerMile}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {touched.ratePerMile && errors.ratePerMile && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.ratePerMile}</div>
                          </div>
                        )}
                      </div>

                      <div className='col-sm-12 col-md-6 col-lg-6 '>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='isMinMiles'
                            name='isMinMiles'
                            checked={values.isMinMiles}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue('isMinMiles', true)
                              } else {
                                setFieldValue('isMinMiles', false)
                                setFieldValue('minMile', '')
                              }
                            }}
                            onBlur={() => {
                              setFieldTouched('isMinMiles', true)
                              setFieldTouched('minMile', true)
                              setFieldTouched('isMaxMile', true)
                              setFieldTouched('maxMile', true)
                            }}
                          />
                          <label className='form-check-label' htmlFor='flexRadioLg'>
                            Min Miles
                          </label>
                        </div>

                        <input
                          type='number'
                          id='minMile'
                          name='minMile'
                          className='form-control form-control-white form-control-sm'
                          placeholder='Enter Min Miles'
                          disabled={!values.isMinMiles}
                          value={values.minMile}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.minMile && errors.minMile && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.minMile}</div>
                          </div>
                        )}
                      </div>

                      <div className='col-sm-12 col-md-6 col-lg-6 '>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='isMaxMile'
                            name='isMaxMile'
                            checked={values.isMaxMile}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue('isMaxMile', true)
                              } else {
                                setFieldValue('isMaxMile', false)
                                setFieldValue('maxMile', '')
                              }
                            }}
                            onBlur={() => {
                              setFieldTouched('isMinMiles', true)
                              setFieldTouched('minMile', true)
                              setFieldTouched('isMaxMile', true)
                              setFieldTouched('maxMile', true)
                            }}
                          />
                          <label className='form-check-label' htmlFor='flexRadioLg'>
                            Max Miles
                          </label>
                        </div>
                        <input
                          id='maxMile'
                          name='maxMile'
                          type='number'
                          className='form-control form-control-white form-control-sm'
                          placeholder='Enter Max Miles'
                          disabled={!values.isMaxMile}
                          value={values.maxMile}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.maxMile && errors.maxMile && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.maxMile}</div>
                          </div>
                        )}
                      </div>

                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='isAutomaticInterpreter'
                            name='isAutomaticInterpreter'
                            checked={values.isAutomaticInterpreter}
                            onChange={(e) => {
                              setFieldValue('isAutomaticInterpreter', e.target.checked)
                            }}
                            onBlur={handleBlur}
                          />
                          <label className='form-check-label' htmlFor='flexRadioLg1'>
                            Automatically add to appointments for Interpreters
                          </label>
                        </div>
                      </div>

                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='isAutomaticCustomer'
                            name='isAutomaticCustomer'
                            checked={values.isAutomaticCustomer}
                            onChange={(e) => {
                              setFieldValue('isAutomaticCustomer', e.target.checked)
                            }}
                            onBlur={handleBlur}
                          />
                          <label className='form-check-label' htmlFor='flexRadioLg2'>
                            Automatically add to appointments for Customer
                          </label>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='isRoundTrip'
                            name='isRoundTrip'
                            checked={values.isRoundTrip}
                            onChange={(e) => {
                              setFieldValue('isRoundTrip', e.target.checked)
                            }}
                            onBlur={handleBlur}
                          />
                          <label className='form-check-label' htmlFor='flexRadioLg'>
                            Round-trip{' '}
                            <i
                              className='bi bi-info-circle text-info'
                              data-bs-toggle='popover'
                              data-bs-custom-class='popover-inverse'
                              data-bs-placement='top'
                              title='When selected, distance from interpreter address to appointment location will be automatically doubled.'
                            ></i>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer py-3'>
                    <button
                      type='button'
                      className='btn btn-light btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => resetForm()}
                    >
                      Cancel
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm'
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting || !dirty || !isValid}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_edit_travel_expense'>
        <Formik
          enableReinitialize
          initialValues={travelTimeInitialValues}
          validationSchema={travelTimeSchema}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            setSubmitting(true)

            try {
              let response = await axios.post(`${API_URL}/invoice/traval-time-rule`, {
                ...values,
                minTimeH: values.isMinTime ? values.minTimeH : 0,
                minTimeM: values.isMinTime ? values.minTimeM : 0,
                maxTimeH: values.isMaxTime ? values.maxTimeH : 0,
                maxTimeM: values.isMaxTime ? values.maxTimeM : 0,
              })

              if (response.data.status === 'S') {
                toaster('success', response.data.text ?? 'Successfully Updated')
                fetchTravelTimeDetails()
                const modal = document.getElementById('kt_edit_travel_expense')
                if (modal) {
                  const modalInstance = Modal.getInstance(modal)
                  if (modalInstance) modalInstance.hide()
                }
                resetForm()
              } else if (response.data.status === 'E') {
                toaster('error', response.data.text ?? 'Updated Error')
              }
            } catch (ex) {
              toaster('error', 'Server Error')
              console.error(ex)
            } finally {
              setSubmitting(true)
            }
          }}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue,
            handleSubmit,
            resetForm,
            values,
            errors,
            touched,
            isValid,
            dirty,
            ...formik
          }) => (
            <Form>
              <div className='modal-dialog modal-md'>
                <div className='modal-content '>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Travel Time Rule</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => resetForm()}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
                    <div className='row g-4 '>
                      <>
                        <div className='col-sm-12 col-md-12 col-lg-12 '>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1 '
                          >
                            New Rate
                          </label>
                          <div className='input-group input-group-sm'>
                            <span className='input-group-text'>$</span>

                            <input
                              type='number'
                              className='form-control form-control-white form-control-sm'
                              placeholder='Enter Rate'
                              name='rateInterpreter'
                              id='rateInterpreter'
                              value={values.rateInterpreter}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <span className='input-group-text'>Per</span>
                            <select
                              className='form-select form-select-sm form-select-white rounded-0'
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                            >
                              <option value='1'>Minute</option>
                            </select>
                            <span className='input-group-text'>for interpreterse</span>
                          </div>
                          {touched.rateInterpreter && errors.rateInterpreter && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.rateInterpreter}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12 '>
                          <div className='input-group input-group-sm'>
                            <span className='input-group-text'>$</span>

                            <input
                              type='number'
                              className='form-control form-control-white form-control-sm'
                              placeholder='Enter Rate'
                              name='rateCustomer'
                              id='rateCustomer'
                              value={values.rateCustomer}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <span className='input-group-text'>Per</span>

                            <select
                              className='form-select form-select-sm form-select-white rounded-0'
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                            >
                              <option value='1'>Minute</option>
                            </select>
                            <span className='input-group-text'>for Customer</span>
                          </div>
                          {touched.rateCustomer && errors.rateCustomer && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.rateCustomer}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                      <div className='col-sm-12 col-md-12 col-lg-12 '>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='flexRadioLg'
                            checked={values.isMinTime}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue('isMinTime', true)
                              } else {
                                setFieldValue('isMinTime', false)
                                setFieldValue('minTimeH', 0)
                                setFieldValue('minTimeM', 0)
                              }
                            }}
                            onBlur={() => {
                              setFieldTouched('isMinTime', true)
                              setFieldTouched('isMaxTime', true)
                              setFieldTouched('minTimeH', true)
                              setFieldTouched('minTimeM', true)
                              setFieldTouched('maxTimeH', true)
                              setFieldTouched('maxTimeM', true)
                            }}
                          />
                          <label className='form-check-label' htmlFor='flexRadioLg'>
                            Min Time
                          </label>
                        </div>

                        <div className='input-group input-group-sm'>
                          <div className='d-flex w-auto' style={{flex: 1}}>
                            <Select
                              id='minTimeH'
                              name='minTimeH'
                              className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                              classNamePrefix='react-select'
                              options={HoursOptions}
                              onChange={(selectedOption: any) =>
                                setFieldValue('minTimeH', selectedOption?.value)
                              }
                              isDisabled={!values.isMinTime}
                              value={{
                                value: values.minTimeH,
                                label:
                                  HoursOptions.find((x: any) => x.value === values.minTimeH)
                                    ?.label || 'Select Hours',
                              }}
                              onBlur={(e: any) => setFieldTouched('minTimeH', true)}
                              placeholder='None'
                              styles={{
                                control: (provided: any) => ({
                                  ...provided,
                                  width: '100%',
                                  border: '1px solid #e4e6ef',
                                }),
                              }}
                            />
                          </div>
                          <span className='input-group-text' style={{flex: 1}}>
                            Hours
                          </span>
                          <div className='d-flex w-auto' style={{flex: 1}}>
                            <Select
                              id='minTimeM'
                              name='minTimeM'
                              className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                              classNamePrefix='react-select'
                              options={MinutesOptions}
                              isDisabled={!values.isMinTime}
                              placeholder='None'
                              onChange={(selectedOption: any) =>
                                setFieldValue('minTimeM', selectedOption?.value)
                              }
                              value={{
                                value: values.minTimeM,
                                label:
                                  MinutesOptions.find((x: any) => x.value === values.minTimeM)
                                    ?.label || 'Select Minutes',
                              }}
                              onBlur={(e: any) => setFieldTouched('minTimeM', true)}
                              styles={{
                                control: (provided: any) => ({
                                  ...provided,
                                  width: '100%',
                                  border: '1px solid #e4e6ef',
                                }),
                              }}
                            />
                          </div>
                          <span className='input-group-text' style={{flex: 1}}>
                            Minutes
                          </span>
                        </div>

                        {(errors?.minTimeH || errors?.minTimeM) &&
                          (touched?.minTimeH || touched?.minTimeM) && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.minTimeH || errors.minTimeM}</span>
                              </div>
                            </div>
                          )}
                      </div>

                      <div className='col-sm-12 col-md-12 col-lg-12 '>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='flexRadioLg'
                            checked={values.isMaxTime}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue('isMaxTime', true)
                              } else {
                                setFieldValue('isMaxTime', false)
                                setFieldValue('maxTimeH', 0)
                                setFieldValue('maxTimeM', 0)
                              }
                            }}
                            onBlur={() => {
                              setFieldTouched('isMinTime', true)
                              setFieldTouched('isMaxTime', true)
                              setFieldTouched('minTimeH', true)
                              setFieldTouched('minTimeM', true)
                              setFieldTouched('maxTimeH', true)
                              setFieldTouched('maxTimeM', true)
                            }}
                          />
                          <label className='form-check-label' htmlFor='flexRadioLg'>
                            Max Time
                          </label>
                        </div>
                        <div className='input-group input-group-sm'>
                          <div className='d-flex w-auto' style={{flex: 1}}>
                            <Select
                              id='hours'
                              isDisabled={!values.isMaxTime}
                              className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                              classNamePrefix='react-select'
                              options={HoursOptions}
                              onChange={(selectedOption: any) =>
                                setFieldValue('maxTimeH', selectedOption?.value)
                              }
                              value={{
                                value: values.maxTimeH,
                                label:
                                  HoursOptions.find((x: any) => x.value === values.maxTimeH)
                                    ?.label || 'Select Hours',
                              }}
                              onBlur={(e: any) => setFieldTouched('maxTimeH', true)}
                              placeholder='None'
                              styles={{
                                control: (provided: any) => ({
                                  ...provided,
                                  width: '100%',
                                  border: '1px solid #e4e6ef',
                                }),
                              }}
                            />
                          </div>
                          <span className='input-group-text' style={{flex: 1}}>
                            Hours
                          </span>
                          <div className='d-flex w-auto' style={{flex: 1}}>
                            <Select
                              id='minutes'
                              className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                              classNamePrefix='react-select'
                              options={MinutesOptions}
                              isDisabled={!values.isMaxTime}
                              placeholder='None'
                              onChange={(selectedOption: any) =>
                                setFieldValue('maxTimeM', selectedOption?.value)
                              }
                              value={{
                                value: values.maxTimeM,
                                label:
                                  MinutesOptions.find((x: any) => x.value === values.maxTimeM)
                                    ?.label || 'Select Minutes',
                              }}
                              onBlur={(e: any) => setFieldTouched('maxTimeM', true)}
                              styles={{
                                control: (provided: any) => ({
                                  ...provided,
                                  width: '100%',
                                  border: '1px solid #e4e6ef',
                                }),
                              }}
                            />
                          </div>
                          <span className='input-group-text' style={{flex: 1}}>
                            Minutes
                          </span>
                        </div>

                        {(errors?.maxTimeH || errors?.maxTimeM) &&
                          (touched?.maxTimeH || touched?.maxTimeM) && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.maxTimeH || errors.maxTimeM}</span>
                              </div>
                            </div>
                          )}
                      </div>

                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='isAutomaticInterpreter'
                            name='isAutomaticInterpreter'
                            checked={values.isAutomaticInterpreter}
                            onChange={(e) => {
                              setFieldValue('isAutomaticInterpreter', e.target.checked)
                            }}
                            onBlur={(e) => setFieldTouched('isAutomaticInterpreter', true)}
                          />
                          <label className='form-check-label' htmlFor='flexRadioLg1'>
                            Automatically add to appointments for Interpreters
                          </label>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='isAutomaticCustomer'
                            name='isAutomaticCustomer'
                            checked={values.isAutomaticCustomer}
                            onChange={(e) => {
                              setFieldValue('isAutomaticCustomer', e.target.checked)
                            }}
                            onBlur={(e) => setFieldTouched('isAutomaticCustomer', true)}
                          />
                          <label className='form-check-label' htmlFor='flexRadioLg2'>
                            Automatically add to appointments for Customer
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer py-3'>
                    <button
                      type='button'
                      className='btn btn-light btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => resetForm()}
                    >
                      Cancel
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm'
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting || !dirty || !isValid}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className='card-body p-0'>
        <div className='row'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='card-title d-flex align-items-center me-4 mb-3'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <h5 className='text-black fs-4 fw-semibold mb-0'> Expense Manage</h5>
                </div>
                <div className='d-flex flex-wrap fs-6 '>
                  <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                    Add system defaults and turn on/off expense related features
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row mx-1'>
          <div className='col-md-12 col-sm-12 border border-gray-500 border-dotted rounded p-5 mb-3'>
            <div className='d-flex align-items-start flex-column'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex fs-3 fw-semibold mb-3'>Fuel/Mileage</div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_edit_mileage_expense'
                    style={{fontSize: '12px'}}
                    onClick={() =>
                      setFuelDistanceInitialValues({
                        ...fuelDistanceDetails,
                        minMile:
                          fuelDistanceDetails?.minMile === 0 ? '' : fuelDistanceDetails?.minMile,
                        isMinMiles: fuelDistanceDetails?.minMile === 0 ? false : true,
                        maxMile:
                          fuelDistanceDetails?.maxMile === 0 ? '' : fuelDistanceDetails?.maxMile,
                        isMaxMile: fuelDistanceDetails?.maxMile === 0 ? false : true,
                      })
                    }
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                <span className='d-flex mb-2'>
                  <strong className='me-2'>Rate per Mile:</strong>
                  {fuelDistanceDetails?.ratePerMile
                    ? `$${fuelDistanceDetails?.ratePerMile}/mi`
                    : 'Not configured'}
                </span>
                <span className='d-flex mb-2'>
                  <strong className='me-2'>Min Miles:</strong>
                  {fuelDistanceDetails?.minMile !== 0 && fuelDistanceDetails?.minMile
                    ? `${fuelDistanceDetails?.minMile}`
                    : 'Not configured'}
                </span>
                <span className='d-flex mb-2'>
                  <strong className='me-2'>Max Miles:</strong>
                  {fuelDistanceDetails?.maxMile !== 0 && fuelDistanceDetails?.maxMile
                    ? `${fuelDistanceDetails?.maxMile}`
                    : 'Not configured'}
                </span>
                <div className='mb-3'>
                  <span className='d-flex mb-2'>
                    <strong className='me-2'>Auto-Expense Appointments for Interpreters -</strong>
                    Automatically add mileage expense for interpreters.
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={fuelDistanceDetails?.isAutomaticInterpreter}
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={!fuelDistanceDetails?.isAutomaticInterpreter}
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className='mb-3'>
                  <span className='d-flex mb-2'>
                    <strong className='me-2'>Auto-Expense Appointments for Customers -</strong>
                    Automatically add mileage expense for client accounts.
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={fuelDistanceDetails?.isAutomaticCustomer}
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={!fuelDistanceDetails?.isAutomaticCustomer}
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className='mb-3'>
                  <span className='d-flex mb-2'>
                    <strong className='me-2'>Round-trip -</strong>Automatically double distance from
                    interpreter address to appointment location.
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={fuelDistanceDetails?.isRoundTrip}
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={!fuelDistanceDetails?.isRoundTrip}
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 col-sm-12 border border-gray-500 border-dotted rounded p-5'>
            <div className='d-flex align-items-start flex-column'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex fs-3 fw-semibold mb-3'>Travel Time</div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_edit_travel_expense'
                    style={{fontSize: '12px'}}
                    onClick={() =>
                      setTravelTimeInitialValues({
                        ...travelTimeDetails,
                        isMinTime:
                          travelTimeDetails?.minTimeH === 0 && travelTimeDetails?.minTimeM === 0
                            ? false
                            : true,
                        isMaxTime:
                          travelTimeDetails?.maxTimeH === 0 && travelTimeDetails?.maxTimeM === 0
                            ? false
                            : true,
                      })
                    }
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                <span className='d-flex mb-2'>
                  <strong className='me-2'>Interpreter Rate per Minutes:</strong>
                  {travelTimeDetails?.rateInterpreter
                    ? `$${travelTimeDetails?.rateInterpreter}/min`
                    : 'Not configured'}
                </span>
                <span className='d-flex mb-2'>
                  <strong className='me-2'>Customer Rate per Minutes:</strong>{' '}
                  {travelTimeDetails?.rateCustomer
                    ? `$${travelTimeDetails?.rateCustomer}/min`
                    : 'Not configured'}
                </span>
                <span className='d-flex mb-2'>
                  <strong className='me-2'>Min Time:</strong>
                  {formatTravelTime(travelTimeDetails?.minTimeH, travelTimeDetails?.minTimeM)}
                </span>
                <span className='d-flex mb-2'>
                  <strong className='me-2'>Max Time:</strong>{' '}
                  {formatTravelTime(travelTimeDetails?.maxTimeH, travelTimeDetails?.maxTimeM)}
                </span>

                <div className='mb-3'>
                  <span className='d-flex mb-2'>
                    <strong className='me-2'>Auto-Expense Appointments for Interpreters -</strong>
                    Automatically add mileage expense for interpreters.
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={travelTimeDetails?.isAutomaticInterpreter}
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={!travelTimeDetails?.isAutomaticInterpreter}
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className='mb-3'>
                  <span className='d-flex mb-2'>
                    <strong className='me-2'>Auto-Expense Appointments for Customers -</strong>
                    Automatically add travel time expense for client accounts.
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={travelTimeDetails?.isAutomaticCustomer}
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={!travelTimeDetails?.isAutomaticCustomer}
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoading && <CommonLoading />}
      </div>
    </>
  )
}
