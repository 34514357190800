/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {MainLogs} from './MainLogs'
import './style.css'
import axios from 'axios'
import {useAuth} from '../../../modules/auth'
import {useDispatch} from 'react-redux'
import {AnyAction, ThunkDispatch} from '@reduxjs/toolkit'
import {fetchAwsRequesters} from '../../../redux/awsRequesterSlice'

const API_URL = process.env.REACT_APP_API_URL

type Props = {
  className: string
}

const DashboardLogs: React.FC<Props> = () => {
  const [showOndemandUI, setShowOndemandUI] = useState(true)
  const [showSheduledUI, setShowSheduledUI] = useState(false)
  const [showInPersonUI, setShowInPersonUI] = useState(false)
  const [showOperatorUI, setShowOperatorUI] = useState(false)
  const [showEffectiff, setShowEffectiff] = useState(false)
  const [callType, setCallType] = useState(1)
  const [pageTitle, setPageTitle] = useState('On Demand Log')
  const [downloadPage, setDownLoadPage] = useState('OnDemand')
  const {currentUser} = useAuth()
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch()

  const [totalCallLength, setTotalCallLength] = useState<string>('00:00:00')

  function handleOndemandClick() {
    setPageTitle('On Demand Log')
    setDownLoadPage('OnDemand')
    setCallType(1)
    setShowOndemandUI(true)
    setShowSheduledUI(false)
    setShowEffectiff(false)
    setShowInPersonUI(false)
    setShowOperatorUI(false)
  }

  function handleSheduledClick() {
    setPageTitle('Scheduled Log')
    setDownLoadPage('Scheduled')
    setCallType(0)
    setShowOndemandUI(false)
    setShowSheduledUI(true)
    setShowInPersonUI(false)
    setShowEffectiff(false)
    setShowOperatorUI(false)
  }
  function handleInPersonClick() {
    setPageTitle('In Person Log')
    setDownLoadPage('InPerson')
    setCallType(2)
    setShowOndemandUI(false)
    setShowSheduledUI(false)
    setShowInPersonUI(true)
    setShowEffectiff(false)
    setShowOperatorUI(false)
  }
  function handleOperatorClick() {
    setPageTitle('Operator Log')
    setDownLoadPage('Operator')
    setCallType(3)
    setShowOndemandUI(false)
    setShowSheduledUI(false)
    setShowInPersonUI(false)
    setShowEffectiff(false)
    setShowOperatorUI(true)
  }

  function handleEffectiffClick() {
    setPageTitle('Backstop Log')
    setDownLoadPage('effectiff')
    setCallType(5)
    setShowOndemandUI(false)
    setShowSheduledUI(false)
    setShowInPersonUI(false)
    setShowOperatorUI(false)
    setShowEffectiff(true)
  }

  const fetchTatalCallLength = async () => {
    try {
      let response = await axios.get(`${API_URL}/aws-connect/call-duration`)
      setTotalCallLength(`${response.data.hh}:${response.data.mm}:${response.data.ss}`)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchTatalCallLength()
    if (
      currentUser?.result?.role === 'Requester Content Admin' ||
      currentUser?.result?.userType === 'SYSTEM'
    ) {
      dispatch(fetchAwsRequesters())
    }
  }, [dispatch])

  return (
    <>
      <div className='card '>
        <div className='card-header px-0 '>
          <div className='card-title d-flex align-items-center me-4'>
            <div className='p-4 d-flex justify-content-center align-items-center rounded'>
              <div className='d-flex text-center me-2'>
                <div className='symbol symbol-85px '>
                  <img src='/media/other/phone.gif' alt='' />
                </div>
              </div>
              <div className='d-flex flex-column justify-content-start'>
                <span className='fs-5 text-gray-400'>
                  Total Call Length{' '}
                  <span className='fs-7 fw-semibold text-gray-400'>(HH:MM:SS)</span>
                </span>
                <span className='fs-1 fw-bold d-flex align-items-center'>{totalCallLength} </span>
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            <button
              type='button'
              onClick={handleOndemandClick}
              className={`btn btn-outline text-muted btn-outline-dotted btn-sm me-3 text-active-white ${
                showOndemandUI ? 'active fw-bold border border-2 bg-primary' : ''
              }`}
            >
              On Demand
            </button>
            <button
              type='button'
              onClick={handleSheduledClick}
              className={`btn btn-outline text-muted btn-outline-dotted btn-sm me-3 text-active-white me-3 ${
                showSheduledUI ? 'active fw-bold border border-2 bg-primary' : ''
              }`}
            >
              Scheduled
            </button>
            <button
              onClick={handleInPersonClick}
              type='button'
              className={`btn btn-outline text-muted btn-outline-dotted btn-sm me-3 text-active-white me-3 ${
                showInPersonUI ? 'active fw-bold border border-2 bg-primary' : ''
              }`}
            >
              In Person
            </button>
            <button
              onClick={handleOperatorClick}
              type='button'
              className={`btn btn-outline text-muted btn-outline-dotted btn-sm text-active-white me-3 ${
                showOperatorUI ? 'active fw-bold border border-2 bg-primary' : ''
              }`}
            >
              Operator
            </button>
            <button
              onClick={handleEffectiffClick}
              type='button'
              className={`btn btn-outline text-muted btn-outline-dotted btn-sm text-active-white ${
                showEffectiff ? 'active fw-bold border border-2 bg-primary' : ''
              }`}
            >
              Backstop
            </button>
          </div>
        </div>
        {showOndemandUI && (
          <MainLogs callType={callType} pageTitle={pageTitle} downloadPage={downloadPage} />
        )}
        {showSheduledUI && (
          <MainLogs callType={callType} pageTitle={pageTitle} downloadPage={downloadPage} />
        )}
        {showInPersonUI && (
          <MainLogs callType={callType} pageTitle={pageTitle} downloadPage={downloadPage} />
        )}
        {showOperatorUI && (
          <MainLogs callType={callType} pageTitle={pageTitle} downloadPage={downloadPage} />
        )}
        {showEffectiff && (
          <MainLogs callType={callType} pageTitle={pageTitle} downloadPage={downloadPage} />
        )}
      </div>
    </>
  )
}

export {DashboardLogs}
