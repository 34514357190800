import {createSlice} from '@reduxjs/toolkit'
import initialState from './tableSatate/initialState'
import { actions } from 'react-table';

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    // billing - interpreter invoice
    InterpreterInvoiceCurrentPage(state, action) {
      state.InterperterInvoice[0].currentPage = action.payload
    },
    InterpreterInvoiceRowsPerPage(state, action) {
      state.InterperterInvoice[0].rowsPerPage = action.payload
    },
    InterpreterInvoiceFilter(state, action) {
      state.InterperterInvoice[0].filterData = action.payload
    },
    InterpreterInvoiceSearch(state, action) {
        state.InterperterInvoice[0].searchQuery = action.payload
    },
    
    // member managemnet - requester
    RequesterManagementCurrentPage(state, action) {
      state.RequesterManagement[0].currentPage = action.payload
    },
    RequesterManagementRowsPerPage(state, action){
      state.RequesterManagement[0].rowsPerPage = action.payload
    },
    RequesterManagementFilter(state, action){
      state.RequesterManagement[0].filterData = action.payload
    },
    RequesterManagementSearch(state, action){
      state.RequesterManagement[0].searchQuery = action.payload
    },
    RequesterManagementSort(state, action){
      state.RequesterManagement[0].sort = action.payload
    },
    RequesterManagementOrder(state, action){
      state.RequesterManagement[0].order = action.payload
    },

    // member managemnet - interpreter
    InterperterManagementCurrentPage(state, action) {
      state.InterperterManagement[0].currentPage = action.payload
    },
    InterperterManagementRowsPerPage(state, action){
      state.InterperterManagement[0].rowsPerPage = action.payload
    },
    InterperterManagementFilter(state, action){
      state.InterperterManagement[0].filterData = action.payload
    },
    InterperterManagementSearch(state, action){
      state.InterperterManagement[0].searchQuery = action.payload
    },
    InterperterManagementSort(state, action){
      state.InterperterManagement[0].sort = action.payload
    },
    InterperterManagementOrder(state, action){
      state.InterperterManagement[0].order = action.payload
    },

    // member managemnet - administrator
    AdministratorManagementCurrentPage(state, action) {
      state.AdministratorManagement[0].currentPage = action.payload
    },
    AdministratorManagementRowsPerPage(state, action){
      state.AdministratorManagement[0].rowsPerPage = action.payload
    },
    AdministratorManagementFilter(state, action){
      state.AdministratorManagement[0].filterData = action.payload
    },
    AdministratorManagementSearch(state, action){
      state.AdministratorManagement[0].searchQuery = action.payload
    },
    AdministratorManagementSort(state, action){
      state.AdministratorManagement[0].sort = action.payload
    },
    AdministratorManagementOrder(state, action){
      state.AdministratorManagement[0].order = action.payload
    },

    // member managemnet - company user
    CompanyUserManagementCurrentPage(state, action) {
      state.CompanyUserManagement[0].currentPage = action.payload
    },
    CompanyUserManagementRowsPerPage(state, action){
      state.CompanyUserManagement[0].rowsPerPage = action.payload
    },
    CompanyUserManagementFilter(state, action){
      state.CompanyUserManagement[0].filterData = action.payload
    },
    CompanyUserManagementSearch(state, action){
      state.CompanyUserManagement[0].searchQuery = action.payload
    },
    CompanyUserManagementSort(state, action){
      state.CompanyUserManagement[0].sort = action.payload
    },
    CompanyUserManagementOrder(state, action){
      state.CompanyUserManagement[0].order = action.payload
    },
    CompanyUserManagementCustomerCode(state, action){
      state.CompanyUserManagement[0].customerCode = action.payload
    },

    // billing - customer invoice
    CustomerInvoiceCurrentPage(state, action) {
      state.CustomerInvoice[0].currentPage = action.payload
    },
    CustomerInvoiceRowsPerPage(state, action){
      state.CustomerInvoice[0].rowsPerPage = action.payload
    },
    CustomerInvoiceFilter(state, action){
      state.CustomerInvoice[0].filterData = action.payload
    },
    CustomerInvoiceSearch(state, action){
      state.CustomerInvoice[0].searchQuery = action.payload
    },

    // call logs - onDemand
    dashboardCallLogsOnDEmandCurrentPage(state, action) {
      state.dashboardCallLogsOnDEmand[0].currentPage = action.payload
    },
    dashboardCallLogsOnDEmandRowsPerPage(state, action){
      state.dashboardCallLogsOnDEmand[0].rowsPerPage = action.payload
    },
    dashboardCallLogsOnDEmandFilter(state, action){
      state.dashboardCallLogsOnDEmand[0].filterData = action.payload
    },
    dashboardCallLogsOnDEmandSearch(state, action){
      state.dashboardCallLogsOnDEmand[0].searchQuery = action.payload
    },

    // call logs - scheduled
    dashboardCallLogsScheduledCurrentPage(state, action) {
      state.dashboardCallLogsScheduled[0].currentPage = action.payload
    },
    dashboardCallLogsScheduledRowsPerPage(state, action){
      state.dashboardCallLogsScheduled[0].rowsPerPage = action.payload
    },
    dashboardCallLogsScheduledFilter(state, action){
      state.dashboardCallLogsScheduled[0].filterData = action.payload
    },
    dashboardCallLogsScheduledSearch(state, action){
      state.dashboardCallLogsScheduled[0].searchQuery = action.payload
    },

    // call logs - inPerson
    dashboardCallLogsInPersonCurrentPage(state, action) {
      state.dashboardCallLogsInPerson[0].currentPage = action.payload
    },
    dashboardCallLogsInPersonRowsPerPage(state, action){
      state.dashboardCallLogsInPerson[0].rowsPerPage = action.payload
    },
    dashboardCallLogsInPersonFilter(state, action){
      state.dashboardCallLogsInPerson[0].filterData = action.payload
    },
    dashboardCallLogsInPersonSearch(state, action){
      state.dashboardCallLogsInPerson[0].searchQuery = action.payload
    },

    // call logs - operator
    dashboardCallLogsOperatorCurrentPage(state, action) {
      state.dashboardCallLogsOperator[0].currentPage = action.payload
    },
    dashboardCallLogsOperatorRowsPerPage(state, action){
      state.dashboardCallLogsOperator[0].rowsPerPage = action.payload
    },
    dashboardCallLogsOperatorFilter(state, action){
      state.dashboardCallLogsOperator[0].filterData = action.payload
    },
    dashboardCallLogsOperatorSearch(state, action){
      state.dashboardCallLogsOperator[0].searchQuery = action.payload
    },

    // call logs - effectiff
    dashboardCallLogsEffectiffCurrentPage(state, action) {
      state.dashboardCallLogsEffectiff[0].currentPage = action.payload
    },
    dashboardCallLogsEffectiffRowsPerPage(state, action){
      state.dashboardCallLogsEffectiff[0].rowsPerPage = action.payload
    },
    dashboardCallLogsEffectiffFilter(state, action){
      state.dashboardCallLogsEffectiff[0].filterData = action.payload
    },
    dashboardCallLogsEffectiffSearch(state, action){
      state.dashboardCallLogsEffectiff[0].searchQuery = action.payload
    },

    // customer uninvoiced calls
    CustomerUnInvoicedCallsCurrentPage(state, action) {
      state.CustomerUnInvoicedCalls[0].currentPage = action.payload
    },
    CustomerUnInvoicedCallsRowsPerPage(state, action){
      state.CustomerUnInvoicedCalls[0].rowsPerPage = action.payload
    },
    CustomerUnInvoicedCallsFilter(state, action){
      state.CustomerUnInvoicedCalls[0].filterData = action.payload
    },
    CustomerUnInvoicedCallsSearch(state, action){
      state.CustomerUnInvoicedCalls[0].searchQuery = action.payload
    },

    // dashboard overview
    dashboardOverviewFilter(state, action){
      state.dashboardOverview[0].filter = action.payload
    },
    dashboardOverviewCommunicationTypes(state, action){
      state.dashboardOverview[0].communicationTypes = action.payload
    },

    // interpreter uninvoiced calls
    InterpreterUnInvoicedCallsCurrentPage(state, action) {
      state.InterpreterUnInvoicedCalls[0].currentPage = action.payload
    },
    InterpreterUnInvoicedCallsRowsPerPage(state, action){
      state.InterpreterUnInvoicedCalls[0].rowsPerPage = action.payload
    },
    InterpreterUnInvoicedCallsFilter(state, action){
      state.InterpreterUnInvoicedCalls[0].filterData = action.payload
    },
    InterpreterUnInvoicedCallsSearch(state, action){
      state.InterpreterUnInvoicedCalls[0].searchQuery = action.payload
    },

    // appointments callender
    appointmentsCallenderFilterData(state, action){
      state.appointmentsCallender[0].filterData = action.payload
    },
    appointmentsCallenderDate(state, action){
      state.appointmentsCallender[0].date = action.payload
    },

    // all appointments callender
    allAppointmentsFilterData(state, action){
      state.allAppointments[0].filterData = action.payload
    },
    allAppointmentsCurrentPage(state, action){
      state.allAppointments[0].currentPage = action.payload
    },
    allAppointmentsRowsPerPage(state, action){
      state.allAppointments[0].rowsPerPage = action.payload
    },
    allAppointmentsSearch(state, action){
      state.allAppointments[0].searchQuery = action.payload
    },

    // upcoming appointments callender
    upcomingAppointmentsFilterData(state, action){
      state.upcomingAppoinment[0].filterData = action.payload
    },
    upcomingAppointmentsCurrentPage(state, action){
      state.upcomingAppoinment[0].currentPage = action.payload
    },
    upcomingAppointmentsRowsPerPage(state, action){
      state.upcomingAppoinment[0].rowsPerPage = action.payload
    },
    upcomingAppointmentsSearch(state, action){
      state.upcomingAppoinment[0].searchQuery = action.payload
    },

    //customer management
    customerManagementCurrentPage(state, action) {
      state.customerManagement[0].currentPage = action.payload
    },
    customerManagementRowsPerPage(state, action){
      state.customerManagement[0].rowsPerPage = action.payload
    },
    customerManagementFilter(state, action){
      state.customerManagement[0].filterData = action.payload
    },
    customerManagementSearch(state, action){
      state.customerManagement[0].searchQuery = action.payload
    },
    customerManagementSort(state, action){
      state.customerManagement[0].sort = action.payload
    },
    customerManagementOrder(state, action){
      state.customerManagement[0].order = action.payload
    },
  },
})

export const {
  InterpreterInvoiceCurrentPage,InterpreterInvoiceRowsPerPage,InterpreterInvoiceFilter,InterpreterInvoiceSearch,
  RequesterManagementCurrentPage,RequesterManagementRowsPerPage,RequesterManagementFilter,RequesterManagementSearch,RequesterManagementSort,RequesterManagementOrder,
  InterperterManagementCurrentPage,InterperterManagementRowsPerPage,InterperterManagementFilter,InterperterManagementSearch,InterperterManagementSort,InterperterManagementOrder,
  AdministratorManagementCurrentPage,AdministratorManagementRowsPerPage,AdministratorManagementFilter,AdministratorManagementSearch,AdministratorManagementSort,AdministratorManagementOrder,
  CompanyUserManagementCurrentPage,CompanyUserManagementRowsPerPage,CompanyUserManagementFilter,CompanyUserManagementSearch,CompanyUserManagementSort,CompanyUserManagementOrder,CompanyUserManagementCustomerCode,
  CustomerInvoiceCurrentPage,CustomerInvoiceRowsPerPage,CustomerInvoiceFilter,CustomerInvoiceSearch,
  dashboardCallLogsOnDEmandCurrentPage,dashboardCallLogsOnDEmandRowsPerPage,dashboardCallLogsOnDEmandFilter,dashboardCallLogsOnDEmandSearch,
  dashboardCallLogsScheduledCurrentPage,dashboardCallLogsScheduledRowsPerPage,dashboardCallLogsScheduledFilter,dashboardCallLogsScheduledSearch,
  dashboardCallLogsInPersonCurrentPage,dashboardCallLogsInPersonRowsPerPage,dashboardCallLogsInPersonFilter,dashboardCallLogsInPersonSearch,
  dashboardCallLogsOperatorCurrentPage,dashboardCallLogsOperatorRowsPerPage,dashboardCallLogsOperatorFilter,dashboardCallLogsOperatorSearch,
  dashboardCallLogsEffectiffCurrentPage,dashboardCallLogsEffectiffRowsPerPage,dashboardCallLogsEffectiffFilter,dashboardCallLogsEffectiffSearch,
  CustomerUnInvoicedCallsCurrentPage,CustomerUnInvoicedCallsRowsPerPage,CustomerUnInvoicedCallsFilter,CustomerUnInvoicedCallsSearch,
  dashboardOverviewFilter,dashboardOverviewCommunicationTypes,
  InterpreterUnInvoicedCallsCurrentPage,InterpreterUnInvoicedCallsRowsPerPage,InterpreterUnInvoicedCallsFilter,InterpreterUnInvoicedCallsSearch,
  appointmentsCallenderFilterData,appointmentsCallenderDate,
  allAppointmentsFilterData,allAppointmentsCurrentPage,allAppointmentsRowsPerPage,allAppointmentsSearch,
  upcomingAppointmentsFilterData,upcomingAppointmentsCurrentPage,upcomingAppointmentsRowsPerPage,upcomingAppointmentsSearch,
  customerManagementCurrentPage,customerManagementRowsPerPage,customerManagementFilter,customerManagementSearch,customerManagementOrder,customerManagementSort
} = tableSlice.actions

export default tableSlice.reducer
