/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {UsersListWrapper} from './users-list/UsersList'
import {UsersListSearchComponent} from './users-list/components/header/UsersListSearchComponent'
import {useListView} from './users-list/core/ListViewProvider'
import {UsersListFilter} from './users-list/components/header/UsersListFilter'

type Props = {
  className: string
  userType: string
  customerCode: string
}

const UsersViewTable: React.FC<Props> = ({className, userType, customerCode}) => {
  return (
    <div data-testid="users-list-wrapper" className={`card shadow-none ${className}`}>
      <UsersListWrapper userType={userType} customerCode={customerCode} />
    </div>
  )
}

export {UsersViewTable}
