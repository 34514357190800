import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Select from 'react-select'
import './style.css'
import {Form, Formik} from 'formik'
import toaster from '../../../../Utils/toaster'
import axios from 'axios'
import * as Yup from 'yup'
import {useRef, useState, useEffect} from 'react'
import {Modal} from 'bootstrap'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import {getFileType} from '../../../../Utils/commonData'
const API_URL = process.env.REACT_APP_API_URL

const HoursOptions: any = Array.from({length: 23}, (_, i) => ({
  value: i,
  label: String(i).padStart(2, '0'),
}))

const MinutesOptions: any = Array.from({length: 60}, (_, i) => ({
  value: i,
  label: String(i).padStart(2, '0'),
}))

interface ModalProps {
  initialValues?: any
  fetchAfterSubmitOSISubmmission?: any
  expenseList: any
  setExpenseList: any
}

const timeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number)
  return hours * 60 + minutes
}

const AddOSIDetails: React.FC<ModalProps> = ({
  initialValues,
  fetchAfterSubmitOSISubmmission,
  expenseList,
  setExpenseList,
}) => {
  let {id} = useParams()

  const fileInputRef1 = useRef<HTMLInputElement>(null)

  const addAppointmentDetailsSchema = Yup.object().shape({
    startTime: Yup.string().required('Required'),
    endTime: Yup.string()
      .test('is-after-start', 'End time cannot be before start time', function (value) {
        const {startTime} = this.parent
        if (startTime && value) {
          return timeToMinutes(value) > timeToMinutes(startTime)
        }
        return true
      })
      .required('Required'),
    travelHours: Yup.number(),
    travelMinutes: Yup.number(),
    isRoundTrip: Yup.boolean(),
    milageOfDrive: Yup.number().min(0, 'Milage cannot be negative'),
  })

  const addAdditionalExpensesSchema = Yup.object().shape({
    description: Yup.string().required('Required'),
    amount: Yup.number().required('Required').min(0, 'Amount cannot be negative'),
    file: Yup.string().required('Required'),
  })

  const onSelectFile = (e: any, setFieldValue: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFieldValue('file', '')
      return
    }
    const file = e.target.files[0]
    if (file.type != 'application/pdf' && file.type != 'image/png' && file.type != 'image/jpeg') {
      setFieldValue('file', '')
      toaster('error', 'Please upload pdf , jpeg or png file')
      return
    }
    // Create a FileReader to read the file
    const reader = new FileReader()

    reader.onload = (event) => {
      const dataUrl = event.target?.result // This is the data URL
      setFieldValue('dataUrl', dataUrl) // Set the data URL as the file value
    }

    reader.readAsDataURL(file)
    setFieldValue('file', file) //e.currentTarget.files[0])
  }

  return (
    <div className='modal fade' tabIndex={-1} id='kt_add_osi_details'>
      <div className='modal-dialog modal-md'>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={addAppointmentDetailsSchema}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            setSubmitting(true)
            try {
              const formData = new FormData()
              formData.append('StartDate', values?.startTime ?? '')
              formData.append('EndDate', values?.endTime ?? '')
              formData.append('Milage', values?.milageOfDrive === '' ? 0 : values?.milageOfDrive ?? 0)
              formData.append('IsRoundTrip', values?.isRoundTrip ? 'true' : 'false')
              formData.append('TravalTimeH', values?.travelHours.toString() ?? '')
              formData.append('TravalTimeM', values?.travelMinutes.toString() ?? '')
              formData.append('FK_AppointmentCode', id ?? '')

              expenseList.map((_item: any, index: Number) => {
                formData.append(`OSIExpensesList[${index}][Code]`, _item?.code)
                formData.append(`OSIExpensesList[${index}][Amount]`, _item?.amount)
                formData.append(`OSIExpensesList[${index}][Description]`, _item?.description)
                formData.append(`OSIExpensesList[${index}].File`, _item?.file)
              })

              const result: any = await axios.post(
                `${API_URL}/Appoinment/osi-submission-appointment`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                  },
                }
              )

              if (result?.data?.status == 'S') {
                toaster('success', result?.text ?? 'Successfully Inserted')
                const modal = document.getElementById('kt_add_osi_details')
                if (modal) {
                  const modalInstance = Modal.getInstance(modal)
                  if (modalInstance) modalInstance.hide()
                }
                fetchAfterSubmitOSISubmmission()
                resetForm()
              } else if (result?.data?.status == 'E') {
                toaster('error', result?.text ?? 'Inserted Error')
              }
            } catch (ex) {
              toaster('error', 'Server Error')
              console.error(ex)
            } finally {
              setSubmitting(true)
            }
          }}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue,
            handleSubmit,
            resetForm,
            values,
            errors,
            touched,
            isValid,
            dirty,
            ...formik
          }) => (
            <Form>
              <div className='modal-content'>
                <div className='modal-header py-2'>
                  <h4 className='modal-title'>
                    {initialValues?.code === '' ? 'Add' : 'Edit'} Appoinment Details
                  </h4>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => {
                      resetForm()
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>

                <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
                  <div className='row g-4 g-xl-6'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className=''>
                        <div className='row g-4 g-xl-6'>
                          <div className='col-sm-12 col-md-12 '>
                            <div className='row g-8 mb-0'>
                              <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div className='row d-flex mb-3'>
                                  <div className='col-md-6'>
                                    <div className=''>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='required form-label fs-7 mb-1'
                                      >
                                        Start Time
                                      </label>
                                      <input
                                        id='startTime'
                                        type='time'
                                        className='form-control form-control-white form-select-sm custom-input-height'
                                        placeholder='Start Time'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.startTime}
                                      />
                                      {errors.startTime && touched?.startTime && (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>{errors.startTime.toString()}</span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className='col-md-6 '>
                                    <div className=''>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='required form-label fs-7 mb-1'
                                      >
                                        End Time
                                      </label>

                                      <input
                                        id='endTime'
                                        name='endTime'
                                        type='time'
                                        className='form-control form-control-white form-select-sm custom-input-height'
                                        placeholder='End Time'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.endTime}
                                      />
                                      {errors.endTime && touched?.endTime && (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>{errors.endTime.toString()}</span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className='row d-flex'>
                                  <div className='col-md-6'>
                                    <div className=''>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className=' form-label fs-7 mb-1'
                                      >
                                        Milage Of Drive(miles)
                                      </label>
                                      <input
                                        id='milageOfDrive'
                                        name='milageOfDrive'
                                        type='number'
                                        className='form-control form-control-white form-select-sm custom-input-height'
                                        placeholder='Milage Of Drive'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.milageOfDrive}
                                      />
                                      {errors.milageOfDrive && touched?.milageOfDrive && (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>
                                              {errors.milageOfDrive.toString()}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className='col-md-6 d-flex align-items-center'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='isRoundTrip'
                                        name='isRoundTrip'
                                        checked={values.isRoundTrip}
                                        onChange={(e) => {
                                          setFieldValue('isRoundTrip', e.target.checked)
                                        }}
                                        onBlur={handleBlur}
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg'>
                                        Round-trip{' '}
                                        <i
                                          className='bi bi-info-circle text-info'
                                          data-bs-toggle='popover'
                                          data-bs-custom-class='popover-inverse'
                                          data-bs-placement='top'
                                          title='When selected, distance from interpreter address to appointment location will be automatically doubled.'
                                        ></i>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className='row d-flex  mt-3'>
                                  <div className='col-md-12 w-100'>
                                    <div className='mb-3'>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className=' form-label fs-7 mb-1'
                                      >
                                        Travel Time
                                      </label>
                                      <div className='input-group input-group-sm'>
                                        <div className='d-flex w-auto' style={{flex: 1}}>
                                          <Select
                                            {...formik.getFieldProps('travelHours')}
                                            className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                                            classNamePrefix='react-select'
                                            options={HoursOptions}
                                            placeholder='Select Hours'
                                            styles={{
                                              control: (provided: any) => ({
                                                ...provided,
                                                width: '100%',
                                                border: '1px solid #e4e6ef',
                                                borderRadius: '4px 0 0 4px',
                                              }),
                                            }}
                                            value={{
                                              value: values.travelHours,
                                              label:
                                                HoursOptions.find(
                                                  (x: any) => x.value === values.travelHours
                                                )?.label || 'Select Hours',
                                            }}
                                            onChange={(e: any) => {
                                              setFieldValue('travelHours', e?.value)
                                            }}
                                            onBlur={(e: any) => setFieldTouched('travelHours', true)}
                                          />
                                        </div>

                                        <span className='input-group-text' style={{flex: 1}}>
                                          Hours
                                        </span>
                                        <div className='d-flex w-auto' style={{flex: 1}}>
                                          <Select
                                            {...formik.getFieldProps('travelMinutes')}
                                            className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                                            classNamePrefix='react-select'
                                            options={MinutesOptions}
                                            placeholder='Select Minutes'
                                            styles={{
                                              control: (provided: any) => ({
                                                ...provided,
                                                width: '100%',
                                                border: '1px solid #e4e6ef',
                                                borderRadius: '0',
                                              }),
                                            }}
                                            value={{
                                              value: values.travelMinutes,
                                              label:
                                                MinutesOptions.find(
                                                  (x: any) => x.value === values.travelMinutes
                                                )?.label || 'Select Minutes',
                                            }}
                                            onChange={(e: any) => {
                                              setFieldValue('travelMinutes', e?.value)
                                            }}
                                            onBlur={(e: any) => setFieldTouched('travelMinutes', true)}
                                          />
                                        </div>

                                        <span className='input-group-text' style={{flex: 1}}>
                                          Minutes
                                        </span>
                                      </div>
                                      {errors.travelHours && touched?.travelHours && (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            {/* <span role='alert'>{errors.travelHours}</span> */}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <Formik
                                  enableReinitialize
                                  initialValues={{
                                    description: '',
                                    amount: '',
                                    file: '',
                                    dataUrl: '',
                                    code: '',
                                  }}
                                  validationSchema={addAdditionalExpensesSchema}
                                  onSubmit={async (values, {setSubmitting, resetForm}) => {
                                    setSubmitting(true)
                                    try {
                                      setExpenseList([...expenseList, values])
                                    } catch (ex) {
                                      toaster('error', 'Server Error')
                                      console.error(ex)
                                    } finally {
                                      setSubmitting(true)
                                      resetForm()
                                      if (fileInputRef1.current) {
                                        fileInputRef1.current.value = '' // Clear the file input
                                      }
                                    }
                                  }}
                                >
                                  {({
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    setFieldTouched,
                                    setFieldValue,
                                    handleSubmit,
                                    resetForm,
                                    values,
                                    errors,
                                    touched,
                                    isValid,
                                    dirty,
                                    ...formik
                                  }) => (
                                    <Form>
                                      <div className='row g-4 g-xl-6 border border-dashed border-gray-400 pb-5 rounded mt-5'>
                                        <h4 className='text-gray-600 text-center bg-secondary p-2 mt-0 rounded'>
                                          Additional Expenses
                                        </h4>
                                        <div className='row d-flex'>
                                          <div className='col-md-6'>
                                            <div className='mb-3'>
                                              <label
                                                htmlFor='exampleFormControlInput1'
                                                className='required form-label fs-7 mb-1'
                                              >
                                                Description
                                              </label>
                                              <input
                                                id='description'
                                                name='description'
                                                type='text'
                                                className='form-control form-control-white form-select-sm custom-input-height'
                                                placeholder='Description'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.description}
                                              />
                                              {errors.description && touched?.description && (
                                                <div className='fv-plugins-message-container'>
                                                  <div className='fv-help-block'>
                                                    <span role='alert'>{errors.description}</span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className='col-md-6'>
                                            <div className='mb-3'>
                                              <label
                                                htmlFor='exampleFormControlInput1'
                                                className='required form-label fs-7 mb-1'
                                              >
                                                Amount
                                              </label>
                                              <input
                                                id='amount'
                                                name='amount'
                                                type='number'
                                                className='form-control form-control-white form-select-sm custom-input-height'
                                                placeholder='Amount'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.amount}
                                              />
                                              {errors.amount && touched?.amount && (
                                                <div className='fv-plugins-message-container'>
                                                  <div className='fv-help-block'>
                                                    <span role='alert'>{errors.amount}</span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className='row d-flex'>
                                          <div className='col-md-12'>
                                            <div className='mb-3'>
                                              <label
                                                htmlFor='exampleFormControlInput1'
                                                className='required form-label fs-7 mb-1  fs-7 mb-1'
                                              >
                                                File Upload
                                              </label>
                                              <input
                                                type='file'
                                                className='form-control form-control-white form-select-sm custom-input-height  '
                                                placeholder='Select File'
                                                onChange={(e) => onSelectFile(e, setFieldValue)}
                                                name='file'
                                                id='file'
                                                onBlur={handleBlur}
                                                ref={fileInputRef1}
                                              />
                                              {errors.file && (
                                                <div className='fv-plugins-message-container'>
                                                  <div className='fv-help-block'>
                                                    <span role='alert'>{errors.file}</span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className='row d-flex' style={{textAlign: 'end'}}>
                                          <div className='col-md-12'>
                                            <button
                                              onClick={() => handleSubmit()}
                                              className='btn btn-primary btn-sm'
                                              disabled={isSubmitting || !dirty || !isValid}
                                            >
                                              Add
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                                <div className='row d-flex mt-3'>
                                  <div className='col-md-12 px-0'>
                                    <div className='accordion' id='kt_accordion_1'>
                                      {expenseList?.map((item: any, index: any) => (
                                        <div className='accordion-item'>
                                          <h2
                                            className='accordion-header'
                                            id={`kt_accordion_1_header_${index + 1}`}
                                          >
                                            <button
                                              className='accordion-button fs-6 fw-normal collapsed'
                                              type='button'
                                              data-bs-toggle='collapse'
                                              data-bs-target={`#kt_accordion_1_body_${index + 1}`}
                                              aria-expanded='false'
                                              aria-controls={`kt_accordion_1_body_${index + 1}`}
                                              style={{
                                                maxHeight: '50px',
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                              }}
                                            >
                                              A.E {index + 1}
                                            </button>
                                          </h2>
                                          <div
                                            id={`kt_accordion_1_body_${index + 1}`}
                                            className='accordion-collapse collapse'
                                            aria-labelledby={`kt_accordion_1_header_${index + 1}`}
                                            data-bs-parent='#kt_accordion_1'
                                          >
                                            <div className='accordion-body'>
                                              <div className='row d-flex align-items-center'>
                                                <div className='col-md-6'>
                                                  <span>
                                                    {' '}
                                                    <strong>Description -</strong>
                                                    {item?.description}{' '}
                                                  </span>
                                                </div>
                                                <div className='col-md-4'>
                                                  <strong>Amount -</strong>
                                                  <span>{item?.amount} </span>
                                                </div>
                                                <div
                                                  className='col-md-2'
                                                  style={{textAlign: 'end'}}
                                                >
                                                  <a
                                                    onClick={() =>
                                                      setExpenseList((prev: any) =>
                                                        prev.filter(
                                                          (_: any, i: number) => i !== index
                                                        )
                                                      )
                                                    }
                                                    className='btn btn-icon btn-bg-light btn-color-danger btn-sm'
                                                  >
                                                    <KTSVG
                                                      path='/media/icons/duotune/general/gen027.svg'
                                                      className='svg-icon-3'
                                                    />
                                                  </a>
                                                </div>
                                              </div>
                                              <div className='row d-flex mt-3'>
                                                {item?.code === '' ? (
                                                  <div className=' w-100 position-relative text-center '>
                                                    {getFileType(item?.file?.name) === 'pdf' ? (
                                                      <iframe
                                                        src={item?.dataUrl}
                                                        frameBorder='0'
                                                        width={'100%'}
                                                        height={'700px'}
                                                      />
                                                    ) : (
                                                      <img
                                                        src={item?.dataUrl}
                                                        alt='document'
                                                        className='w-100 h-auto'
                                                      />
                                                    )}
                                                  </div>
                                                ) : (
                                                  <div className=' w-100 position-relative text-center '>
                                                    {getFileType(item?.file) === 'pdf' ? (
                                                      <iframe
                                                        src={
                                                          process.env.REACT_APP_IMG_URL +
                                                          '/files/' +
                                                          initialValues.code +
                                                          '/' +
                                                          item?.file
                                                        }
                                                        frameBorder='0'
                                                        width={'100%'}
                                                        height={'700px'}
                                                      />
                                                    ) : (
                                                      <img
                                                        src={
                                                          process.env.REACT_APP_IMG_URL +
                                                          '/files/' +
                                                          initialValues.code +
                                                          '/' +
                                                          item?.file
                                                        }
                                                        alt='document'
                                                        className='w-100 h-auto'
                                                      />
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer py-3'>
                  <button
                    type='reset'
                    className='btn btn-light btn-sm'
                    data-bs-dismiss='modal'
                    onClick={() => {
                      resetForm()
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleSubmit()}
                    className='btn btn-primary btn-sm'
                    disabled={isSubmitting || !isValid}
                  >
                    {initialValues?.code === '' ? 'Save' : 'Update'} & Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AddOSIDetails
