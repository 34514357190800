import {FC, useState} from 'react'
import * as Yup from 'yup'
import Select from 'react-select'
import {Form, Formik} from 'formik'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {LanguageModel} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {createUser, getadditionalMaster, updateUserInfo} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useQuery} from 'react-query'
import {getNativeLanguage} from '../../../Common/components/core/_requests'
import toaster from '../../../../../Utils/toaster'

type Props = {
  isUserLoading: boolean
  user: LanguageModel
  userType: string
  customerCode: string
}

const UserEditModalForm: FC<Props> = ({user, isUserLoading, userType, customerCode}) => {
  const {setItemIdForUpdate , itemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [userForEdit] = useState<LanguageModel>({...user})

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const editUserSchema = Yup.object().shape({
    languageFrom: Yup.string().required('required').nullable(),
    languageTo: Yup.string()
      .required('required')
      .nullable()
      .test('not-same', 'Language from and language to must be different', function (value) {
        const {languageFrom} = this.parent
        return value !== languageFrom
      }),
    specializedTypes: Yup.array().min(1, 'required').required('required'),
    yearOfExp: Yup.number().required('required').min(0, 'Must be a positive number'),
  })

  const {
    isLoading: isLoadingMasterInfo,
    data: userMasterIfo,
    error: errorMasterinfo,
    refetch: refetchMasterInfo,
  } = useQuery(
    `customer-additional-master`,
    () => {
      return getadditionalMaster()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    () => {
      return getNativeLanguage()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const languageOptions = nativeLanguageList?.data?.map((d) => ({
    value: d.key,
    label: d.value,
  }))

  return (
    <Formik
      initialValues={userForEdit}
      validationSchema={editUserSchema}
      onSubmit={async (values, {setSubmitting}) => {
        values.fK_User = customerCode
        values.userType = userType

        if (values.isDeleted + '' === 'true' || values.isDeleted + '' === 'false') {
          values.isDeleted = JSON.parse(values.isDeleted + '')
        }
        setSubmitting(true)
        try {
          let result: any
          if (isNotEmpty(values.code)) {
            result = await updateUserInfo(values)
            if (result?.status === 'S') {
              toaster('success', result?.text ?? 'Successfully updated')
              cancel(true)
            } else if (result?.status === 'E') {
              toaster('error', result?.text ?? 'Record Not Updated')
            }
          } else {
            result = await createUser(values)
            if (result?.status === 'S') {
              toaster('success', result?.text ?? 'Successfully created')
              cancel(true)
            } else if (result?.status === 'E') {
              toaster('error', result?.text ?? 'Record Not created')
            }
          }
        } catch (ex) {
          console.error(ex)
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        values,
        touched,
        errors,
        isValid,
        dirty,
      }) => (
        <Form>
          {/* Language From */}
          <div className='mb-0'>
            <div className='row mb-0'>
              <div className='col-lg-12'>
                <label className='col-lg-6 col-form-label required fw-semibold'>
                  Language From
                </label>
                <Select
                  name='languageFrom'
                  isDisabled={isSubmitting || isUserLoading  || (userType === 'ADMIN' && itemIdForUpdate !== null )}
                  placeholder='Select a Language'
                  value={
                    languageOptions?.find((option) => option.value === values.languageFrom) || null
                  }
                  onChange={(option: { value: any }) => setFieldValue('languageFrom', option?.value || '')}
                  options={!isFetchingNativeLanguage ? languageOptions : []}
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      width: '100%',
                      border: '1px solid #e4e6ef',
                    }),
                    placeholder: (provided: any) => ({
                      ...provided,
                      color: '#a4afc4',
                    }),
                  }}
                  onBlur={() => setFieldTouched('languageFrom', true)}
                />
                {touched.languageFrom && errors.languageFrom && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.languageFrom}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Language To */}
          <div className='mb-0'>
            <div className='row'>
              <div className='col-lg-12 fv-row'>
                <label className='col-lg-6 col-form-label required fw-semibold'>Language To</label>
                <Select
                  name='languageTo'
                  isDisabled={isSubmitting || isUserLoading || (userType === 'ADMIN' && itemIdForUpdate !== null )}
                  placeholder='Select a Language'
                  value={
                    languageOptions?.find((option) => option.value === values.languageTo) || null
                  }
                  onChange={(option: { value: any }) => setFieldValue('languageTo', option?.value || '')}
                  options={!isFetchingNativeLanguage ? languageOptions : []}
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      width: '100%',
                      border: '1px solid #e4e6ef',
                    }),
                    placeholder: (provided: any) => ({
                      ...provided,
                      color: '#a4afc4',
                    }),
                  }}
                  onBlur={() => setFieldTouched('languageTo', true)}
                />
                {touched.languageTo && errors.languageTo && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.languageTo}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Service Type */}
          <div className='mb-0'>
            <div className='row mb-0'>
              <div className='col-lg-12 fv-row'>
                <label className='col-lg-6 col-form-label required fw-semibold'>Service Type</label>
                <Select
                  name='specializedTypes'
                  className='react-select-styled react-select-solid react-select-sm'
                  classNamePrefix='react-select'
                  value={values.specializedTypes || []}
                  options={userMasterIfo?.data?.specializedTypes}
                  onBlur={() => setFieldTouched('specializedTypes', true)}
                  onChange={(e: any) => setFieldValue('specializedTypes', e)}
                  placeholder='Service Type'
                  isMulti
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      border: '1px solid #e4e6ef',
                      color: '#5e6278',
                      minHeight: '33px',
                    }),
                  }}
                />
                {touched.specializedTypes && errors.specializedTypes && (
                  <div className='fv-plugins-message-container' role="alert">
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.specializedTypes}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Years of Experience */}
          <div className='fv-row mb-0'>
            <div className='row mb-4'>
              <div className='col-lg-12 fv-row'>
                <label className='col-lg-6 col-form-label required fw-semibold'>
                  Years of Experience
                </label>
                <input
                  type='number'
                  className='form-control form-control-white form-select-sm'
                  placeholder='Years of Experience'
                  name='yearOfExp'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.yearOfExp}
                />
                {touched.yearOfExp && errors.yearOfExp && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.yearOfExp}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className='text-end mt-5'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3 btn-sm'
              data-kt-users-modal-action='cancel'
              disabled={isSubmitting || isUserLoading}
            >
              Discard
            </button>
            <button
              type='button'
              className='btn btn-primary btn-sm'
              onClick={() => {
                handleSubmit()
                setFieldTouched('languageFrom', true)
                setFieldTouched('languageTo', true)
                setFieldTouched('specializedTypes', true)
                setFieldTouched('yearOfExp', true)
              }}
              disabled={isSubmitting}
            >
              {userType === 'ADMIN' ? (itemIdForUpdate ? 'Update' : 'Add') : 'Save'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export {UserEditModalForm}
